import React, { useEffect, useState } from "react";
import { SponsorCategory } from "../../../../types/EventData";
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import SponsorCategoryListItem from "../SponsorCategoryListItem";
import getMaxIndexByIdSuffix from "../../../../utils/helpers/getMaxIndexByIdSufix";
import CreateListItemButton from "../CreateListItemButton";
import reorderArray from "../../../../utils/helpers/reorderArray";
import InsertListButton from "../../../../utils/ui/InsertListButton";

interface CategoryListProps {
  sponsorCategories: SponsorCategory[];
  onSelect: (selectedId: string) => void;
  onDelete: (sponsorCategoryUpdate: SponsorCategory[]) => void;
  onCreate: (sponsorCategoryUpdate: SponsorCategory[]) => void;
  onDrag: (sponsorCategoryUpdate: SponsorCategory[]) => void;
}

function SponsorCategoryList({sponsorCategories, onDelete, onCreate, onDrag, onSelect}: CategoryListProps) {
  const [selectedId, setSelectedId] = useState<string>('');

  useEffect(() => {
    onSelect(selectedId);
  }, [selectedId]);

  const onDragEnd = (result: DropResult<string>) => {
    if (!result.destination) {
      return;
    }

    const swipedItemsArray = reorderArray<SponsorCategory>(sponsorCategories, result.source.index, result.destination.index);

    onDrag(swipedItemsArray);
  }

  const handleCategoryDelete = (categoryId: string) => {
    const categoryUpdate = sponsorCategories.filter(category => category.id !== categoryId);
    setSelectedId('');
    onDelete(categoryUpdate);
  }

  const handleCategoryClick = (categoryId: string) => {
    if (categoryId == selectedId) {
      return setSelectedId('');
    }
    setSelectedId(categoryId);
  }

  const handleCategoryCreate = () => {
    const categoryUpdate = [...sponsorCategories];
    categoryUpdate.push({
      id: `sponsorctg-${sponsorCategories.length + 1}`,
      name: '<Nova Categoria>',
      participants: []
    });

    onCreate(categoryUpdate);
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sponsorcategories" type="list" direction="vertical">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="border-r-transparent xl:border-r-slate-200 border-l-transparent border-t-transparent xl:border-b-transparent pe-8 border-4 overflow-y-scroll w-[600px] xl:w-[400px] h-[400px]"
            >
              {sponsorCategories.map((category, index) => (
                <SponsorCategoryListItem 
                  key={category.id}
                  sponsorCategory={category}
                  index={index}
                  selected={selectedId == category.id}
                  handleDelete={handleCategoryDelete}
                  onClick={handleCategoryClick}
                />
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-center items-center mt-6">
        {/* <CreateListItemButton onClick={handleCategoryCreate} /> */}
        <InsertListButton onClick={handleCategoryCreate} />
      </div>
    </div>
  );
}

export default SponsorCategoryList;