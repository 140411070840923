import React from "react";
import { UploadButton } from "@bytescale/upload-widget-react";
import { FaEye, FaFilePdf, FaTrash } from "react-icons/fa6";

interface PdfUploaderProps {
  pdfUrl: string;
  setPdfUrl: (url: string) => void;
}

const options = {
  apiKey: "public_12a1z8V6fXbMfWWFEV3xj6yqzse5",
  maxFileCount: 1,
  mimeTypes: ["application/pdf"],
  styles: {
    colors: {
      primary: "#FF6B00"
    },
  },
  locale: {
    "uploadFileBtn": "Enviar PDF",
    "orDragDropFile": "...ou arraste um arquivo PDF.",
    "cancelBtn": "cancelar",
    "cancelPreviewBtn": "Cancelar",
    "continueBtn": "Continuar",
    "removeBtn": "remover",
    "removeBtnClicked": "removido",
    "submitBtnLoading": "Porfavor espere...",
  },
};

export default function PdfUploader({
  pdfUrl,
  setPdfUrl
}: PdfUploaderProps) {
  const handleClearClick = () => {
    setPdfUrl('');
  }

  return (
    <div className="pdfUploader">
      <div className="flex flex-col items-center w-80 gap-4">
        <div className="bg-gray-300 shadow-sm rounded-lg flex-1 w-full flex justify-center items-center h-36 py-8 px-4">
          {pdfUrl && pdfUrl !== '' ? (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <FaFilePdf className="text-5xl text-orange-500 mb-2" />
              <p className="text-gray-700 font-semibold">PDF carregado</p>
              <div className="flex mt-4 space-x-2">
                <a 
                  href={pdfUrl} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="flex items-center bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded transition duration-300"
                >
                  <FaEye className="mr-2" /> Visualizar
                </a>
                <button
                  onClick={handleClearClick}
                  className="flex items-center bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded transition duration-300"
                >
                  <FaTrash className="mr-2" /> Remover
                </button>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <FaFilePdf className="text-5xl text-gray-400 mx-auto mb-2" />
              <p className="text-gray-500">
                Nenhum PDF carregado. Envie o PDF do evento.
              </p>
            </div>
          )}
        </div>
        <div className="flex gap-4">
          <UploadButton 
            options={options}
            onComplete={files => {
              const newPdfUrl = files.map(x => x.fileUrl).join("\n");
              if (newPdfUrl !== '') {
                setPdfUrl(newPdfUrl);
              }
            }}
          >
            {({onClick}) =>
              <button
                className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-lg transition transition-duration-300"
                onClick={onClick}
              >
                Enviar PDF
              </button>
            }
          </UploadButton>
          <button
            className={`${pdfUrl != '' ? 'bg-orange-500 hover:bg-orange-600 text-white cursor-pointer' : 'border border-orange-300 bg-transparent text-orange-300 cursor-default'} px-4 py-2 rounded-lg transition transition-duration-300`}
            disabled={pdfUrl == ''}
            onClick={handleClearClick}
          >
            Limpar
          </button>
        </div>
      </div>
    </div>
  );
}