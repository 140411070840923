import axios from 'axios';
import parseResponseTypes from './helpers/parseResponseTypes';
import { EventData } from '../types/EventData';

const apiUrl = 'https://api.credentech.com.br';
// const apiUrl = 'https://devapi.credentech.com.br';
// const apiUrl = 'http://localhost:3333';

export const fetchEventData = async (eventId: string) => {
  try {
    const response = await axios.get(`${apiUrl}/${eventId}`);
    const eventData = response.data;
    
    parseResponseTypes(eventData);

    eventData.paymentdate = eventData.paymentdate || new Date(2024, 8, 8);
    eventData.color1 = (!eventData.color1 || eventData.color1.length == 0) ? [37, 99, 235] : eventData.color1;
    eventData.color2 = (!eventData.color2 || eventData.color2.length == 0) ? [37, 99, 235] : eventData.color2;
    
    return eventData;
  } catch (error) {
    console.error(error);
    throw "Falha ao buscar os dados do evento.";
  }
}

export const fetchEventDataById = async(eventId: number) => {
  try {
    const response = await axios.get(`${apiUrl}/get_event/${eventId}`);
    const eventData = response.data as EventData;
    return eventData;
  } catch(error) {
    console.error(error);
    throw "Falha ao buscar os dados do evento.";
  }
}

export const fetchEventEditData = async(eventId: number) => {
  try {
    const response = await axios.get(`${apiUrl}/get_event/${eventId}`);
    const eventData = response.data as EventData;

    parseResponseTypes(eventData);

    console.log(eventData);

    // Parse categories
    eventData.categories.forEach((category) => {
      category.id = `newct-${category.id}`;
    });

    // Parse base participants 
    eventData.participants.forEach((participant) => {
      participant.id = `newpct-${participant.id}`;
      if (participant.categoryid) {
        participant.category!.id = `newct-${participant.categoryid}`;
        participant.categoryid = `newct-${participant.categoryid}`;
      }
    });
    
    // Parse schedule structure
    eventData.schedule.days.forEach(day => {
      const numericDayId = day.id;
      day.id = `newday-${numericDayId}`;
      
      day.dayplaces.forEach(place => {
        const numericPlaceId = place.id;
        place.id = `newplace-${numericDayId}-${numericPlaceId}`;
        
        place.placemodules.forEach(module => {
          const numericModuleId = module.id;
          module.id = `newmodule-${numericDayId}-${numericPlaceId}-${numericModuleId}`;
          
          if (module.assignments) {
            module.assignments.forEach(assignment => {
              assignment.id = `assignment-${assignment.id}`;
              assignment.participant.id = `newpct-${assignment.participant.id}`;
              assignment.function.id = `newfunction-${assignment.function.id}`;
              if (assignment.participant.category) {
                assignment.participant.category.id = `newct-${assignment.participant.category.id}`;
              }
            });
          }

          module.moduleprogrammings.forEach(programming => {
            const numericProgrammingId = programming.id;
            programming.id = `newprogramming-${numericDayId}-${numericPlaceId}-${numericModuleId}-${numericProgrammingId}`;
            
            if (programming.assignments) {
              programming.assignments.forEach(assignment => {
                assignment.id = `assignment-${assignment.id}`;
                assignment.participant.id = `newpct-${assignment.participant.id}`;
                assignment.function.id = `newfunction-${assignment.function.id}`;
                if (assignment.participant.category) {
                  assignment.participant.category.id = `newct-${assignment.participant.category.id}`;
                }
              });
            }
          });
        });
      });
    });

    eventData.functions.forEach((functionData, functionIndex) => {
      functionData.id = `newfunction-${functionData.id}`;
    });

    // Parse sponsors
    eventData.sponsors.forEach((sponsor, sponsorIndex) => {
      sponsor.id = `sponsorctg-${sponsorIndex}`;
      sponsor.participants.forEach((participant) => {
        participant.id = `sponsor-${participant.id}`;
      });
    });

    // Parse subscription categories
    eventData.subscriptioncategories.forEach((subscription) => {
      subscription.id = `subc-${subscription.id}`;
      subscription.isfree = subscription.prevalue === 0 && subscription.posvalue === 0;
    });

    console.log(eventData)

    return eventData;
  } catch(error) {
    console.error(error);
    throw "Falha ao buscar os dados do evento.";
  }
}

export const fetchCreateEvent = async (eventData: string) => {
  try {
    const response = await axios.post(`${apiUrl}/create_event`, eventData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
    console.log('Evento criado com sucesso', response.data);
    return response;
  } catch (error) {
    console.log(error)
    throw "Erro ao criar o evento";
  }
}

export const fetchEditEvent = async (eventData: string, eventId: string) => {
  try {
    const response = await axios.put(`${apiUrl}/edit_event/${eventId}`, eventData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log('Evento editado com sucesso', response.data);
    return response;
  } catch (error) {
    console.log(error)
    throw "Erro ao editar o evento";
  }
}

export async function getEventSubscribers(eventId: string) {
  try {
    const response = await axios({
      method: 'get',
      url: `${apiUrl}/get_event_subscribers/${eventId}`,
      withCredentials: true
    });

    console.log(response)
    const parsedSubscribers: any[] = Object.assign(response.data);

    parsedSubscribers.forEach((subscriber) => {
      subscriber.birthdate = new Date(subscriber.birthdate);
      subscriber.dateofsubscription = new Date(subscriber.dateofsubscription);
    });

    return parsedSubscribers;
  } catch(err) {
    console.log("Failed while trying to get subscribers");
    console.log(err);
  }
}

export async function fetchLogin(username: string, password: string) {
  try {
    const loginForm = new URLSearchParams();

    loginForm.append("username", username);
    loginForm.append("password", password);

    const response = await axios({
      method: "post",
      url: `${apiUrl}/login`,
      data: loginForm,
      withCredentials: true
    });

    return response;
  } catch(err) {
    throw err;
  }
}

export async function deleteEvent(eventId: number) {
  try {
    const response = await axios.delete(`${apiUrl}/delete_event/${eventId}`, {
      withCredentials: true
    });

    return response;
  } catch(err) {
    console.log("Falha ao deletar o evento");
    console.log(err);
  }
}

export async function fetchEventsDashboard() {
  try {
    // const response = await axios({
    //   method: "get",
    //   url: `${apiUrl}/get_event_dashboard`,
    //   withCredentials: true
    // });
    const response = await axios.get(`${apiUrl}/get_event_dashboard`, {
      withCredentials: true
    });
    if (response.data === "No events found") {
      return [];
    }
    return response.data;
  } catch(err) {
    console.log("Falha ao tentar pegar a dashboard de eventos");
    console.log(err);
  }
}
