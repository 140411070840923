import React from "react";
import { IconType } from "react-icons"

export enum IconCircleColors {
  Orange = "orange",
  LightOrange = "lightOrange",
  Blue = "blue",
  LightBlue = "lightBlue"
};

type IconCircleProps = {
  icon : IconType,
  color : IconCircleColors
}

const IconCircleColorsData = {
  orange: {
    backgroundColor: "bg-orange-600",
    textColor: "text-orange-800"
  },
  lightOrange: {
    backgroundColor: "bg-orange-500",
    textColor: "text-orange-800"
  },
  blue: {
    backgroundColor: "bg-blue-600",
    textColor: "text-blue-800"
  },
  lightBlue: {
    backgroundColor: "bg-blue-500",
    textColor: "text-blue-600"
  }
}

function IconCircle({icon : Icon, color} : IconCircleProps) {
  const {backgroundColor, textColor} = IconCircleColorsData[color]

  return (
    <div className={`rounded-full w-12 h-12 xl:w-16 xl:h-16 2xl:w-20 2xl:h-20 flex-shrink-0 ${backgroundColor}`}>
      <Icon className={`w-full h-full p-4 ${textColor}`} />
    </div>
  );
}

export default IconCircle