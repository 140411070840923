import { motion } from "framer-motion";
import React from "react";
import { RiArrowRightSFill } from "react-icons/ri";
import { RiArrowRightFill } from "react-icons/ri";
import LayoutSelectionCard from "../components/LayoutSelectionCard";
import { FaArrowLeftLong } from "react-icons/fa6";

function LayoutSelection() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="layoutSelection bg-white flex justify-center"
      style={{
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="absolute w-full h-full p-8">
        <a href="/events"><FaArrowLeftLong style={{width: '35px', height: '35px'}} /></a>
      </div>
      <div className="max-w-[1200px]">
        <nav className="flex justify-center items-center py-12 mb-16">
          <h1 className="font-bold text-4xl">Selecione um Layout</h1>
        </nav>
        <div className="flex justify-center gap-8">
          <LayoutSelectionCard
            imgSrc="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/layout1showcase.png"
            layoutName="layout 1"
            layoutId="layout1"
            delay={0.2}
          />
          <LayoutSelectionCard
            imgSrc="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/layout2showcase.png"
            layoutName="layout 2"
            layoutId="layout2"
            delay={0.4}
          />
          <LayoutSelectionCard
            imgSrc="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/layout3showcase.png"
            layoutName="layout 3"
            layoutId="layout3"
            delay={0.6}
          />
          <LayoutSelectionCard
            imgSrc="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/layout4showcase.png"
            layoutName="layout 4"
            layoutId="layout4"
            delay={0.8}
          />
        </div>
      </div>
    </motion.div>
  );
}

export default LayoutSelection;