import axios from "axios";
import L from "leaflet";
import { Place } from "../types/EventData";

const NominatimBaseURL = 'https://nominatim.openstreetmap.org/search';

export type Coordinates = {
  lat: number;
  lon: number;
}
export type LocationName = string;

const customIcon = new L.Icon({
  iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/8/88/Map_marker.svg',
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41]
});

export async function getCoordinates({ street, number, city, state }: Place) {
  const address = `${number} ${street}, ${city}, ${state}, Brazil`;
  const url = `${NominatimBaseURL}?q=${encodeURIComponent(address)}&format=json&limit=1`;

  try {
    const response = await axios.get(url);
    
    if (response.data.length > 0) {
      const {lat, lon} = response.data[0];
      return {lat, lon};
    }
  } catch(error) {
    console.log("Erro ao obter geoloc: " + error);
  }
}

export function initMap({lat, lon}: Coordinates, name: LocationName) {
  const map = L.map('map');

  try {
    map.setView([lat, lon], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors'
    }).addTo(map);
  
    L.marker([lat, lon], {
      draggable: false,
      icon: customIcon
    })
      .addTo(map)
      .bindPopup(name)
      .openPopup();
    return map;
  } catch(e) {
    console.log(e)
  }
}