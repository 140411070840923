function formatTelephone(telephone: string) {
  let formatted = "+55 ";
  let digitsOnly = telephone.replace(/[^\d]/g, '');

  if (digitsOnly.startsWith("55")) {
    digitsOnly = digitsOnly.substring(2);
  }

  if (digitsOnly.length > 0) {
    formatted += '(';
    if (digitsOnly.length > 2) {
      formatted += digitsOnly.slice(0, 2) + ') ';
      if (digitsOnly.length > 7) {
        formatted += digitsOnly.slice(2, 7) + '-' + digitsOnly.slice(7, 11);
      } else {
        formatted += digitsOnly.slice(2);
      }
    } else {
      formatted += digitsOnly;
    }
  }

  return formatted;
}

export default formatTelephone;