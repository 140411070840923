import React, { useState } from "react";
import parseNumberToTwoDigits from "../../utils/helpers/parseNumberToTwoDigits";
import { rgbHoverColor } from "../../utils/helpers/rgbHoverColor";

type DayButtonProps = {
  index: number;
  day: Date;
  color1: number[];
  selected?: boolean;
  onClick?: () => void;
};

function DayButton({index, day, selected, color1, onClick}: DayButtonProps) {
  const [isHovering, setHovering] = useState<boolean>(false);

  const color = `rgb(${color1})`;
  const hoverColor = rgbHoverColor(color1);

  return (
    <div
      className={`min-w-40 min-h-24 transition-colors duration-200 cursor-pointer ${selected ? "bg-blue-400 hover:bg-blue-500" : "bg-slate-400 hover:bg-slate-500"} text-white py-2`}
      style={{
        backgroundColor: selected ? 
                        (isHovering ? `rgb(${hoverColor})` : `rgb(${color})`) : // selected color
                        (isHovering ? 'rgb(148 163 184)' : 'rgb(100 116 139)')  // hovering color
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={onClick}
    >
      <p className='font-bold text-xl ml-2'>Dia</p>
      <p className='font-bold text-5xl text-center'>{parseNumberToTwoDigits(index)}</p>
      <p className='font-bold text-xl text-center'>{`${parseNumberToTwoDigits(day.getDate())}/${parseNumberToTwoDigits(day.getMonth() + 1)}`}</p>
    </div>
  );
}

export default DayButton;