import React from "react";

interface SectionInfoProps {
  title: string;
  content: string;
  grow?: boolean;
}

export default function SectionInfo({title, content, grow}: SectionInfoProps) {
  return (
    <div className={`flex gap-2 ${grow ? 'grow' : ''}`}>
      <h3 className="font-bold">{title}:</h3>
      <p>{content}</p>
    </div>
  );
}