import { Draggable } from "@hello-pangea/dnd";
import React from "react";
import { Participant } from "../../../../types/EventData";
import { Tooltip } from "react-tooltip";
import { FaTrash } from "react-icons/fa6";
import truncateString from "../../../../utils/helpers/truncateString";
import ReactCountryFlag from "react-country-flag";

interface ParticipantsListItemProps {
  participant: Participant;
  index: number;
  selected?: boolean;
  small?: boolean;
  handleDelete: (participantId: string) => void;
  onClick: (participantId: string) => void;
}

function ParticipantsListItem({participant, selected, index, handleDelete, onClick, small}: ParticipantsListItemProps) {
  return (
    <Draggable draggableId={participant.id} index={index}>
      {(provided) => (
        <li
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={`${selected ? 'bg-neutral-900 text-white' : 'bg-stone-200 text-stone-900'} w-full mt-2 rounded-full shadow-md transition-colors duration-300 ease-in-out flex items-center justify-between px-8 py-1 ${small ? 'gap-2' : 'gap-4'}`}
          onClick={() => onClick(participant.id)}
        >
          <div className="flex items-center gap-2">
            <img 
              src={participant.mask ? participant.mask.profilepic : participant.profilepic}
              className="rounded-full"
              style={{
                width: '30px',
                height: '30px'
              }}  
            />
            <p className={`uppercase ${small ? 'text-xs' : 'text-sm'} font-semibold tracking-wider`}>
              {truncateString(participant.mask ? participant.mask.name : participant.name, 12)}
              {participant.nationality && (
                <span className="ms-2 text-gray-500">
                  <ReactCountryFlag countryCode={participant.nationality} svg />
                </span>
              )}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <p className="uppercase text-xs font-semibold tracking-wide">
              {participant.mask ? (participant.mask?.category?.name || '<Sem Categoria>') : (participant?.category?.name || '<Sem Categoria>')}
            </p>
            {participant.introductionauthor && (
              <span className="text-xs">Introdutor</span>
            )}
            <button
              data-tooltip-id={`participant-${index + 1}-tooltip`}
              data-tooltip-content="Deletar"
              data-tooltip-place="bottom"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(participant.id);
              }}
            >
              <FaTrash />
            </button>
            <Tooltip id={`participant-${index + 1}-tooltip`} />
          </div>
        </li>
      )}
    </Draggable>
  );
}

export default ParticipantsListItem;