import React, { useState } from "react";
import { CheckoutFormData } from "..";
import InfoRow from "./InfoRow";
import { SubscriptionCategory } from "../../../types/EventData";
import { rgbHoverColor } from "../../../utils/helpers/rgbHoverColor";

interface StepRender2Props {
  subscriptionCategory?: SubscriptionCategory
  checkoutData: CheckoutFormData;
  color1: number[];
  onStepBack?: () => void;
  onStepFoward?: () => void;
}

function StepRender2({subscriptionCategory, onStepBack, onStepFoward, checkoutData, color1}: StepRender2Props) {
  const [proceedBtnHover, setProceedBtnHover] = useState<boolean>(false);

  const hoverColor = rgbHoverColor(color1);

  return (
    <div className="stepRender2">
      <div className="flex gap-12">
        <div className="w-1/2">
          <div className="bg-slate-100 py-6 px-12 md:max-h-28 rounded-lg">
            <h3 className="uppercase text-xl font-bold">CATEGORIA DE INSCRIÇÃO</h3>    
            <p className="ms-4 mt-2 font-semibold">{subscriptionCategory?.title} - (1x) R$ {subscriptionCategory?.prevalue}</p>
          </div>
        </div>
        <div className="w-1/2 flex flex-col items-center">
          <div className="bg-slate-100 py-6 px-12 rounded-lg">
            <h3 className="uppercase text-xl font-bold">INFORMAÇÕES DE CONTATO</h3>
            <InfoRow property="Email:" value={checkoutData.email} />
            <InfoRow property="Celular:" value={checkoutData.telephone} />
            <h3 className="uppercase text-xl font-bold my-6">INFORMAÇÕES DE CONTATO</h3>
            <InfoRow property="Nome:" value={checkoutData.firstname} />
            <InfoRow property="Sobrenome:" value={checkoutData.surname} />
            <InfoRow property="CPF:" value={checkoutData.cpf} />
            <InfoRow property="Data de Nascimento:" value={checkoutData.birthdate} />
            <h3 className="uppercase text-xl font-bold my-6">ENDEREÇO</h3>
            <InfoRow property="CEP:" value={checkoutData.cep} />
            <InfoRow property="Estado:" value={checkoutData.state} />
            <InfoRow property="Cidade:" value={checkoutData.city} />
            <InfoRow property="Bairro:" value={checkoutData.district} />
            <InfoRow property="Número:" value={checkoutData.number} />
            <InfoRow property="Endereço:" value={checkoutData.address} />
            <InfoRow property="Complemento:" value={checkoutData.complement} />
            <InfoRow property="Referência:" value={checkoutData.reference} />
          </div>
          <button 
            onClick={onStepFoward}
            // className="bg-blue-400 hover:bg-blue-500 text-white px-6 py-2 text-lg font-semibold mt-4 rounded-lg transition-all"
            className="text-white px-6 py-2 text-lg font-semibold mt-4 rounded-lg transition-all"
            style={{
              backgroundColor: `rgb(${proceedBtnHover ? hoverColor : color1})`
            }}
          >
            Prosseguir Pagamento
          </button>
          <button 
            onClick={onStepBack}
            className="text-slate-500 border-2 border-slate-500 hover:text-white hover:bg-slate-500 text-slate-500 px-14 py-2 text-lg font-semibold mt-2 rounded-lg transition-all"
          >
            Retornar Etapa
          </button>
        </div>
      </div>
    </div>
  );
}

export default StepRender2;