import React from "react";

type LocationAddressData = {
  street : string;
  number : string;
  district : string,
  city : string;
  state : string;
  telephone : string;
}

function LocationAddress({street, number, district, city, state, telephone} : LocationAddressData) {
  return (
    <div className="locationAddress">
      <div className="text-center font-semibold my-8">
        <p className="">{street}, {number}, {district} - {city} - {state}</p>
        <p className="">Tel .: {telephone}</p>
      </div>
    </div>
  );
}

export default LocationAddress;