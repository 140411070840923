import React from "react";
import { ClipLoader } from "react-spinners";

type LoadingProps = {
  className?: string;
  customWidth?: string;
  customHeight?: string;
}

function Loading({className, customWidth, customHeight}: LoadingProps) {
  return (
    <div 
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "100vh" 
        height: (customHeight ? customHeight : '100vh'),
        width: (customWidth ? customWidth : '')
      }}
    >
        <ClipLoader size={50} color={"#FF6B00"} loading={true} />
    </div>
  );
}

export default Loading;