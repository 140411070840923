import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StepProgress from "./StepProgress";
import StepRender1 from "./StepsRender/StepRender1";
import StepRender2 from "./StepsRender/StepRender2";
import StepRender3 from "./StepsRender/StepRender3";
import StepRender4 from "./StepsRender/StepRender4";
import { SubscriptionCategory } from "../../types/EventData";

export type CheckoutFormData = {
  email: string,
  telephone: string,
  firstname: string,
  surname: string,
  cpf: string,
  birthdate: string,
  state: string,
  city: string,
  district: string,
  number: string,
  address: string,
  cep: string,
  complement: string,
  reference: string,
  categoryid: string
}

interface MultistepCheckoutProps {
  subscriptionCategories: SubscriptionCategory[];
  color1?: number[];
  color2?: number[];
  eventid: number;
}

function MultistepCheckout({subscriptionCategories, color1 = [59, 130, 246], color2 = [37, 99, 235], eventid}: MultistepCheckoutProps) {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [userFormData, setUserFormData] = useState<CheckoutFormData>({
    email: '',
    telephone: '',
    firstname: '',
    surname: '',
    cpf: '',
    birthdate: '',
    state: '',
    city: '',
    district: '',
    number: '',
    address: '',
    cep: '',
    complement: '',
    reference: '',
    categoryid: subscriptionCategories[0].id
  });
  const [paymentStatus, setPaymentStatus] = useState<string>('');

  const handleFormSubmit = (data: CheckoutFormData) => {
    setCurrentStep(2);
    setUserFormData(data);
  }

  const handleStepForward = () => {
    setCurrentStep(currentStep + 1);
  }

  const handleStepBack = () => {
    setCurrentStep(currentStep - 1);
  }

  const handlePaymentUpdate = (event: string) => {
    setPaymentStatus(event);
    handleStepForward();
  }

  useEffect(() => {
    console.log(subscriptionCategories)
    console.log(userFormData);
  }, [userFormData]);

  const renderStep = () => {
    switch(currentStep) {
      case 1:
        return (
          <StepRender1 
            subscriptionCategories={subscriptionCategories}
            defaultFormData={userFormData} 
            onSubmit={handleFormSubmit} 
            color1={color1}
          />
        ); 
      case 2:
        return (
          <StepRender2 
            subscriptionCategory={subscriptionCategories.find((v) => v.id == userFormData.categoryid)}
            onStepBack={handleStepBack}
            onStepFoward={handleStepForward}
            checkoutData={userFormData} 
            color1={color1}
          />
        );
      case 3:
        return (
          <StepRender3
            onConfirm={handlePaymentUpdate}
            formData={userFormData}
            eventid={eventid}
          />
        );
      case 4:
        return (
          <StepRender4 
            paymentStatus={paymentStatus}
            subscriberEmail={userFormData.email}
          />
        );
    }
  }

  return (
    <div className={`multistepCheckout px-6 py-8 rounded-lg ${currentStep !== 3 ? 'bg-slate-50' : ''}`}>
      <StepProgress 
        step={currentStep} 
        color2={color2}
      />
      <div className="px-6 pb-4 pt-4">
        {renderStep()}
      </div>
    </div>
  );
}

export default MultistepCheckout;