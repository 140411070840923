import React from "react";
import { UploadButton } from "@bytescale/upload-widget-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { CiTrash } from "react-icons/ci";
import { GoLinkExternal  } from "react-icons/go";

import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Tooltip } from "react-tooltip";
import { Image } from "../../../../types/EventData";

const options = {
  apiKey: "public_12a1z8V6fXbMfWWFEV3xj6yqzse5",
  maxFileCount: 4,
  multi: true,
  "editor": {
    "images": {
      "preview": true
    }
  },
  styles: {
    colors: {
      primary: "#FF6B00"
    },
  },
  locale: {
    "uploadFileMultiBtn": "Enviar imagem",
    "orDragDropFileMulti": "...ou arraste uma imagem.",
    "addAnotherFileBtn": "Adicione outra imagem...",
    "removeBtnClicked": "removido",
    "removeBtn": "remover",
    "cancelBtn": "cancelar",
    "cancelPreviewBtn": "Cancelar",
    "continueBtn": "Continuar",
  },
};

type PicturesUploaderProps = {
  pictures: Image[];
  setPictures: (images: Image[]) => void;
  title: string
}

function PicturesUploader({title, pictures, setPictures}: PicturesUploaderProps) {
  return (
    <div className="max-w-[800px] flex flex-col items-center m-auto">
      <h2 
        className="text-2xl font-semibold"
      >
        {title}
      </h2>
      <div className="flex flex-col w-80 h-64 items-center justify-between gap-8 mt-4">
        <div className="w-64 flex-1 bg-gray-300 shadow-sm rounded-lg">
          {pictures.length > 0 ? (
            <ul className="px-2 py-2 rounded-lg flex flex-col justify-center gap-2">
              {pictures.map((picture, index) => (
                <li className="bg-gray-200 rounded-full px-4 py-1 text-center shadow-md text-md flex justify-center items-center gap-1">
                  <span className="font-semibold text-gray-500">Foto {index + 1} - </span>
                  <CiTrash 
                    className="inline-block text-gray-600 cursor-pointer"
                    data-tooltip-id={`delete-imagepic-${index}`}
                    data-tooltip-content="Remover"
                    data-tooltip-place="bottom"
                    onClick={() => {setPictures(pictures.filter((v, i) => i != index))}}
                    style={{
                      width: '25px',
                      height: '25px'
                    }}
                  />
                  <Tooltip id={`delete-imagepic-${index}`} />
                  <a href={picture.src} target="_blank">
                    <GoLinkExternal 
                      data-tooltip-id={`visualize-imagepic-${index}`}
                      data-tooltip-content="Visualizar"
                      data-tooltip-place="bottom"
                      className="inline-block text-gray-600"
                      style={{
                        width: '20px',
                        height: '20px'
                      }}
                    />
                    <Tooltip id={`visualize-imagepic-${index}`} />
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <div className="flex h-full px-4 justify-center items-center text-center text-gray-500">
              <p>Adicione as imagens.</p>
            </div>
          )}
        </div>
        <UploadButton 
          options={options}
          onComplete={files => {
            console.log("pictures");
            console.log(pictures)
            console.log("new files");
            console.log(files.map(x => ({src: x.fileUrl, alt: ''})));

            const union = [...pictures, ...files.map(x => ({src: x.fileUrl, alt: ''}))]
            console.log("union")
            console.log(union);

            setPictures([...pictures, ...files.map(x => ({src: x.fileUrl, alt: ''}))])
          }}
        >
          {({onClick}) =>
            <button
              className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-lg transition transition-duration-300"
              onClick={onClick}
            >
              Enviar fotos
            </button>
          }
        </UploadButton>
      </div>
    </div>
  )
}

export default PicturesUploader;