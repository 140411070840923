interface Identifiable {
  id: string;
}

function getMaxIndexByIdSuffix<T extends Identifiable>(
  items: T[],
  idPrefix: string,
  separator: string = '-'
): number {
  let maxIndex = -1;
  items.forEach(item => {
    if (item.id.includes(idPrefix)) {
      const currentIdNumber = parseInt(item.id.split(separator).pop() || '', 10);
      if (!isNaN(currentIdNumber) && currentIdNumber > maxIndex) {
        maxIndex = currentIdNumber;
      }
    }
  });
  return maxIndex;
}

export default getMaxIndexByIdSuffix;