import { Draggable } from "@hello-pangea/dnd";
import React from "react";
import { Category, SubscriptionCategory } from "../../../../types/EventData";
import truncateString from "../../../../utils/helpers/truncateString";
import { FaTrash } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";

interface SubscriptionCategoryListItemProps {
  subscriptioncategory: SubscriptionCategory;
  index: number;
  selected?: boolean;
  handleDelete: (categoryId: string) => void;
  onClick: (categoryId: string) => void;
}

export default function SubscriptionCategoryListItem({subscriptioncategory, index, selected, onClick, handleDelete}: SubscriptionCategoryListItemProps) {
  return (
    <Draggable draggableId={subscriptioncategory.id} index={index}>
      {(provided) => (
        <li
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={`${selected ? 'bg-neutral-900 text-white' : 'bg-stone-200 text-stone-900'} w-full mt-2 rounded-full shadow-md transition-colors duration-300 ease-in-out flex items-center justify-between px-8 py-2 gap-4`}
          onClick={() => onClick(subscriptioncategory.id)}
        >
          <div className="flex items-center gap-2">
            <p className="uppercase text-sm font-semibold tracking-wider">
              {truncateString(subscriptioncategory.title, 30)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              data-tooltip-id={`category-${index + 1}-tooltip`}
              data-tooltip-content="Deletar"
              data-tooltip-place="bottom"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(subscriptioncategory.id);
              }}
            >
              <FaTrash />
            </button>
            <Tooltip id={`category-${index + 1}-tooltip`} />
          </div>
        </li>
      )}
    </Draggable>
  )
}