import { Draggable } from "@hello-pangea/dnd";
import React from "react";
import { Sponsor } from "../../../../types/EventData";
import truncateString from "../../../../utils/helpers/truncateString";
import { FaTrash } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";

interface SponsorListItem {
  sponsor: Sponsor;
  index: number;
  selected?: boolean;
  handleDelete: (sponsorId: string) => void;
  onClick: (sponsorId: string) => void;
}

function SponsorListItem({sponsor, index, selected, handleDelete, onClick}: SponsorListItem) {
  return (
    <Draggable draggableId={sponsor.id} index={index}>
      {(provided) => (
        <li
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={`${selected ? 'bg-neutral-900 text-white' : 'bg-stone-200 text-stone-900'} w-full mt-2 rounded-full shadow-md transition-colors duration-300 ease-in-out flex items-center justify-between px-8 py-2 gap-4`}
          onClick={() => onClick(sponsor.id)}
        >
          <div className="flex items-center gap-2">
            <p className="uppercase text-sm font-semibold tracking-wider">
              {truncateString(sponsor.name, 30)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              data-tooltip-id={`sponsor-${index + 1}-tooltip`}
              data-tooltip-content="Deletar"
              data-tooltip-place="bottom"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(sponsor.id);
              }}
            >
              <FaTrash />
            </button>
            <Tooltip id={`sponsor-${index + 1}-tooltip`} />
          </div>
        </li>
      )}
    </Draggable>
  );
}

export default SponsorListItem;