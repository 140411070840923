import React from "react";
import { useNavigate, useParams } from "react-router-dom";

type StepRender4Props = {
  paymentStatus: string;
  subscriberEmail: string;
}

function StepRender4({subscriberEmail, paymentStatus}: StepRender4Props) {
  const { eventSlug } = useParams();

  return (
    <div className="stepRender4">
      <div className="text-center flex flex-col items-center">
        <p 
          className={`${paymentStatus == "approved" ? "text-green-700" : "text-red-700"}`}
          style={{
            fontSize: '10rem'
          }}
        >
          {paymentStatus == "approved" ? '✓' : '✕'}
        </p>
        <p className={`${paymentStatus == "approved" ? "text-green-700" : "text-red-700"} text-2xl font-bold`}>
          {paymentStatus == "approved" ? (
            "Sua inscrição foi confirmada!"
          ) : (
            "Houve um erro com sua inscrição, verifique seu método de pagamento e tente novamente."
          )}
        </p>
        <p
          className="text-zinc-700 font-bold text-lg pt-12 w-2/3"
        >
          O restante das informações foram enviadas para o seu email &lt;<span className="text-zinc-500">{subscriberEmail}</span>&gt;
        </p>
        <a
          href={`/${eventSlug}`}
          className="text-slate-500 border-2 border-slate-500 hover:text-white hover:bg-slate-500 text-slate-500 px-14 py-2 text-lg font-semibold mt-16 mb-6 rounded-lg transition-all"
        >
          Finalizar Inscrição
        </a>
      </div>
    </div>
  );
}

export default StepRender4;