import React, { ReactNode } from "react";

interface ListItemProps {
  selected?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export default function ListItem({selected, onClick, children, className}: ListItemProps) {
  return (
    <div
      className={`${selected ? 'bg-neutral-900 text-white' : 'bg-stone-200 text-stone-900'} w-full mt-2 rounded-full shadow-md transition-colors duration-300 ease-in-out ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}