import React from "react";
import { Participant } from "../../../types/EventData";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Tooltip } from "react-tooltip";

type PersonItemContainer = {
  person: Participant;
  functionData?: Function;
  onCurriculumClick: (participant: Participant) => void;
}

function PersonItemContainer({
  person,
  functionData,
  onCurriculumClick,
}: PersonItemContainer) {
  return (
    <div className='flex items-center'>
      <img src={person.profilepic} className='profile-pic max-w-10 max-h-10 mr-6' />
      <p className='text-md flex'>
        {functionData && (
          <span className="font-semibold">{functionData.name}: </span>
        )}
        <span className="ms-2">{person.name}</span>
      </p>
      <button 
        className="text-black px-4 py-2 rounded-md"
        onClick={() => onCurriculumClick(person)}
        data-tooltip-id={`participant-cv-${person.id}`}
        data-tooltip-content="Ver mini currículo"
        data-tooltip-place="bottom"
      >
        <FaExternalLinkAlt />
      </button>
      <Tooltip id={`participant-cv-${person.id}`} />
    </div> 
  );
}

export default PersonItemContainer;