import React from "react";

function CredentechNavbar() {
  return (
    <div className="CredentechNavbar">
      <div className="relative">
        <nav className="relative pt-12 ps-8">
          <a href="/">
            <img 
              className='m-auto max-w-48'
              src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/credentechlogo.png" 
              alt="Credentech" 
            />
          </a>
        </nav>
      </div>
    </div>
  );
}

export default CredentechNavbar;