import React from "react";
import { Participant, SchedulePlace, ScheduleProgramming } from "../../../types/EventData";
import ModuleContainer from "../ModuleContainer";

interface PlaceContainerProps {
  place: SchedulePlace;
  endTime: Date;
  onCurriculumClick: (participant: Participant) => void;
}

export default function PlaceContainer({
  place,
  endTime,
  onCurriculumClick,
}: PlaceContainerProps) {
  const minutesReducer = (accumulator: number, reducer: ScheduleProgramming) => accumulator + reducer.minutes;

  return (
    <div>
      <p className="bg-black text-white px-2 py-1 rounded-md font-semibold">
        • {place.title}
      </p>
      <div className="flex flex-col gap-6">
        {place.placemodules.map((module, index) => {
          const accumulatedMinutes = place.placemodules.slice(0, index).reduce((accumulator, reducer) => accumulator + reducer.moduleprogrammings.reduce(minutesReducer, 0), 0);
          const moduleDuration = module.moduleprogrammings.reduce(minutesReducer, 0);

          const startDate = new Date(endTime.getTime() + accumulatedMinutes * 60000);
          const endDate = new Date(startDate.getTime() + moduleDuration * 60000); 

          return (
            <ModuleContainer
              module={module}
              startDate={startDate}
              endDate={endDate}
              onCurriculumClick={onCurriculumClick}
            />
          );
        })}
      </div>
    </div>
  );
}