import React, { useState } from "react";
import ProgressItem from "./ProgressItem";

const stepsInfo = [
  "Informações gerais",
  "Detalhes do local",
  "Categorias",
  "Gerenciamento de pessoas",
  "Funções",
  "Programação",
  "Patrocinadores",
  "Inscrições",
  "Expositores",
  "Configurações"
]

type EventManagerProgressProps = {
  currentStep?: number;
  stepChange?: (step: number) => void;
}

function EventManagerProgress({currentStep, stepChange}: EventManagerProgressProps) {
  return (
    <div className="eventManagerProgress sticky top-0 z-10 bg-white py-6 2xl:py-0">
      <ul className="text-sm 2xl:text-lg font-bold flex justify-center gap-2 2xl:gap-4">
        {stepsInfo.map((step, index) => (
          <ProgressItem delay={0.05 * (index + 1)} selected={currentStep == index}>
            <a 
              onClick={() => stepChange!(index)}
              className="cursor-pointer"
            >
              {step}
            </a>
          </ProgressItem>
        ))}
      </ul>
    </div>
  );
}

export default EventManagerProgress;
