import React from "react";
import { useParams } from "react-router-dom";

type HeaderProps = {
  logo : string;
  small?: boolean;
  large?: boolean;
  center?: boolean;
  right?: boolean;
  [key : string] : any;
}

function Header({logo, small, large, center, right, ...props} : HeaderProps) {
  const { eventSlug } = useParams();

  let widthClass = 'w-72 py-8';
  if (small) {
    widthClass = 'w-16';
  } else if (large) {
    widthClass = 'w-80';
  }

  let marginClass = '';
  if (center) {
    marginClass = 'm-auto';
  } else if (right) {
    marginClass = 'ml-auto'; 
  }

  const imageClass = `${widthClass} ${marginClass}`;

  return (
    <header className="sm:m-0 m-auto">
      <a href={`/${eventSlug}`} className="inline-block">
        <img 
          src={logo}
          className={imageClass} 
          alt="Logo" 
        />
      </a>
    </header>
  );
}

export default Header;