import { motion } from "framer-motion";
import React from "react";
import SubscribersDashboardTable from "../components/SubscribersDashboardTable";
import { useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function SubscriptionDashboard() {
  const {eventId} = useParams();

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="subscriptionDashboard flex flex-col items-center relative" // Garanta que o container seja relativo
    >
      <div className="absolute w-full h-full p-8 z-10">
        <a href="/events" className="inline-block"><FaArrowLeftLong style={{width: '35px', height: '35px'}} /></a>
      </div>
      <h1 className="font-bold text-3xl tracking-wide text-center py-6">Inscritos no Evento</h1>
      {eventId ? (
        <SubscribersDashboardTable eventId={eventId} />
      ) : (
        <p>Error while trying to load subscribers dashboard table: Event id is null</p>
      )}
    </motion.div>
  );
}
