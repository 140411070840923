import React, { useEffect, useState } from "react";
import TableHead from "./TableHead";
import TableCell from "./TableCell";
import { RiEyeFill, RiSettings2Fill } from "react-icons/ri";
import { EventSubscriber } from "../../types/EventData";
import { fetchEventDataById, getEventSubscribers } from "../../utils/api";
import convertDateToString from "../../utils/helpers/convertDateToString";
import formatBRL from "../../utils/helpers/formatBRL";
import TooltipIcon from "../../utils/ui/TooltipIcon";
import SubscriberModal from "./SubscriberModal";
import { FaArrowLeftLong } from "react-icons/fa6";
import formatTelephone from "../../utils/helpers/formatTelephone";
import { AnimatePresence } from "framer-motion";

interface SubscribersDashboardTableProps {
  eventId: string;
}

export default function SubscribersDashboardTable({eventId}: SubscribersDashboardTableProps) {
  const [subscribers, setSubscribers] = useState<EventSubscriber[]>();
  const [modalSubscriber, setModalSubscriber] = useState<EventSubscriber>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();

  useEffect(() => {
    getEventSubscribers(eventId).then(setSubscribers);
    
    (async () => {
      const eventData = await fetchEventDataById(parseInt(eventId));
      setStartDate(new Date(eventData.startdate));
    })();
  }, []);

  const handleSubscriberView = (subscriber: EventSubscriber) => {
    setIsModalVisible(true);
    setModalSubscriber(subscriber);
  }

  return (
    <div className="subscribersDashboardTable z-20">
      <table className="table-auto min-w-full leading-normal">
        <thead>
          <tr className="text-center">
            <TableHead>Primeiro Nome</TableHead>
            <TableHead>Sobrenome</TableHead>
            <TableHead>Celular</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Categoria</TableHead>
            <TableHead>Valor</TableHead>
            <TableHead>Data de Inscrição</TableHead>
            <TableHead>Ação</TableHead>
            {/* <TableHead>Status</TableHead> */}
          </tr>
        </thead>
        <tbody>
          {subscribers && subscribers.map((subscriber, index) => (
            <tr className="bg-gray-50 hover:bg-gray-100 transition-all mb-4">
              <TableCell>{subscriber.name}</TableCell>
              <TableCell>{subscriber.surname}</TableCell>
              <TableCell>{formatTelephone(subscriber.area_code.toString() + subscriber.number.toString())}</TableCell>
              <TableCell>{subscriber.email}</TableCell>
              <TableCell>{subscriber.subscriptioncategory.title}</TableCell>
              <TableCell>R$ {formatBRL(subscriber.valuepaid)}</TableCell>
              <TableCell>{convertDateToString(subscriber.dateofsubscription)}</TableCell>
              <TableCell>
                <TooltipIcon
                  id={`edit-sb-${index}`}
                  content="Editar"
                  Icon={RiSettings2Fill}
                  height={25}
                  width={25}
                  disabled={startDate && new Date().getTime() >= startDate.getTime()}
                />
                <TooltipIcon 
                  id={`view-sb-${index}`}
                  content="Visualizar"
                  Icon={RiEyeFill}
                  height={25}
                  width={25}
                  onClick={() => handleSubscriberView(subscriber)}
                />
              </TableCell>
              {/* <TableCell>
                <div className="text-sm text-green-800 font-bold uppercase bg-green-400 px-4 py-1 rounded-full">
                  {data.status}
                </div>
              </TableCell> */}
            </tr>
          ))}
        </tbody>
      </table>
      {(!subscribers || subscribers?.length == 0) && (
        <p className="text-4xl text-center font-black text-stone-300">Não há nenhum inscrito no evento.</p>
      )}
      {modalSubscriber && (
        <AnimatePresence>
          <SubscriberModal
            modalSubscriber={modalSubscriber}
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
          />
        </AnimatePresence>
      )}
    </div>
  );
}