import React from "react";

type DotProps = {
  color: string;
  className?: string;
  [key: string] : any;
}

function Dot({color, className} : DotProps) {
  return (
    <div 
      className={`dot h-4 min-w-4 rounded-full ${className}`}
      style={{
        backgroundColor: `${color}`
      }}  
     >
    </div>
  );
}

export default Dot;