import React from "react";

interface TextInputProps {
  title: string;
  placeholder: string;
  value: string;
  onChange?: (value: string) => void;
  center?: boolean;
}

export default function TextInput({
  title, 
  placeholder, 
  center,
  value, 
  onChange
}: TextInputProps) {
  return (
    <div className={`flex flex-col gap-2 ${center && 'items-center'}`}>
      <h2 className="text-xl 2xl:text-3xl font-bold">{title}</h2>
      <input 
        type="text" 
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        className="w-full text-xs shadow-lg rounded-full px-8 font-semibold py-2 border max-w-80"
      />
    </div>
  );
}