import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import EventBanner from "../../components/Introduction/EventBanner";
import IntroductionSection from "../../components/Introduction/IntroductionSection";
import LayoutSection from "../../components/LayoutSection";
import SpeakerCarousel from "../../components/Speakers/SpeakerCarousel";
import QuickLinks from "../../components/QuickLinks";
import SectionHeader from "../../components/SectionHeader";
import LocationDetails from "../../components/Location/LocationDetails";
import LocationAddress from "../../components/Location/LocationAddress";
import LocationMap from "../../components/Location/LocationMap";
import SponsorsContainer from "../../components/SponsorsContainer";
import LayoutContainer from "../../components/LayoutContainer";
import Navbar from "../../components/Header/Navbar";
import { useFetchDemoData } from "../../hooks/useFetchDemoData";
import Loading from "../Loading";
import useWindowSize from "../../hooks/useWindowSize";
import VideoPlayer from "../../utils/ui/VideoPlayer";

interface Layout1Props {
  eventSlug: string;
}

function Layout1({eventSlug}: Layout1Props) {
  const {data, loading} = useFetchDemoData();
  const {windowSize} = useWindowSize();

  if (loading) {
    return (<Loading />);
  }
  
  return (
    <div className="Layout1 layout">
        <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
          <Navbar color1={data?.color1!} className="pb-2 pt-6" logo={data?.logourl!} layout={data?.layout!} />
          <LayoutSection noBorder>
            <EventBanner
              color1={data?.color1!}
              color2={data?.color2!}
              banner={data?.banner!}
              eventDate={data?.startdate!}
              place={data?.place!}
            />
            <IntroductionSection
              title="Introdução"
              content={data!.introduction}
              author={data?.participants.find((participant) => participant.introductionauthor)!}
            />
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Palestrantes</SectionHeader>
            <div className="flex justify-center items-center gap-12 py-8">
              <div className="w-2/3">
                <SpeakerCarousel
                  color1={data?.color1!}
                  authors={data?.participants.filter((participant) => participant.isspeaker)!}
                />
              </div>
              <div className="w-1/2">
                <QuickLinks eventSlug={eventSlug} />
              </div>
            </div>
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Localização</SectionHeader>
            <LocationDetails name={data?.place.name!} description={data?.place.description!}  />
            <div className="flex">
              <div>
                <LocationMap className="flex justify-center my-12"  location={data?.place!} />
                <LocationAddress
                  street={data?.place.street!}
                  number={data?.place.number!}
                  district={data?.place.district!}
                  city={data?.place.city!}
                  state={data?.place.state!}
                  telephone={data?.place.telephone!}
                />
              </div>
              <div
                style={{
                  maxWidth: (windowSize.width < 1200 ? '400px' : '550px'),
                  margin: 'auto'
                }}
              >
                <Swiper
                  modules={[Navigation, Pagination]}
                  pagination={{clickable: true}}
                  navigation={true}
                >
                  {data?.place.imgs?.map((placeImgSrc) => {
                    const width = windowSize.width < 1200 ? 300 : 400;
                    const height = windowSize.width < 900 ? 320 : 400;
                    return (  
                      <SwiperSlide>
                        <img className={`w-[${width}px] h-[${height}px] pb-4 m-auto`} src={placeImgSrc.src} alt={placeImgSrc.alt} />
                      </SwiperSlide>
                    )
                  })}
                  {data?.place.youtubeurl && (
                    <SwiperSlide>
                      <VideoPlayer 
                        url={data?.place.youtubeurl}
                        width={windowSize.width < 1200 ? 300 : 400}
                        height={windowSize.width < 900 ? 320 : 400}
                      />
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
            </div>
          </LayoutSection>
          <LayoutSection>
            <SponsorsContainer 
              sponsorCategories={data?.sponsors!}
            />
          </LayoutSection>
        </LayoutContainer>
    </div>
  );
}

export default Layout1;