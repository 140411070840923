import React from 'react'

interface ColumnListProps extends React.HTMLAttributes<HTMLLIElement> {
  children?: React.ReactNode;
}

function ColumnList({children} : ColumnListProps) {
  return (
    <div className="border-l-2 pl-8  border-gray-400">
      <ul className="flex flex-col text-center gap-5">
        {children}
      </ul>
    </div>
  );
}

export default ColumnList;