import React, { useState } from "react";
import LogoUploader from "../FormInputs/LogoUploader";
import EventTitleInput from "../FormInputs/EventTitleInput";
import PicturesUploader from "../FormInputs/PicturesUploader";
import StartDateInput from "../FormInputs/StartDateInput";
import ColorSelector from "../FormInputs/ColorSelector";
import EventIntroductionInput from "../FormInputs/DescriptionInput";
import { EventData, Image } from "../../../types/EventData";
import PaymentDateInput from "../FormInputs/PaymentDateInput";

type InfoProps = {
  eventData: EventData;
  handleFormChange: (data: {[key: string]: any}) => void;
  submitForm: () => void;
}

function Info({eventData, handleFormChange}: InfoProps) {
  return (
    <div className="info bg-white">
      <div className="px-16 py-8 flex flex-col items-center 2xl:items-start 2xl:flex-row justify-center gap-24">
        <div className="flex-0 flex flex-col items-center 2xl:items-start gap-2">
          <EventTitleInput 
            title={eventData.banner.title}
            handleFormChange={(title) => handleFormChange({
              banner: {
                title: title
              }
            })}
          />
          <LogoUploader 
            imageUrl={eventData.logourl}
            setImageUrl={(newImage) => handleFormChange({
              logourl: newImage
            })}
          />
          <div className="flex gap-8">
            <ColorSelector 
              title="Cor Primária" 
              rgbColor={eventData.color1!}
              handleFormChange={(rgb: number[]) => handleFormChange({
                color1: rgb
              })}
            />
            <ColorSelector 
              title="Cor Secundária" 
              rgbColor={eventData.color2!}
              handleFormChange={(rgb: number[]) => handleFormChange({
                color2: rgb
              })}
            />
            {/* <p>{eventData.color1!.join('-')}</p> */}
            {/* <p>{eventData.color2!.join('-')}</p> */}
          </div>
        </div>
        <div className="flex-0 flex flex-col items-center 2xl:items-start gap-8 2xl:gap-12">
          <StartDateInput 
            date={eventData.startdate.toISOString().split('T')[0]}
            handleFormChange={(date) => handleFormChange({
              startdate: new Date(date)
            })}
          />
          <PaymentDateInput 
            date={eventData.paymentdate.toISOString().split('T')[0]}
            handleFormChange={(date) => handleFormChange({
              paymentdate: new Date(date)
            })}
          />
          <PicturesUploader 
            title="Fotos Principais"
            pictures={eventData.banner.backgroundimgs}
            setPictures={(newPics) => handleFormChange({
              banner: {
                backgroundimgs: newPics
              }
            })}
          />
        </div>
        <div className="flex-0 flex flex-col items-center 2xl:items-start" style={{height: '600px'}}>
          <EventIntroductionInput
            content={eventData.introduction.join('\n')}
            handleFormChange={(content) => {
              handleFormChange({
                introduction: content.split('\n')
              })
            }}
            title="Introdução do Evento"
            placeholder="Escreva aqui a descrição do evento..."
          />
        </div>
      </div>
    </div>
  );
}

export default Info;