import React from "react";

type DescriptionInputProps = {
  content: string;
  handleFormChange: (content: string) => void;
  title: string;
  placeholder: string;
}

function DescriptionInput({content, handleFormChange, title, placeholder}: DescriptionInputProps) {
  return (
    <div className="descriptionInput flex-grow flex flex-col">
      <h2 
        className="text-2xl font-semibold mb-4"
      >
        {title}
      </h2>
      <div className="flex-grow flex">
        <textarea
          className="bg-gray-100 shadow-xl border rounded-lg p-4 2xl:h-[600px] w-[400px] h-full resize-none"
          placeholder={placeholder}
          value={content}
          onChange={(e) => handleFormChange(e.target.value)}
        />
      </div>
    </div>
  );
}

export default DescriptionInput;