import React from "react";

interface InsertListButtonProps {
  onClick?: () => void;
}

export default function InsertListButton({onClick}: InsertListButtonProps) {
  return (
    <button 
      className="border-2 w-7 h-7 border-black flex justify-center items-center cursor-pointer rounded-full text-2xl font-semibold hover:bg-black hover:text-white transition-colors duration-300"
      onClick={onClick}
    >
      +
    </button>
  );
}