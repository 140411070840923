import React from "react";
import { ScheduleDay, ScheduleModule, SchedulePlace, ScheduleProgramming } from "../../../../../types/EventData";
import formatTime from "../../../../../utils/helpers/formatTime";
import addMinutesFromDate from "../../../../../utils/helpers/addMinutesFromDate";
import DraggableList from "../../DraggableList";
import truncateString from "../../../../../utils/helpers/truncateString";

interface ProgrammingListProps {
  programming: ScheduleProgramming[];
  scheduleDay: ScheduleDay;
  schedulePlace: SchedulePlace;
  modules: ScheduleModule[];
  moduleIndex: number;
  selectedId?: string;
  handleProgrammingSelect: (id?: string) => void;
  handleProgrammingCreate: () => void;
  handleProgrammingUpdate: (newProgramming: ScheduleProgramming[]) => void;
}

export default function ProgrammingList({
  programming,
  scheduleDay,
  schedulePlace,
  modules,
  moduleIndex,
  selectedId,
  handleProgrammingCreate,
  handleProgrammingSelect,
  handleProgrammingUpdate,
}: ProgrammingListProps) {
  const getModuleDuration = (module: ScheduleModule) =>
    module.moduleprogrammings.reduce((totalMinutes, prog) => totalMinutes + prog.minutes, 0);

  const getPlaceDuration = (place: SchedulePlace): number => 
    place.placemodules.reduce((acc, red) => acc + getModuleDuration(red), 0);

  const getPlacesDuration = (places: SchedulePlace[]): number =>
    places.reduce((acc, red) => acc + getPlaceDuration(red), 0);

  const getModuleStartTime = () => {
    const accumulatedMinutes = modules
      .slice(0, moduleIndex)
      .reduce((totalMinutes, module) => totalMinutes + getModuleDuration(module), 0);
    return addMinutesFromDate(addMinutesFromDate(scheduleDay.date, getPlacesDuration(scheduleDay.dayplaces.slice(0, scheduleDay.dayplaces.indexOf(schedulePlace)))), accumulatedMinutes);
  };

  const getProgrammingAccumulatedMinutesByIndex = (programmingIndex: number) =>
    programming.slice(0, programmingIndex).reduce((totalMinutes, prog) => totalMinutes + prog.minutes, 0);

  const moduleStartTime = getModuleStartTime();

  const getProgrammingStartTime = (index: number) =>
    addMinutesFromDate(moduleStartTime, getProgrammingAccumulatedMinutesByIndex(index));

  const getProgrammingEndTime = (index: number) =>
    addMinutesFromDate(getProgrammingStartTime(index), programming[index].minutes);

  return (
    <DraggableList
      id="droppable-programming"
      title="Programação"
      emptyText="Adicione uma nova programação"
      className="h-96 overflow-y-scroll px-1"
      items={programming}
      selectedId={selectedId}
      onSelect={handleProgrammingSelect}
      onCreate={handleProgrammingCreate}
      onOrderChange={handleProgrammingUpdate}
      onDelete={(id: string) => handleProgrammingUpdate(programming.filter(programming => programming.id !== id))}
      renderItem={(item: ScheduleProgramming, index: number) => (
        <p className="uppercase text-sm font-semibold flex gap-2">
          {truncateString(item.theme, 15)}
          <span
            className={`${selectedId === item.id ? "text-gray-100" : "text-gray-500"}`}
            style={{
              fontSize: "0.7rem",
            }}
          >
            {item.minutes
              ? `${formatTime(getProgrammingStartTime(index))} - ${formatTime(getProgrammingEndTime(index))}`
              : "--:-- - --:--"}
          </span>
        </p>
      )}
    />
  );
}
