import { FunctionData } from "../../../../types/EventData";

interface EditFunctionFormProps {
  functionData: FunctionData;
  onChange: (key: keyof FunctionData, value: any, functionId: string) => void;
}

export default function EditFunctionForm({ 
  functionData, 
  onChange 
}: EditFunctionFormProps) {
  return (
    <div className="editFunctionForm">
      <h2 className="text-3xl font-bold mb-4">Edição de Função</h2>
      <div className="flex flex-col gap-12">
        <div>
          <h2 className="text-xl font-bold">Nome</h2>
          <input 
            type="text" 
            placeholder="DIGITE O NOME DA FUNCAO" 
            value={functionData.name}
            onChange={(e) => onChange("name", e.target.value, functionData.id)}
            className="w-full text-xs shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border max-w-80"
          />
        </div>
      </div>
    </div>
  );
}
