import React, { useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Tooltip } from "react-tooltip";

import {Category} from '../../types/EventData';
import {Participant} from '../../types/EventData';
import CurriculumModal from "../CurriculumModal";

type CategorySectionProps = {
  category: Category;
  participants: Participant[];
  containerLayout?: boolean;
}

function CategorySection({category, participants, containerLayout}: CategorySectionProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [participant, setParticipant] = useState<Participant | null>(null);

  participants.sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0));

  return (
    <div className={`ml-5 ${containerLayout ? '' : 'mb-5'}`}>
      <p className='tracking-widest text-sm uppercase font-bold'>{category.name}</p>
      <div className='flex flex-col sm:flex-row gap-4 items-center'>
        {participants.map((participants, index) => (
          <div className={`${containerLayout ? 'my-2' : 'my-8'} w-full sm:w-1/2 flex items-center bg-slate-50 px-8 py-4 rounded-lg`}>
            <img className='profile-pic max-w-20 max-h-20 mr-6' src={participants.profilepic} />
            <p className='font-semibold text-lg'>{participants.name}</p>
            <button 
              className="text-black px-4 py-2 rounded-md"
              onClick={() => {
                setParticipant(participants);
                setIsModalOpen(true);
              }}
              data-tooltip-id={`participant-cv-${index}`}
              data-tooltip-content="Ver mini currículo"
              data-tooltip-place="bottom"
            >
              <FaExternalLinkAlt />
            </button>
            <Tooltip id={`participant-cv-${index}`} />
          </div>
        ))}
      </div>
      {participant && (
        <CurriculumModal
          participant={participant}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </div> 
  );
}

export default CategorySection;