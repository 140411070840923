import React from "react";

interface LayoutSectionGroupProps extends React.HTMLAttributes<HTMLLIElement> {
  children?: React.ReactNode;
  containerized?: boolean;
}

function LayoutSectionGroup({children, containerized}: LayoutSectionGroupProps) {
  return (
    <div className={`layoutSectionGroup ${containerized ? 'bg-white px-8 py-4' : ''} rounded-lg`}>
      <div className="px-5 py-4">
        {children}
      </div>
    </div>
  );
}

export default LayoutSectionGroup;