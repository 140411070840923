import React from "react";
import { Category } from "../../../../types/EventData";

interface EditCategoryFormProps {
  category: Category;
  sponsor?: boolean;
  onChange: (key: keyof Category, value: any, categoryId: string) => void;
}

function EditCategoryForm({category, sponsor, onChange}: EditCategoryFormProps) {
  return (
    <div className="editCategoryForm">
      <h2 className="text-3xl font-bold mb-4">Edição de Categoria</h2>
      <div className="flex flex-col gap-12">
        <div>
          <h2 className="text-xl font-bold">Nome</h2>
          <input 
            type="text" 
            placeholder="DIGITE O NOME DA CATGORIA"
            value={category.name}
            onChange={(e) => onChange("name", e.target.value, category.id)}
            className="w-full text-xs shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border max-w-80"
          />
        </div>
        {!sponsor && (
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <h2 className="text-md font-bold">Categoria de Convidados</h2>
              <input 
                type="checkbox"
                checked={category.guestcategory}
                onChange={(e) => onChange("guestcategory", e.target.checked, category.id)}
              />
            </div>
            {!category.guestcategory && (
              <p className="font-semibold text-gray-200">Categorias não marcadas como de convidado, serão de comissão.</p>
            )}
            {/* <div className="flex gap-4">
              <h2 className="text-md font-bold">Categoria de Comissão</h2>
              <input 
                type="checkbox"
                checked={category.guestcategory}
                onChange={(e) => onChange("guestcategory", e.target.checked, category.id)}
              />
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default EditCategoryForm;