import React from "react";
import ReactPlayer from "react-player";

type VideoPlayerProps = {
  url: string;
  width: number;
  height: number;
}

function VideoPlayer({url, width, height}: VideoPlayerProps) {
  return (
    <div className="videoPlayer flex justify-center my-4">
      <ReactPlayer
        controls
        width={width}
        height={height}
        url={url} 
      />
    </div>
  );
}

export default VideoPlayer;