import React from "react";
import { Participant, ScheduleProgramming } from "../../../types/EventData";
import PersonItemContainer from "../PersonItemContainer";

interface ProgrammingContainerProps {
  programming: ScheduleProgramming;
  compose?: boolean;
  startDate: Date;
  endDate: Date;
  onCurriculumClick: (participant: Participant) => void;
}

export default function ProgrammingContainer({
  programming,
  compose,
  startDate,
  endDate,
  onCurriculumClick,
}: ProgrammingContainerProps) {
  const formatDate = (date: Date) => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <div className={`${programming.assignments.length > 0 ? 'mt-2' : ''}`}>
      {compose && (
        <p className="font-semibold text-sm">
          {formatDate(startDate)} - {formatDate(endDate)} | {programming.theme}
        </p>
      )}
      {programming.assignments.length > 0 && (
        <div className="flex flex-col gap-2 px-2 py-4">
          {programming.assignments.map((assignment) => (
            <PersonItemContainer
              key={assignment.participant.id}
              person={assignment.participant}
              functionData={assignment.function as any} 
              onCurriculumClick={onCurriculumClick}
            />
          ))}
        </div>
      )}
    </div>
  );
}
