import React from "react";
import Wave from "react-wavify";

function PageWaves() {
  return (
    <div className="pageWaves">
      <Wave
        fill='#FF6B00'
        paused={false}
        className="absolute bottom-0 left-0 w-full"
        options={{
          height: 25,
          amplitude: 25,
          speed: 0.15,
          points: 3
        }}
      />
      <Wave
        fill='#FF6B004F'
        paused={false}
        className="absolute bottom-1 left-0 w-full"
        options={{
          height: 30,
          amplitude: 30,
          speed: 0.35,
          points: 3
        }}
      />
      <Wave
        fill='#FF6B006F'
        paused={false}
        className="absolute bottom-2 left-0 w-full"
        options={{
          height: 35,
          amplitude: 35,
          speed: 0.5,
          points: 3
        }}
      />
      <Wave
        fill='#FF6B008F'
        paused={false}
        className="absolute bottom-3 left-0 w-full"
        options={{
          height: 40,
          amplitude: 40,
          speed: 0.5,
          points: 3
        }}
      />
    </div>
  )
}

export default PageWaves;