import React, { useState } from "react";
import { EventData, FunctionData } from "../../../types/EventData";
import FunctionList from "../FormInputs/FunctionList";
import EditFunctionForm from "../FormInputs/EditFunctionForm";

type FunctionsProps = {
  eventData: EventData;
  handleFormChange: (data: {[key: string]: any}) => void;
  submitForm: () => void;
  isEditing: boolean;
}

function Functions({eventData, handleFormChange}: FunctionsProps) {
  const [selectedFunction, setSelectedFunction] = useState<FunctionData>();

  const handleFunctionSelect = (selectedId: string) => {
    setSelectedFunction(eventData.functions.find((func) => func.id === selectedId));
  };

  const handleFunctionUpdate = (updatedFunctions: FunctionData[]) => {
    handleFormChange({
      functions: updatedFunctions
    });
  };

  const handleFunctionChangeInput = (key: keyof FunctionData, value: any, functionId: string) => {
    const functionUpdate = [...eventData.functions];
    const selectedFunction = functionUpdate.find(func => func.id === functionId);

    if (selectedFunction) {
      //@ts-ignore
      selectedFunction[key] = value;
    }

    handleFormChange({
      functions: functionUpdate
    });
  };

  return (
    <div className="functions">
      <div className="px-36 py-16 flex">
        <div>
          <h2 className="text-3xl font-bold mb-4">Funções</h2>
          <FunctionList
            functions={eventData.functions}
            onSelect={handleFunctionSelect}
            onDelete={handleFunctionUpdate}
            onCreate={handleFunctionUpdate}
            onDrag={handleFunctionUpdate}
          />
        </div>
        <div className="flex-grow max-w-[800px] px-12">
          {selectedFunction ? (
            <EditFunctionForm
              functionData={selectedFunction}
              onChange={handleFunctionChangeInput}
            />
          ) : (
            <div className="flex items-center h-full">
              <p className="text-lg text-gray-300 font-bold mb-4">Selecione uma função para editar</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Functions;
