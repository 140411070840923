import React from "react";

type SponsorItemProps = {
  pic?: string;
  alt?: string;
}

function SponsorItem({pic, alt}: SponsorItemProps) {
  return (
    <div className="sponsorItem">
      <img 
        src={pic} 
        alt={alt} 
        className="min-h-[50px] min-w-[50px] h-auto w-auto max-h-[50px] sm:max-h-[150px] object-contain" 
      />
    </div>
  );
}

export default SponsorItem;