export const getNationalities = () => {
  return [
    { code: "BR", name: "Brasil" },
    { code: "US", name: "Estados Unidos" },
    { code: "FR", name: "França" },
    { code: "DE", name: "Alemanha" },
    { code: "IT", name: "Itália" },
    { code: "JP", name: "Japão" },
    { code: "CN", name: "China" },
    { code: "IN", name: "Índia" },
    { code: "RU", name: "Rússia" },
    { code: "GB", name: "Reino Unido" },
    { code: "CA", name: "Canadá" },
    { code: "AU", name: "Austrália" },
    { code: "ES", name: "Espanha" },
    { code: "MX", name: "México" },
    { code: "AR", name: "Argentina" },
    { code: "ZA", name: "África do Sul" },
    { code: "KR", name: "Coreia do Sul" },
    { code: "NG", name: "Nigéria" },
    { code: "EG", name: "Egito" },
    { code: "TR", name: "Turquia" },
    { code: "SA", name: "Arábia Saudita" },
    { code: "SE", name: "Suécia" },
    { code: "CH", name: "Suíça" },
    { code: "NL", name: "Países Baixos" },
    { code: "BE", name: "Bélgica" },
    { code: "PT", name: "Portugal" },
    { code: "GR", name: "Grécia" },
    { code: "NO", name: "Noruega" },
    { code: "FI", name: "Finlândia" },
    { code: "DK", name: "Dinamarca" },
    { code: "IE", name: "Irlanda" },
    { code: "PL", name: "Polônia" },
    { code: "AT", name: "Áustria" },
    { code: "HU", name: "Hungria" },
    { code: "CZ", name: "República Tcheca" },
    { code: "RO", name: "Romênia" },
    { code: "IL", name: "Israel" },
    { code: "NZ", name: "Nova Zelândia" },
    { code: "SG", name: "Singapura" },
    { code: "MY", name: "Malásia" },
    { code: "TH", name: "Tailândia" },
    { code: "VN", name: "Vietnã" },
    { code: "PH", name: "Filipinas" },
    { code: "ID", name: "Indonésia" },
    { code: "PK", name: "Paquistão" },
    { code: "BD", name: "Bangladesh" },
    { code: "UA", name: "Ucrânia" },
    { code: "CL", name: "Chile" },
    { code: "CO", name: "Colômbia" },
    { code: "PE", name: "Peru" },
    { code: "VE", name: "Venezuela" },
    { code: "CU", name: "Cuba" },
    { code: "UY", name: "Uruguai" },
    { code: "PY", name: "Paraguai" },
    { code: "BO", name: "Bolívia" },
    { code: "EC", name: "Equador" },
    { code: "CR", name: "Costa Rica" },
    { code: "PA", name: "Panamá" },
    { code: "GT", name: "Guatemala" },
    { code: "HN", name: "Honduras" },
    { code: "SV", name: "El Salvador" },
    { code: "NI", name: "Nicarágua" },
    { code: "DO", name: "República Dominicana" },
    { code: "HT", name: "Haiti" },
    { code: "JM", name: "Jamaica" },
    { code: "TT", name: "Trinidad e Tobago" },
    { code: "BB", name: "Barbados" },
    { code: "BS", name: "Bahamas" },
    { code: "BZ", name: "Belize" },
    { code: "GY", name: "Guiana" },
    { code: "SR", name: "Suriname" },
    { code: "FJ", name: "Fiji" },
    { code: "PG", name: "Papua-Nova Guiné" },
    { code: "SB", name: "Ilhas Salomão" },
    { code: "VU", name: "Vanuatu" },
    { code: "WS", name: "Samoa" },
    { code: "TO", name: "Tonga" },
    { code: "KI", name: "Kiribati" },
    { code: "TV", name: "Tuvalu" },
    { code: "NR", name: "Nauru" },
    { code: "MH", name: "Ilhas Marshall" },
    { code: "FM", name: "Micronésia" },
    { code: "PW", name: "Palau" },
    { code: "TL", name: "Timor-Leste" },
    { code: "QA", name: "Catar" },
    { code: "KW", name: "Kuwait" },
    { code: "BH", name: "Bahrein" },
    { code: "OM", name: "Omã" },
    { code: "YE", name: "Iémen" },
    { code: "JO", name: "Jordânia" },
    { code: "LB", name: "Líbano" },
    { code: "SY", name: "Síria" },
    { code: "IQ", name: "Iraque" },
    { code: "IR", name: "Irã" },
    { code: "AF", name: "Afeganistão" },
    { code: "KZ", name: "Cazaquistão" },
    { code: "UZ", name: "Uzbequistão" },
    { code: "TM", name: "Turcomenistão" },
    { code: "KG", name: "Quirguistão" },
    { code: "TJ", name: "Tajiquistão" },
    { code: "MN", name: "Mongólia" },
    { code: "NP", name: "Nepal" },
    { code: "BT", name: "Butão" },
    { code: "LK", name: "Sri Lanka" },
    { code: "MV", name: "Maldivas" },
    { code: "MM", name: "Mianmar" },
    { code: "KH", name: "Camboja" },
    { code: "LA", name: "Laos" },
    { code: "BN", name: "Brunei" },
    { code: "MO", name: "Macau" },
    { code: "HK", name: "Hong Kong" },
    { code: "TW", name: "Taiwan" },
    { code: "KP", name: "Coreia do Norte" }
  ];
}