import React from "react";

interface LayoutProps extends React.HTMLAttributes<HTMLLIElement> {
  children: React.ReactNode;
  color1: number[];
  color2: number[];
}

function LayoutContainer({children, color1, color2}: LayoutProps) {
  return (
    <div 
      className="LayoutContainer flex flex-col justify-center sm:px-0 md:px-6 lg:px-8 xl:px-20 2xl:px-36"
      style={{
        '--swiper-navigation-color': `rgb(${color1[0]}, ${color1[1]}, ${color1[2]})`,
        '--swiper-pagination-color': `rgb(${color1[0]}, ${color1[1]}, ${color1[2]})`,
        '--swiper-pagination-active-color': `rgb(${color1[0]}, ${color1[1]}, ${color1[2]})`
      } as React.CSSProperties & { [key: string]: string | number}}  
    >
      {children}
    </div>
  );
}

export default LayoutContainer;