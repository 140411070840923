import React from "react";
import { ScheduleDay, ScheduleModule, SchedulePlace } from "../../../../../types/EventData";
import formatTime from "../../../../../utils/helpers/formatTime";
import addMinutesFromDate from "../../../../../utils/helpers/addMinutesFromDate";
import DraggableList from "../../DraggableList";
import truncateString from "../../../../../utils/helpers/truncateString";

interface ModuleListProps {
  modules: ScheduleModule[];
  scheduleDay: ScheduleDay;
  schedulePlace: SchedulePlace;
  selectedId?: string;
  handleModuleSelect: (id?: string) => void;
  handleModuleCreate: () => void;
  handleModuleUpdate: (newModules: ScheduleModule[]) => void;
}

export default function ModuleList({
  modules,
  schedulePlace,
  scheduleDay,
  selectedId,
  handleModuleCreate,
  handleModuleSelect,
  handleModuleUpdate,
}: ModuleListProps) {
  const getModuleDuration = (module: ScheduleModule): number =>
    module.moduleprogrammings.reduce(
      (totalMinutes, programming) => totalMinutes + programming.minutes,
      0
    );

  const getPlaceDuration = (place: SchedulePlace): number => 
    place.placemodules.reduce((acc, red) => acc + getModuleDuration(red), 0);

  const getPlacesDuration = (places: SchedulePlace[]): number =>
    places.reduce((acc, red) => acc + getPlaceDuration(red), 0);

  const getModuleDurationByIndex = (index: number): number =>
    modules.slice(0, index).reduce((totalMinutes, module) => totalMinutes + getModuleDuration(module), 0);

  const getModuleStartTime = (index: number): Date =>
    addMinutesFromDate(addMinutesFromDate(scheduleDay.date, getPlacesDuration(scheduleDay.dayplaces.slice(0, scheduleDay.dayplaces.indexOf(schedulePlace)))), getModuleDurationByIndex(index));

  const getModuleEndTime = (index: number): Date =>
    addMinutesFromDate(getModuleStartTime(index), getModuleDuration(modules[index]));

  return (
    <DraggableList
      id="droppable-module"
      title="Módulos"
      emptyText="Adicione um novo módulo."
      className="h-28 2xl:h-36 overflow-y-scroll px-2"
      items={modules}
      selectedId={selectedId}
      onCreate={handleModuleCreate}
      onSelect={handleModuleSelect}
      onOrderChange={handleModuleUpdate}
      onDelete={(id: string) => handleModuleUpdate(modules.filter(module => module.id !== id))}
      renderItem={(item: ScheduleModule, index: number) => (
        <p className="uppercase text-sm font-semibold flex gap-2">
          {truncateString(item.title, 15)}
          <span
            className={`${selectedId === item.id ? "text-gray-100" : "text-gray-500"}`}
            style={{
              fontSize: "0.7rem",
            }}
            >
            {`${formatTime(getModuleStartTime(index))} - ${formatTime(getModuleEndTime(index))}`}
          </span>
        </p>
      )}
    />
  );
}
