import React from "react";
import { Place } from "../../../../types/EventData";
import formatCep from "../../../../utils/helpers/formatCep";
import fetchCepData from "../../../../utils/helpers/fetchCepData";

type PlaceInputGroupProps = {
  placeData: Place;
  handleFormChange: (data: {[key: string]: any}) => void;
}

function PlaceInputGroup({placeData, handleFormChange}: PlaceInputGroupProps) {
  const handleCepChange = (cep: string) => {
    handleFormChange({
      place: {
        cep: formatCep(cep)
      }
    })
  }

  const handleCepFetch = async (cep: string) => {
    try {
      const cepData = await fetchCepData(cep);
      // setFormData(prevData => ({
      //   ...prevData,
      //   district: cepData.bairro,
      //   state: cepData.uf,
      //   city: cepData.localidade,
      //   address: cepData.logradouro,
      // }));
      handleFormChange({
        place: {
          cep: formatCep(cep),
          city: cepData.localidade,
          state: cepData.uf,
          district: cepData.bairro,
          street: cepData.logradouro,
        }
      })
    } catch (e) {
      // setCepValid(false);
      console.log("Invalid cep");
    }
  }

  return (
    <div className="placeInputGroup">
      <div className="min-w-96">
        <h2 className="text-2xl font-bold">Endereço do Local</h2>
        <div className="flex flex-col mt-4 gap-4">
          <div className="flex gap-8">
            <div>
              <h3 className="text-lg font-bold">Cidade</h3>
              <input 
                type="text" 
                value={placeData.city}
                onChange={(e) => handleFormChange({
                  place: {
                    city: e.target.value
                  }
                })}
                placeholder="DIGITE A CIDADE"
                className="w-full text-sm shadow-lg rounded-full mt-2 px-4 font-semibold y-1 border"
              />
            </div>
            <div>
              <h3 className="text-lg font-bold">Estado</h3>
              <input 
                type="text" 
                value={placeData.state}
                onChange={(e) => handleFormChange({
                  place: {
                    state: e.target.value
                  }
                })}
                placeholder="DIGITE O ESTADO"
                className="w-full text-sm shadow-lg rounded-full mt-2 px-4 font-semibold py-1 border"
              />
            </div>
          </div>
          <div className="flex gap-8">
            <div>
              <h3 className="text-lg font-bold">CEP</h3>
              <input 
                type="text" 
                value={placeData.cep}
                onChange={(e) => handleCepChange(e.target.value)}
                onBlur={(e) => handleCepFetch(e.target.value)}
                placeholder="00000-000"
                className="w-full text-sm shadow-lg rounded-full mt-2 px-4 font-semibold py-1 border"
              />
            </div>
            <div>
              <h3 className="text-lg font-bold">Número</h3>
              <input 
                type="text" 
                value={placeData.number}
                onChange={(e) => handleFormChange({
                  place: {
                    number: e.target.value
                  }
                })}
                placeholder="Ex: 220"
                className="w-full text-sm shadow-lg rounded-full mt-2 px-4 font-semibold py-1 border"
              />
            </div>
          </div>
          <div className="flex gap-8">
            <div>
              <div className="flex">
                <h3 className="text-lg font-bold">Bairro</h3>
              </div>
              <input 
                type="text" 
                value={placeData.district}
                onChange={(e) => handleFormChange({
                  place: {
                    district: e.target.value
                  }
                })}
                placeholder="DIGITE O NOME DO BAIRRO"
                className="w-full text-sm shadow-lg rounded-full mt-2 px-4 font-semibold py-1 border"
              />
            </div>
            <div>
              <div className="flex">
                <h3 className="text-lg font-bold">Endereço</h3>
              </div>
              <input 
                type="text" 
                value={placeData.street}
                onChange={(e) => handleFormChange({
                  place: {
                    street: e.target.value
                  }
                })}
                placeholder="Ex: Rua 46"
                className="w-full text-sm shadow-lg rounded-full mt-2 px-4 font-semibold py-1 border"
              />
            </div>
          </div>
          <div className="flex gap-8">
            <div>
              <div className="flex">
                <h3 className="text-lg font-bold">Complemento</h3>
                <p className="text-xs italic">Opcional</p>
              </div>
              <input 
                type="text" 
                value={placeData.complement}
                onChange={(e) => handleFormChange({
                  place: {
                    complement: e.target.value
                  }
                })}
                placeholder="DIGITE O COMPLEMENTO"
                className="w-full text-sm shadow-lg rounded-full mt-2 px-4 font-semibold py-1 border"
              />
            </div>
            <div>
              <div className="flex">
                <h3 className="text-lg font-bold">Referência</h3>
                <p className="text-xs italic">Opcional</p>
              </div>
              <input 
                type="text" 
                value={placeData.reference}
                onChange={(e) => handleFormChange({
                  place: {
                    reference: e.target.value
                  }
                })}
                placeholder="DIGITE A REFERÊNCIA"
                className="w-full text-sm shadow-lg rounded-full mt-2 px-4 font-semibold py-1 border"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlaceInputGroup;