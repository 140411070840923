import React from "react";

function Error() {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-2">
      <h2 className="font-black text-9xl text-black/20 tracking-tight">404</h2>
      <p className="font-semibold text-xl text-black/60">Evento não encontrado.. verifique o nome digitado e tente novamente!</p>
      <img 
        className='absolute bottom-5 w-32'
        src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/credentechlogo.png" 
        alt="Credentech" 
      />
    </div>
  );
}

export default Error;