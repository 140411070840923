import React from "react";
import StepCircle from "./StepCircle";

interface StepProgressProps {
  step: number;
  color2: number[];
}

function StepProgress({step, color2}: StepProgressProps) {
  const stepCirclesTitles = [
    "INFORMAÇÕES",
    "CONFIRMAR INFORMAÇÕES",
    "PAGAMENTO",
    "CONFIRMAÇÃO"
  ]

  return (
    <div className="stepProgress">
      <div className="flex justify-between flex-col gap-8 md:flex-row md:gap-0">
        {stepCirclesTitles.map((stepCircleTitle, index) => (
          <StepCircle 
            index={index + 1} 
            title={stepCircleTitle} 
            fill={(index + 1) > step} 
            color2={color2}
          />
        ))}
      </div>
    </div>
  );
}

export default StepProgress;