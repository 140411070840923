import React from "react";

import Success from "../../../utils/ui/Success";
import Error from "../../../utils/ui/Error";
import Loading from "../../../pages/Loading";
import { ClipLoader } from "react-spinners";

export enum EventCreationStatus {
  none = -1,
  loading = 0,
  success = 1,
  error = 2
}

export interface FeedbackModalProps {
  eventCreationStatus: EventCreationStatus;
  message?: string;
}

export default function FeedbackModal({eventCreationStatus, message}: FeedbackModalProps) {
  // encontrar os links no formato <a href="/path">/path</a>
  const processMessage = (message: string | undefined) => {
    if (!message) return message;

    const linkRegex = /<a href="([^"]+)">([^<]+)<\/a>/g;
    const baseUrl = window.location.origin;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = linkRegex.exec(message)) !== null) {
      parts.push(message.slice(lastIndex, match.index));
      parts.push(
        <a
          key={match[1]}
          href={`${baseUrl}${match[1]}`}
          className="text-blue-500 underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {match[2]}
        </a>
      );

      lastIndex = linkRegex.lastIndex;
    }
    parts.push(message.slice(lastIndex));

    return parts;
  };

  return (
    <div>
      {(() => {
        switch(eventCreationStatus) {
          case EventCreationStatus.error:
            return (
              <div>
                <Error />
                <p className="text-center font-bold text-red-500">{processMessage(message)}</p>
              </div>
            );
          case EventCreationStatus.success:
            return (
              <div>
                <Success />
                <p className="text-center font-bold text-green-500">{processMessage(message)}</p>
              </div>
            );
          default:
            // return <Loading customHeight="50%" customWidth="50%" />
            return (
              <div className="mt-12">
                <ClipLoader size={50} color={"#3b82f6"} loading={true} />
              </div>
            );
        }
      })()}
    </div>
  );
}