import React from "react";
import formatTelephone from "../../../../utils/helpers/formatTelephone";

type PlaceNameInputProps = {
  tel: string;
  handleFormChange: (tel: string) => void;
}

function PlaceTelephoneInput({tel, handleFormChange}: PlaceNameInputProps) {
  const handleTelephoneChange = (telephone: string) => {
    handleFormChange(formatTelephone(telephone))
  }

  return (
    <div className="placeTelephoneInput">
      <h3 className="text-xl font-bold">Telefone do Local</h3>
      <input 
        type="text"
        value={tel}
        onChange={(e) => handleTelephoneChange(e.target.value)}
        placeholder="+55 (DDD) 9XXXX-YYYY"
        className="w-full text-sm shadow-lg rounded-full mt-2 px-4 font-semibold py-1 border"
      />
    </div>
  );
}

export default PlaceTelephoneInput;