import React, { useEffect, useState } from "react";
import {produce} from "immer";

import { EventData, Participant, ScheduleDay, ScheduleModule, SchedulePlace, ScheduleProgramming } from "../../../types/EventData";
import StartDateInfo from "../FormInputs/Schedule/StartDateInfo";
import DaysCarousel from "../FormInputs/Schedule/DaysCarousel";
import Divider from "../../../utils/ui/Divider";
import getMaxIndexByIdSuffix from "../../../utils/helpers/getMaxIndexByIdSufix";
import PlaceList from "../FormInputs/Schedule/PlaceList";
import ModuleList from "../FormInputs/Schedule/ModuleList";
import { FaExternalLinkAlt } from "react-icons/fa";
import ConfirmActionModal from "../../../utils/ui/ConfirmActionModal";
import Modal from "../../../utils/ui/Modal";
import TextInput from "../FormInputs/TextInput";
import ModeratorsForm from "../FormInputs/Schedule/ModeratorsForm";
import ProgrammingList from "../FormInputs/Schedule/ProgrammingList";
import ProgrammingForm from "../FormInputs/Schedule/ProgrammingForm";
import ScheduleController from "../../ScheduleController";
import ModuleAssignmentForm from "../FormInputs/Schedule/ModuleAssignmentForm";
import CurriculumModal from "../../CurriculumModal";

interface ScheduleProps {
  eventData: EventData;
  handleFormChange: (data: {[key: string]: any}) => void;
  submitForm: () => void;
}

export default function Schedule({eventData, handleFormChange}: ScheduleProps) {
  const [selectedDay, setSelectedDay] = useState<ScheduleDay>();
  const [selectedPlace, setSelectedPlace] = useState<SchedulePlace>();
  const [selectedModule, setSelectedModule] = useState<ScheduleModule>();
  const [selectedProgramming, setSelectedProgramming] = useState<ScheduleProgramming>();
  const [selectedParticipant, setSelectedParticipant] = useState<Participant>();

  const [selectedDayId, setSelectedDayId] = useState<string | undefined>();
  const [selectedPlaceId, setSelectedPlaceId] = useState<string | undefined>();
  const [selectedModuleId, setSelectedModuleId] = useState<string | undefined>();
  const [selectedProgrammingId, setSelectedProgrammingId] = useState<string | undefined>();  

  const [isDeleteDayModalOpen, setDeleteDayModalOpen] = useState<boolean>(false);
  const [requestDayDeleteId, setRequestDayDeleteId] = useState<string>();

  const [isModeratorsModalOpen, setModeratorsModalOpen] = useState<boolean>(false);
  const [isProgrammingModalOpen, setProgrammingModalOpen] = useState<boolean>(false);

  const [isPreviewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const [selectedIndexPreviewDay, setSelectedIndexPreviewDay] = useState<number>();

  const [isCurriculumModalOpen, setIsCurriculumModalOpen] = useState<boolean>(false);
  const [curriculumParticipant, setCurriculumParticipant] = useState<Participant>();

  useEffect(() => {
    setSelectedDay(eventData.schedule.days.find(day => day.id === selectedDayId));
  }, [selectedDayId, eventData.schedule.days]);
  
  useEffect(() => {
    setSelectedPlace(selectedDay?.dayplaces.find(place => place.id === selectedPlaceId));
  }, [selectedPlaceId, selectedDay]);
  
  useEffect(() => {
    setSelectedModule(selectedPlace?.placemodules.find(module => module.id === selectedModuleId));
    setSelectedProgramming(undefined);
    setSelectedParticipant(undefined);
  }, [selectedModuleId, selectedPlace]);
  
  useEffect(() => {
    setSelectedProgramming(selectedModule?.moduleprogrammings.find(programming => programming.id === selectedProgrammingId));
    setSelectedParticipant(undefined);
  }, [selectedProgrammingId, selectedModule]);

  const updateDaysForm = (days: ScheduleDay[]) => {
    handleFormChange({ schedule: { ...eventData.schedule, days } });
  };

  const handleDayCreate = () => {
    const newDayIndex = getMaxIndexByIdSuffix(eventData.schedule.days, 'newday') + 1;
    const newDay: ScheduleDay = {
      id: `newday-${newDayIndex}`,
      date: new Date(new Date(eventData.startdate).setHours(7, 30, 0, 0)),
      dayplaces: [],
    };

    updateDaysForm([...eventData.schedule.days, newDay]);
    setSelectedDay(newDay);
  }

  const handleDayDateChange = (day: ScheduleDay | undefined, date: Date) => {
    if (!day) return;

    day.date = date;
    updateDaysForm([...eventData.schedule.days])
  }

  const handlePlaceCreate = (day: ScheduleDay) => {
    const placePrefix = `newplace-${day.id.replace('newday-', '')}`;
    const newPlaceIndex = getMaxIndexByIdSuffix(day.dayplaces, placePrefix) + 1;
    const newPlace: SchedulePlace = {
      id: `${placePrefix}-${newPlaceIndex}`,
      title: "<Nova sala> " + (day.dayplaces.length + 1),
      placemodules: []
    }

    day.dayplaces.push(newPlace);
    updateDaysForm([...eventData.schedule.days]);
  }

  const handleModuleCreate = (place: SchedulePlace) => {
    const modulePrefix = `newmodule-${place.id.replace('newplace-', '')}`;
    const newModuleIndex = getMaxIndexByIdSuffix(place.placemodules, modulePrefix) + 1;
    const newModule: ScheduleModule = {
      id: `${modulePrefix}-${newModuleIndex}`,
      title: '<Novo modulo> ' + (place.placemodules.length + 1),
      moduleprogrammings: [],
      assignments: []
    }
    place.placemodules.push(newModule);
    updateDaysForm([...eventData.schedule.days]);
  }

  const handleProgrammingCreate = (module: ScheduleModule) => {
    const programmingPrefix = `newprogramming-${module.id.replace('newmodule-', '')}`;;
    const newProgrammingIndex = getMaxIndexByIdSuffix(module.moduleprogrammings, programmingPrefix) + 1;
    const newProgramming: ScheduleProgramming = {
      id: `${programmingPrefix}-${newProgrammingIndex}`,
      minutes: 0,
      isinterval: false,
      theme: '<Nova programação>',
      // participants: [],
      assignments: []
    }
    module.moduleprogrammings.push(newProgramming);
    updateDaysForm([...eventData.schedule.days]);
  }

  const handleProgrammingUpdate = (module: ScheduleModule, newProgramming: ScheduleProgramming[]) => {
    module.moduleprogrammings = newProgramming;
    updateDaysForm([...eventData.schedule.days]);
  }

  const handleModuleUpdate = (place: SchedulePlace, newModules: ScheduleModule[]) => {
    place.placemodules = newModules;
    updateDaysForm([...eventData.schedule.days]);
  }

  const handlePlaceUpdate = (day: ScheduleDay, newPlaces: SchedulePlace[]) => {
    day.dayplaces = newPlaces;
    updateDaysForm([...eventData.schedule.days]);
  }

  const handleDayDelete = (id: string) => {
    const updatedDays = eventData.schedule.days.filter((day) => id !== day.id);
    updateDaysForm(updatedDays);
    setSelectedDay(undefined);
  }

  const handlePlaceTitleChange = (newTitle: string) => {
    if (!selectedDay || !selectedPlace) return;
  
    const updatedDays = eventData.schedule.days.map((day) => {
      if (day.id !== selectedDay.id) return day;
  
      return {
        ...day,
        dayplaces: day.dayplaces.map((place) => {
          if (place.id !== selectedPlace.id) return place;
  
          return {
            ...place,
            title: newTitle,
          };
        }),
      };
    });
  
    updateDaysForm(updatedDays);
    setSelectedPlace((prev) => prev ? { ...prev, title: newTitle } : prev);
  };

  const updateModuleProperty = (
    property: keyof ScheduleModule,
    value: any,
  ): void => {
    if (!selectedDay || !selectedPlace || !selectedModule) return;
  
    const updatedDays = eventData.schedule.days.map(day => {
      if (day.id !== selectedDay.id) return day;
  
      return {
        ...day,
        dayplaces: day.dayplaces.map(place => {
          if (place.id !== selectedPlace.id) return place;
  
          return {
            ...place,
            placemodules: place.placemodules.map(module => {
              if (module.id !== selectedModule.id) return module;
  
              return {
                ...module,
                [property]: value,
              };
            }),
          };
        }),
      };
    });
  
    updateDaysForm(updatedDays);
  
    setSelectedModule(prev => prev ? { ...prev, [property]: value } : prev);
    setSelectedPlace(prevPlace => {
      if (!prevPlace) return;

      return (
        {
          ...prevPlace,
          placemodules: prevPlace?.placemodules.map(module =>
            module.id === selectedModule.id ? { ...module, [property]: value } : module
          ),
        }
      );
    });
  };

  const handleModuleTitleChange = (newTitle: string) => {
    updateModuleProperty(
      'title',
      newTitle
    );
  };

  const updateProgrammingProperty = (
    property: keyof ScheduleProgramming,
    value: any
  ): void => {
    if (!selectedDay || !selectedPlace || !selectedModule || !selectedProgramming) return;
  
    const updatedDays = eventData.schedule.days.map((day) => {
      if (day.id !== selectedDay.id) return day;
      return {
        ...day,
        dayplaces: day.dayplaces.map((place) => {
          if (place.id !== selectedPlace.id) return place;
          return {
            ...place,
            placemodules: place.placemodules.map((module) => {
              if (module.id !== selectedModule.id) return module;
              return {
                ...module,
                moduleprogrammings: module.moduleprogrammings.map((programming) => {
                  if (programming.id !== selectedProgramming.id) return programming;
                  return {
                    ...programming,
                    [property]: value,
                  };
                }),
              };
            }),
          };
        }),
      };
    });
  
    updateDaysForm(updatedDays);
  
    const updatedSelectedDay = updatedDays.find((day) => day.id === selectedDay.id);
    const updatedSelectedPlace = updatedSelectedDay?.dayplaces.find((place) => place.id === selectedPlace.id);
    const updatedSelectedModule = updatedSelectedPlace?.placemodules.find((module) => module.id === selectedModule.id);
    const updatedSelectedProgramming = updatedSelectedModule?.moduleprogrammings.find((prog) => prog.id === selectedProgramming.id);
  
    setSelectedDay(updatedSelectedDay);
    setSelectedPlace(updatedSelectedPlace);
    setSelectedModule(updatedSelectedModule);
    setSelectedProgramming(updatedSelectedProgramming);
  };

  const handleParticipantAssignmentSelect = (participant: Participant) => {
    if (participant.id === selectedParticipant?.id) {
      setSelectedParticipant(undefined);
      return;
    }
    setSelectedParticipant(participant);
  }

  const handleModuleAssignmentChange = (participantId: string, functionId: string | undefined) => {
    console.log(eventData)
    if (!selectedModule) return;
    const participant = eventData.participants.find(p => p.id === participantId);
    
    if (functionId === undefined) {
      updateModuleProperty('assignments', selectedModule.assignments.filter(
        assignment => assignment.participant.id !== participantId
      ));
    } else {
      const functionData = eventData.functions.find(f => f.id === functionId);
      console.log(functionData)
      if (participant && functionData) {
        const existingAssignmentIndex = selectedModule.assignments.findIndex(
          assignment => assignment.participant.id === participantId
        );
        
        if (existingAssignmentIndex !== -1) {
          const updatedAssignments = [...selectedModule.assignments];
          updatedAssignments[existingAssignmentIndex] = {
            ...updatedAssignments[existingAssignmentIndex],
            function: functionData
          };
          updateModuleProperty('assignments', updatedAssignments);
        } else {
          updateModuleProperty('assignments', [
            ...selectedModule.assignments,
            {
              id: `assignment-${Date.now()}`,
              function: functionData,
              participant: participant
            }
          ]);
        }
      }
    }
  }

  const handleProgrammingAssignmentChange = (participantId: string, functionId: string | undefined) => {
    if (!selectedProgramming) return;
    const participant = eventData.participants.find(p => p.id === participantId);
    
    if (functionId === undefined) {
      updateProgrammingProperty('assignments', selectedProgramming.assignments.filter(
        assignment => assignment.participant.id !== participantId
      ));
    } else {
      const functionData = eventData.functions.find(f => f.id === functionId);
      if (participant && functionData) {
        const existingAssignmentIndex = selectedProgramming.assignments.findIndex(
          assignment => assignment.participant.id === participantId
        );
        
        if (existingAssignmentIndex !== -1) {
          const updatedAssignments = [...selectedProgramming.assignments];
          updatedAssignments[existingAssignmentIndex] = {
            ...updatedAssignments[existingAssignmentIndex],
            function: functionData
          };
          updateProgrammingProperty('assignments', updatedAssignments);
        } else {
          updateProgrammingProperty('assignments', [
            ...selectedProgramming.assignments,
            {
              id: `assignment-${Date.now()}`,
              function: functionData,
              participant: participant
            }
          ]);
        }
      }
    }
  }

  return (
    <div className="schedule px-32">
      <div className=" py-1 2xl:py-4 flex flex-col gap-1 2xl:gap-4">
        <div className="flex gap-4 justify-center items-center">
          <StartDateInfo date={eventData.startdate} />
          <button 
            className="bg-stone-300 px-4 py-2 shadow-md rounded-full hover:translate-y-[-5px] transition-all duration-300 flex justify-center items-center gap-2"
            onClick={() => setPreviewModalOpen(true)}
          >
            Preview
            <FaExternalLinkAlt />
          </button>
        </div>
        <DaysCarousel 
          days={eventData.schedule.days}
          selectedId={selectedDayId}
          onDaySelect={setSelectedDayId}
          onDayCreate={handleDayCreate}
          onDayDelete={(id: string) => {
            setDeleteDayModalOpen(true);
            setRequestDayDeleteId(id);
          }}
          onDayChange={(day: ScheduleDay, date: Date | null) => {
            if (!date) return;
            handleDayDateChange(day, date)
          }}
        />
        <Divider />
        <div className="flex gap-8">
          {selectedDay && (
            <PlaceList 
              places={selectedDay.dayplaces}
              selectedId={selectedPlaceId}
              handlePlaceSelect={setSelectedPlaceId}
              handlePlaceCreate={() => handlePlaceCreate(selectedDay)}
              handlePlaceUpdate={(places: SchedulePlace[]) => handlePlaceUpdate(selectedDay, places)}
            />
          )}
          {(selectedDay && selectedPlace) && (
            <div className="flex flex-col gap-8">
              <TextInput 
                title="Nome"
                value={selectedPlace.title}
                placeholder="DIGITE O NOME DO LOCAL"
                onChange={handlePlaceTitleChange}
              />
              <ModuleList
                modules={selectedPlace.placemodules}
                scheduleDay={selectedDay!}
                schedulePlace={selectedPlace}
                selectedId={selectedModuleId}
                handleModuleSelect={setSelectedModuleId}
                handleModuleCreate={() => handleModuleCreate(selectedPlace)}
                handleModuleUpdate={(modules: ScheduleModule[]) => handleModuleUpdate(selectedPlace, modules)}
              />
            </div>
          )}
          {selectedModule && (
            <div className="flex flex-col gap-8">
              <TextInput 
                title="Nome do Módulo"
                value={selectedModule.title}
                placeholder="DIGITE O NOME DO MÓUDLO"
                onChange={handleModuleTitleChange}
              />
              <div>
                <h2 className="text-xl 2xl:text-3xl font-bold mb-2">Funções associadas</h2>
                <button
                  className="flex gap-4 bg-stone-200 font-semibold text-stone-600 shadow-md rounded-full justify-center items-center px-6 py-1 hover:scale-105 transition-all duration-300"
                  onClick={() => setModeratorsModalOpen(true)}
                >
                  GERENCIAR
                  <FaExternalLinkAlt />
                </button>
              </div>
              <div>
                <h2 className="text-xl 2xl:text-3xl font-bold mb-2">Programação</h2>
                <button
                  className="flex gap-4 bg-stone-200 font-semibold text-stone-600 shadow-md rounded-full justify-center items-center px-6 py-1 hover:scale-105 transition-all duration-300"
                  onClick={() => setProgrammingModalOpen(true)}
                >
                  GERENCIAR
                  <FaExternalLinkAlt />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ConfirmActionModal 
        visible={isDeleteDayModalOpen}
        question="Você tem certeza que deseja deletar um dia de evento ? (Você perderá as informações deste evento)"
        onConfirm={() => {
          setDeleteDayModalOpen(false);
          requestDayDeleteId != undefined && handleDayDelete(requestDayDeleteId);
        }}
        onCancel={() => {
          setDeleteDayModalOpen(false);
          setRequestDayDeleteId(undefined);
        }}
      />
      <Modal
        visible={isModeratorsModalOpen}
        onClose={() => {
          setModeratorsModalOpen(false);
          setSelectedParticipant(undefined);
        }}
        className="relative py-6 px-8"
        width={400}
        height={500}
      >
        {selectedModule && ( 
          <ModuleAssignmentForm 
            scheduleModule={selectedModule}
            functions={eventData.functions}
            eventParticipants={eventData.participants}
            selectedParticipant={selectedParticipant}
            onSelectParticipant={handleParticipantAssignmentSelect}
            onAssignmentChange={handleModuleAssignmentChange}
          />
        )}
      </Modal>
      
      <Modal
        visible={isProgrammingModalOpen}
        onClose={() => {
          setProgrammingModalOpen(false);
          setSelectedParticipant(undefined);
        }}
        className="relative py-6 px-8 flex flex-col gap-12"
        width={850}
        height={600}
      >
        {(selectedDay && selectedModule && selectedPlace) && (
          <div className="flex w-full justify-start gap-4">
            <ProgrammingList 
              programming={selectedModule.moduleprogrammings}
              modules={selectedPlace!.placemodules}
              scheduleDay={selectedDay!}
              schedulePlace={selectedPlace!}
              moduleIndex={selectedPlace!.placemodules.indexOf(selectedModule)}
              selectedId={selectedProgrammingId}
              handleProgrammingSelect={setSelectedProgrammingId}
              handleProgrammingCreate={() => handleProgrammingCreate(selectedModule)}
              handleProgrammingUpdate={(programming: ScheduleProgramming[]) => handleProgrammingUpdate(selectedModule, programming)}
            />
            {selectedProgramming && (
              <ProgrammingForm
                programming={selectedProgramming}
                eventParticipants={eventData.participants}
                functions={eventData.functions}
                selectedParticipant={selectedParticipant}
                onProgrammingChange={updateProgrammingProperty}
                onParticipantSelect={handleParticipantAssignmentSelect}
                onAssignmentChange={handleProgrammingAssignmentChange}
              />
            )}
          </div>
        )}
      </Modal>
      <Modal
        visible={isPreviewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        width={1000}
        height={700}
      >
        <div className="py-2 overflow-y-scroll h-[700px]">
          <ScheduleController
            days={eventData.schedule.days}
            selectedIndex={selectedIndexPreviewDay}
            color1={[67, 155, 249]}
            onDaySelect={setSelectedIndexPreviewDay}
            onCurriculumClick={(participant: Participant) => {
              setCurriculumParticipant(participant);
              setIsCurriculumModalOpen(true);
            }}
          />
        </div>
      </Modal>
      {curriculumParticipant && (
        <CurriculumModal
          participant={curriculumParticipant}
          isModalOpen={isCurriculumModalOpen}
          setIsModalOpen={setIsCurriculumModalOpen}
        />
      )}
    </div>
  );
}
