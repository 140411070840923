import React from "react";
import Modal from "../Modal";

interface ConfirmActionModalProps {
  visible: boolean;
  question: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function ConfirmActionModal({ visible, question, onConfirm, onCancel }: ConfirmActionModalProps) {
  return (
    <Modal 
      visible={visible}
      width={400}
      height={200}
      onClose={onCancel}
      className="p-6"
      hideClose
    >
      <div className="flex flex-col items-center justify-center h-full space-y-6">
        <h2 className="text-lg font-bold text-gray-700 text-center">{question}</h2>
        <div className="flex justify-around w-full">
          <button
            onClick={onCancel}
            className="px-6 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg text-gray-700 font-medium transition duration-300"
          >
            Não
          </button>
          <button
            onClick={onConfirm}
            className="px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg font-medium transition duration-300"
          >
            Sim
          </button>
        </div>
      </div>
    </Modal>
  );
}
