import { Draggable } from "@hello-pangea/dnd";
import React from "react";
import ListItem from "../../../../utils/ui/ListItem";
import { FaTrash } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";

interface DraggableListItemProps {
  id: string;
  index: number;
  selected?: boolean;
  children: React.ReactNode;
  onDelete: () => void;
  onClick: () => void;
}

export default function DraggableListItem({
  id, 
  index,
  selected,
  children,
  onClick,
  onDelete
}: DraggableListItemProps) {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <li
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <ListItem
            selected={selected}
            className="flex justify-between px-8 py-2 cursor-pointer"
            onClick={onClick}
          >
            {children}
            <button
              data-tooltip-id={`delete-${id}`}
              data-tooltip-content="Deletar"
              data-tooltip-place="bottom"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDelete();
              }}
              className="ml-4"
            >
              <FaTrash />
            </button>
            <Tooltip id={`delete-${id}`} />
          </ListItem>
        </li>
      )}
    </Draggable>
  );
}