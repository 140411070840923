import { motion } from "framer-motion";
import React, { useState } from "react";
import EventsDashboardTable from "../components/EventsDashboardTable";
import { Tooltip } from "react-tooltip";
import Loading from "./Loading";

function EventsDashboard() {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="eventsDashboard flex flex-col items-center"
    >
      <h1 className="font-bold text-3xl tracking-wide text-center py-6">Lista de Eventos</h1>
      <EventsDashboardTable onLoad={() => setLoading(false)} />
      {loading && <Loading />}
      <div className="fixed w-full h-full flex justify-end items-end pointer-events-none">
        <a 
          className="flex justify-center items-center bg-blue-500 w-12 h-12 mx-12 my-4 text-4xl font-bold rounded-full text-white transition-transform duration-300 hover:scale-110 pointer-events-auto"
          href="/events/create"
          data-tooltip-id="new-event-button"
          data-tooltip-content="Criar Evento"
          data-tooltip-place="top-start"
        >
          +
        </a>
        <Tooltip id="new-event-button" />
      </div>
    </motion.div>
  );
}

export default EventsDashboard;