import React from 'react';
import Wave from 'react-wavify';

import RedirectButton from '../utils/ui/RedirectButton';
import { motion } from 'framer-motion';
import PageWaves from '../components/PageWaves';

function Home() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="Home"
    >
      <div className='flex flex-col md:flex-row min-h-screen'>
        <div className="waves w-full md:w-1/2 my-16 md:my-0 relative flex flex-col justify-center items-center">
          <div className="content text-center">
            <p className='text-xl mb-4'>BEM VINDO A</p>
            <img 
              className='m-auto'
              src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/credentechlogo.png" 
              alt="Credentech" 
            />
            <p className='max-w-96 my-12 text-stone-500'>Clique para saber mais sobre nossas soluções inovadoras de credenciamento para eventos. Oferecemos tecnologia de ponta para simplificar e otimizar o credenciamento de participantes.
            </p>
            <a
              className="uppercase text-white bg-gradient-to-r from-orange-600 to-orange-800 px-12 py-3 rounded-full font-semibold transition-all ease-in-out hover:animate-gradient"
              href="/about"
              style={{
                backgroundSize: "200% 200%",
                backgroundPosition: "0% 50%",
                animation: "gradient-slide 3s ease infinite"
              }}
            >
              Leia Mais
            </a>
          </div>
          <div className='hidden md:block'>
            <PageWaves />
          </div>
        </div>
        <div className="image w-full md:w-1/2">
          <div 
            className="relative py-12 md:py-0 md:h-screen w-full border-l-0 border-t-4 border-orange-500 mt-12 md:mt-0 md:border-t-0 md:border-l-4 flex flex-col items-center justify-center"
            style={{
              backgroundImage: "url('https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/homebg.png')",
              backgroundSize: "cover",
              backgroundPosition: "center"
            }}
          >
            <img 
              className='z-10 relative max-w-64 md:max-w-fit'
              src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/credentech-sistemas%2Cpng.png"
              alt="credentech-logo"
            />
            <p className='z-10 px-24 mt-10 text-md md:text-lg text-white text-center'>Na Credentech, proporcionamos sistemas completos de credenciamento com check-in automatizado, controle de acesso e geração de relatórios em tempo real. Acompanhe o sucesso do seu evento de forma simples e eficiente com nossas ferramentas tecnológicas.</p>
            <div className="absolute inset-0 bg-gradient-to-r from-[rgba(255,107,0,0.55)] to-[rgba(0,0,0,0.8)]"></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Home;