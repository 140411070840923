import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import React, { useEffect, useState } from "react";
import CategoryListItem from "../CategoryListItem";
import { Category, EventData, Participant } from "../../../../types/EventData";
import reorderArray from "../../../../utils/helpers/reorderArray";
import CreateListItemButton from "../CreateListItemButton";
import getMaxIndexByIdSuffix from "../../../../utils/helpers/getMaxIndexByIdSufix";
import InsertListButton from "../../../../utils/ui/InsertListButton";

interface CategoryListProps {
  eventData: EventData;
  categories: Category[];
  onSelect: (selectedId: string) => void;
  onDelete: (categoryUpdate: Category[], participantsUpdate: Participant[]) => void;
  onCreate: (categoryUpdate: Category[]) => void;
  onDrag: (categoryUpdate: Category[]) => void;
}

function CategoryList({eventData, categories, onDelete, onCreate, onDrag, onSelect}: CategoryListProps) {
  const [selectedId, setSelectedId] = useState<string>('');

  useEffect(() => {
    onSelect(selectedId);
  }, [selectedId]);

  const onDragEnd = (result: DropResult<string>) => {
    if (!result.destination) {
      return;
    }
    const swipedItemsArray = reorderArray<Category>(categories, result.source.index, result.destination.index);
    
    onDrag(swipedItemsArray);
  }


  const handleCategoryDelete = (categoryId: string) => {
    const categoryUpdate = categories.filter(category => category.id !== categoryId);
    setSelectedId('');

    /*
      Clearing all participants categories that was deleted.
    */
    eventData.participants.forEach(participant => {
      if (participant.category && participant.category.id == categoryId) {
        participant.category = undefined;
      }
    });

    onDelete(categoryUpdate, eventData.participants);
  }

  const handleCategoryClick = (categoryId: string) => {
    if (categoryId == selectedId) {
      return setSelectedId('');
    }
    setSelectedId(categoryId);
  }

  const handleCategoryCreate = () => {
    const categoryUpdate = [...categories];
    categoryUpdate.push({
      name: '<Nova Categoria>',
      id: `newct-${getMaxIndexByIdSuffix(categoryUpdate, 'newct') + 1}`,
      guestcategory: false
    });

    onCreate(categoryUpdate);
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categories" type="list" direction="vertical">
          {(provided) => (
            <ul 
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="border-l-transparent border-t-transparent border-b-transparent pe-8 border-4 overflow-y-scroll w-[400px] h-[300px]"
            >
              {categories.map((category, index) => (
                <CategoryListItem 
                  key={category.id} 
                  category={category}
                  selected={selectedId == category.id}
                  index={index}
                  handleDelete={handleCategoryDelete}
                  onClick={handleCategoryClick}
                />
              ))}
            {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-center items-center mt-6">
        {/* <CreateListItemButton onClick={handleCategoryCreate} /> */}
        <InsertListButton onClick={handleCategoryCreate} />
      </div>
    </div>
  );
}

export default CategoryList;