import React from "react";

import EventBanner from "../../components/Introduction/EventBanner";
import IntroductionSection from "../../components/Introduction/IntroductionSection";
import LayoutSection from "../../components/LayoutSection";
import Header from "../../components/Header";
import SpeakerCarousel from "../../components/Speakers/SpeakerCarousel";
import QuickLinks from "../../components/QuickLinks";
import SectionHeader from "../../components/SectionHeader";
import LocationDetails from "../../components/Location/LocationDetails";
import LocationAddress from "../../components/Location/LocationAddress";
import LocationMap from "../../components/Location/LocationMap";
import SponsorsContainer from "../../components/SponsorsContainer";
import LayoutContainer from "../../components/LayoutContainer";
import Navbar from "../../components/Header/Navbar";
import VideoPlayer from "../../utils/ui/VideoPlayer";
import { useFetchDemoData } from "../../hooks/useFetchDemoData";
import useWindowSize from "../../hooks/useWindowSize";
import Loading from "../Loading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

interface Layout3Props {
  eventSlug: string;
}

function Layout3({eventSlug}: Layout3Props) {
  const {data, loading} = useFetchDemoData();
  const { windowSize } = useWindowSize();

  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="Layout3 layout px-6 sm:px-0">
        <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
          <Header logo={data?.logourl!} />
          <div className="flex flex-col sm:flex-row gap-12 mb-4 sm:mb-0">
            <EventBanner 
              color1={data?.color1!}
              color2={data?.color2!}
              banner={data?.banner!}
              eventDate={data?.startdate!}
              place={data?.place!}
            />
            <Navbar column logo={data?.logourl!} color1={data?.color1!} layout={data?.layout!} />
          </div>
          <LayoutSection>
            <IntroductionSection
              authorCenter
              title="Introdução"
              content={data!.introduction}
              author={data?.participants.find((participant) => participant.introductionauthor)!}
            />
          </LayoutSection>
          <LayoutSection>
            <QuickLinks horizontal={windowSize.width > 600} eventSlug={eventSlug} />
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Palestrantes</SectionHeader>
            <SpeakerCarousel
              color1={data?.color1!}
              authors={data?.participants.filter((participant) => participant.isspeaker)!}
            />
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Localização</SectionHeader>
            <div className="flex flex-col sm:flex-row gap-12 sm:gap-0">
              <div>
                <LocationMap
                  width={windowSize.width < 900 ? 300 : 450 * (windowSize.width * 0.0008)}
                  height={windowSize.width < 900 ? 320 : 400}
                  className="flex justify-center mb-12 mt-4"
                  location={data?.place!} 
                />
                <LocationAddress
                  street={data?.place.street!}
                  number={data?.place.number!}
                  district={data?.place.district!}
                  city={data?.place.city!}
                  state={data?.place.state!}
                  telephone={data?.place.telephone!}
                />
              </div>
              <div className="px-2 sm:px-12 m-auto flex flex-col items-center sm:block">
                <div className="max-w-[300px] sm:max-w-[520px] mx-auto">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    pagination={{clickable: true}}
                    navigation={true}
                  >
                    {data?.place.imgs?.map((placeImgSrc) => {
                      return (
                        <SwiperSlide> 
                          <img 
                              className="w-[200px] sm:w-[300px] lg:w-[400px] h-[280px] sm:h-[320px] lg:h-[400px] pb-4 m-auto"
                              src={placeImgSrc.src} 
                              alt={placeImgSrc.alt} 
                          />
                        </SwiperSlide>
                      )
                    })}
                    {data?.place.youtubeurl && (
                      <SwiperSlide>
                        <VideoPlayer 
                          url={data?.place.youtubeurl!}
                          width={windowSize.width < 600 ? 250 : (windowSize.width < 1200 ? 300 : 450)}
                          height={windowSize.width < 600 ? 280 : (windowSize.width < 900 ? 320 : 400)}
                        />
                      </SwiperSlide>
                    )}
                  </Swiper>
                  <LocationDetails name={data?.place.name!} description={data?.place.description!}  />
                </div>
              </div>
            </div>
          </LayoutSection>
          <LayoutSection>
            <SponsorsContainer 
              sponsorCategories={data?.sponsors!}
            />
          </LayoutSection>
        </LayoutContainer>
    </div>
  );
}

export default Layout3;