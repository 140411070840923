import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import { FunctionData } from "../../../../types/EventData";
import reorderArray from "../../../../utils/helpers/reorderArray";
import { useEffect, useState } from "react";
import FunctionListItem from "../FunctionListItem";
import InsertListButton from "../../../../utils/ui/InsertListButton";

interface FunctionListProps {
  functions: FunctionData[];
  onSelect: (selectedId: string) => void;
  onDelete: (functionsUpdate: FunctionData[]) => void;
  onCreate: (functionsUpdate: FunctionData[]) => void;
  onDrag: (functionsUpdate: FunctionData[]) => void;
}

export default function FunctionList({ 
  functions,
  onSelect,
  onDelete,
  onCreate,
  onDrag
 }: FunctionListProps) {
  const [selectedId, setSelectedId] = useState<string>('');

  useEffect(() => {
    onSelect(selectedId);
  }, [selectedId]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const swipedItemsArray = reorderArray<FunctionData>(functions, result.source.index, result.destination.index);
    onDrag(swipedItemsArray);
  }

  const handleFunctionDelete = (functionId: string) => {
    const functionsUpdate = functions.filter((functionItem: FunctionData) => functionItem.id !== functionId);
    onDelete(functionsUpdate);
  }

  const handleFunctionClick = (functionId: string) => {
    if (functionId == selectedId) {
      return setSelectedId('');
    }
    setSelectedId(functionId);
  }

  const handleFunctionCreate = () => {
    const newFunction: FunctionData = {
      id: `newfunction-${functions.length + 1}`,
      name: '<Nova Funcao>',
    };
    onCreate([...functions, newFunction]);
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="functions" type="list" direction="vertical">
          {(provided) => (
            <ul 
              {...provided.droppableProps}
              ref={provided.innerRef} 
              className="border-l-transparent border-t-transparent border-b-transparent pe-8 border-4 overflow-y-scroll w-[400px] h-[300px]"
            >
              {functions.map((functionItem, index) => (
                <FunctionListItem 
                  key={functionItem.id} 
                  functionData={functionItem} 
                  index={index} 
                  selected={selectedId == functionItem.id}
                  handleDelete={handleFunctionDelete}
                  onClick={handleFunctionClick}
                />
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-center items-center mt-6">
        <InsertListButton onClick={handleFunctionCreate} />
      </div>
    </div>
  );
}