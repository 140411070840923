import React from "react";

interface RowListProps extends React.HTMLAttributes<HTMLLIElement> {
  children?: React.ReactNode;
}

function RowList({children}: RowListProps) {
  return (
    <div>
      <ul className="flex text-center gap-5">
        {children}
      </ul>
    </div>
  );
}

export default RowList;