type Dictionary = {[key: string]: any};

export function recursiveKeyUpdate(target: Dictionary, updates: Dictionary): void {
  Object.keys(updates).forEach(key => {
    if (Array.isArray(updates[key])) {
      target[key] = updates[key];
    } else if (typeof updates[key] === 'object' && updates[key] !== null && !(updates[key] instanceof Date)) {
      if (!target[key]) target[key] = {};
      recursiveKeyUpdate(target[key] as Dictionary, updates[key] as Dictionary);
    } else {
      target[key] = updates[key];
    }
  });
}