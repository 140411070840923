import React from "react";
import Navbar from "../Navbar";
import { useFetchDemoData } from "../../../hooks/useFetchDemoData";
import Layout3Header from "../LayoutsHeader/Layout3";
import Loading from "../../../pages/Loading";

function LayoutsHeaderGate() {
  const {data, loading} = useFetchDemoData();
  
  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="layoutsHeaderGate">
      {(data?.layout != "layout3") ? (
        <Navbar color1={data?.color1!} className="pb-2 pt-6" logo={data?.logourl!} layout={data?.layout!} />
      ) : <></>}
      {(data?.layout == "layout3") ? (
        <Layout3Header logo={data?.logourl!} layout={data?.layout!} />
      ) : <></>}
    </div>
  );
}

export default LayoutsHeaderGate;