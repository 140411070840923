import React from "react";
import { Draggable } from '@hello-pangea/dnd';
import { FaTrash } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import { Category } from "../../../../types/EventData";
import truncateString from "../../../../utils/helpers/truncateString";

interface CategoryListItemProps {
  category: Category;
  index: number;
  selected?: boolean;
  handleDelete: (categoryId: string) => void;
  onClick: (categoryId: string) => void;
}
function CategoryListItem({category, selected, index, handleDelete, onClick}: CategoryListItemProps) {
  return (
    <Draggable draggableId={category.id} index={index}>
      {(provided) => (
        <li 
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={`${selected ? 'bg-neutral-900 text-white' : 'bg-stone-200 text-stone-900'} w-full mt-2 rounded-full shadow-md transition-colors duration-300 ease-in-out flex gap-4 items-center justify-between px-8 py-1`}
          onClick={() => onClick(category.id)}
        >
          <p className="uppercase text-sm font-semibold tracking-wider max-w-48 overflow-hidden min-h-8 flex items-center">{truncateString(category.name, 15)}</p>
          <button
            data-tooltip-id={`category-${index + 1}-tooltop`}
            data-tooltip-content="Deletar"
            data-tooltip-place="bottom"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(category.id);
            }}
          >
            <FaTrash />
          </button>
          <Tooltip id={`category-${index + 1}-tooltop`} />
        </li>
      )}
    </Draggable>
  );
}

export default CategoryListItem;