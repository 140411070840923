import axios from "axios";

const api = 'https://viacep.com.br/ws';

async function fetchCepData(cep: string) {
  if (cep.length < 8) {
    throw 'CEP does not have 8 caracters';
  }

  const { data } = await axios.get(`${api}/${cep}/json/`);

  if (data.erro) {
    throw 'CEP is invalid';
  }

  return data;
}

export default fetchCepData;