import React from "react";
import DayCard from "./DayCard";
import InsertListButton from "../../../../../utils/ui/InsertListButton";
import { ScheduleDay } from "../../../../../types/EventData";
import { motion } from "framer-motion";
import DatePicker from "react-datepicker";

import 'react-datepicker/dist/react-datepicker.css';

interface DaysCarouselProps {
  days: ScheduleDay[];
  selectedId?: string;
  onDaySelect?: (id?: string) => void;
  onDayChange?: (day: ScheduleDay, date: Date | null) => void;
  onDayCreate?: () => void;
  onDayDelete?: (id: string) => void;
}

export default function DaysCarousel({
  days,
  selectedId,
  onDaySelect,
  onDayChange,
  onDayCreate,
  onDayDelete
}: DaysCarouselProps) {

  const handleDayClick = (dayId: string) => {
    onDaySelect && onDaySelect(selectedId === dayId ? undefined : dayId);
  };

  const handleDayCreate = () => {
    onDayCreate && onDayCreate();
    // Note que agora não atualizamos automaticamente o `selectedId` após a criação
  };

  const handleDayDelete = (dayId: string) => {
    const dayIndex = days.findIndex(day => day.id === dayId);
    onDayDelete && onDayDelete(dayId);
    if (dayId === selectedId) {
      onDaySelect && onDaySelect(undefined);
    } else if (selectedId !== undefined) {
      const selectedIndex = days.findIndex(day => day.id === selectedId);
      if (selectedIndex > dayIndex) {
        onDaySelect && onDaySelect(days[selectedIndex - 1]?.id);
      }
    }
  };

  return (
    <div className="flex justify-center items-center gap-4 select-none">
      <div className="flex gap-4">
        {days.length === 0 && (
          <p className="text-gray-300 font-bold py-10 flex items-center justify-center h-[30px] 2xl:h-[60px]">
            Crie um dia de evento para começar.
          </p>
        )}
        {days.map((day, index) => (
          <motion.div
            key={day.id}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            layout
          >
            <div className="flex flex-col items-center gap-2">
              <DayCard
                index={index + 1}
                selected={selectedId === day.id}
                onClick={() => handleDayClick(day.id)}
                onDelete={() => handleDayDelete(day.id)}
              />
              <DatePicker 
                selected={day.date}
                onChange={(date: Date | null) => { onDayChange && onDayChange(day, date) }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Hora"
                dateFormat="dd/MM/yyyy HH:mm"
                locale="pt-BR"
                className="custom-datepicker border border-2 rounded-full border-gray-300 text-center py-1 text-sm"
              />
            </div>
          </motion.div>
        ))}
      </div>
      <InsertListButton onClick={handleDayCreate} />
    </div>
  );
}
