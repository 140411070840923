import React, { useState } from "react";
import TrashButton from "./TrashButton";
import { motion } from "framer-motion";

interface DayCardProps {
  index: number;
  selected?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
};

export default function DayCard({ index, selected, onClick, onDelete }: DayCardProps) {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <motion.div
      className="w-[60px] h-[60px] 2xl:w-[80px] 2xl:h-[60px] relative flex flex-col justify-center items-center py-6 2xl:py-10 rounded-lg transition-transform duration-300 ease-in-out hover:scale-105 shadow-lg cursor-pointer"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={onClick}
      initial={{ backgroundColor: selected ? '#E5E7EB' : '#171717', color: selected ? '#111827' : '#FFFFFF' }}
      animate={{ backgroundColor: selected ? '#171717' : '#E5E7EB', color: selected ? '#FFFFFF' : '#111827' }}
      transition={{ duration: 0.25, ease: "easeInOut" }}
    >
      {isHovering && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <TrashButton onClick={onDelete} />
        </motion.div>
      )}
      <p className="text-md 2xl:text-lg font-semibold">DIA</p>
      <p className="text-xl 2xl:text-3xl font-bold">{index.toString().padStart(2, '0')}</p>
    </motion.div>
  );
}