function convertDateToString(date: Date) {
  if (!date) return "<undefined>";

  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  let year = date.getFullYear();

  day = parseInt(day) < 10 ? '0' + day : day;
  month = parseInt(month) < 10 ? '0' + month : month;

  return `${day}/${month}/${year}`;
}

export default convertDateToString;