import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import React from "react";
import reorderArray from "../../../../utils/helpers/reorderArray";
import InsertListButton from "../../../../utils/ui/InsertListButton";
import DraggableListItem from '../DraggableListItem'

interface Identifiable {
  id: string;
}

interface DraggableListProps<T extends Identifiable> {
  id: string;
  items: T[];
  selectedId?: string;
  title?: string;
  emptyText?: string;
  className?: string;
  renderItem: (item: T, index: number) => React.ReactNode;
  onCreate: () => void;
  onDelete: (id: string) => void;
  onSelect: (id?: string) => void;
  onOrderChange: (items: T[]) => void;
}

export default function DraggableList<T extends Identifiable>({
  id,
  items,
  renderItem,
  title,
  selectedId,
  emptyText,
  className,
  onCreate,
  onSelect,
  onDelete,
  onOrderChange
}: DraggableListProps<T>) {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    
    const itemsSorted = reorderArray(items, result.source.index, result.destination.index);
    onOrderChange(itemsSorted);
  }

  return (
    <div className="flex flex-col gap-4 select-none">
      <h2 className="text-xl 2xl:text-3xl font-bold text-left">{title}</h2>
      <div className="flex flex-col items-center">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={id} type="list" direction="vertical">
            {(provided) => (
              <ul 
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`w-96 py-2 ${className}`}
              >
                {items.length === 0 && (
                  <p className="text-center text-gray-300 font-semibold">{emptyText}</p>
                )}
                {items.map((item, index) => {
                  const selected = selectedId === item.id;
                  return (
                    <DraggableListItem
                      key={item.id}
                      id={item.id}
                      index={index}
                      selected={selected}
                      onDelete={() => onDelete(item.id)}
                      onClick={() => selected ? onSelect(undefined) : onSelect(item.id)}
                    >
                      {renderItem(item, index)}
                    </DraggableListItem>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <InsertListButton onClick={onCreate} />    
      </div>
    </div>
  );
}