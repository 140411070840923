import React, { useState } from "react";
import { UploadButton, UploadDropzone } from "@bytescale/upload-widget-react";
import { FaFileImage } from "react-icons/fa6";

const options = {
  apiKey: "public_12a1z8V6fXbMfWWFEV3xj6yqzse5",
  maxFileCount: 1,
  showFinishButton: false,
  styles: {
    colors: {
      primary: "#FF6B00"
    },
  },
  locale: {
    "uploadFileBtn": "Enviar logo",
    "orDragDropFile": "...ou arraste uma imagem.",
    "cancelBtn": "cancelar",
    "cancelPreviewBtn": "Cancelar",
    "continueBtn": "Continuar",
    "removeBtn": "remover",
    "removeBtnClicked": "removido",
    "submitBtnLoading": "Porfavor espere...",
  },
};

type LogoUploaderProps = {
  imageUrl: string;
  setImageUrl: (image: string) => void;
}

function LogoUploader({imageUrl, setImageUrl}: LogoUploaderProps) {
  const handleClearClick = () => {
    setImageUrl('');
  }

  return (
    <div className="logoUploader">
      <h2 
        className="text-xl font-semibold mb-2"
      >
        Logo do Evento
      </h2>
      <div className="flex flex-col items-center w-52 h-52 gap-4">
        <div className="bg-gray-300 shadow-sm rounded-lg flex-1 w-full flex justify-center items-center h-36 h-36">
          {imageUrl && imageUrl != '' ? (
            <img
              className="rounded-lg max-w-24 max-h-24"
              src={imageUrl}
            />
          ) : (
            <p className="text-gray-500">
              <FaFileImage className="m-auto" />
              Envie a logo do evento.
            </p>
          )}
        </div>
        <div className="flex gap-4">
          <UploadButton 
            options={options}
            onComplete={files => setImageUrl(files.map(x => x.fileUrl).join("\n"))}
          >
            {({onClick}) =>
              <button
                className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-lg transition transition-duration-300"
                onClick={onClick}
              >
                Enviar logo
              </button>
            }
          </UploadButton>
          <button
            className={`${imageUrl != '' ? 'bg-orange-500 hover:bg-orange-600 text-white cursor-pointer' : 'border border-orange-300 bg-transparent text-orange-300 cursor-default'} px-4 py-2 rounded-lg transition transition-duration-300`}
            disabled={imageUrl == ''}
            onClick={handleClearClick}
          >
            Limpar
          </button>
        </div>
      </div>
    </div>
  );
}

export default LogoUploader;