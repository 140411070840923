import React from "react";

type YoutubeLinkProps = {
  linkUrl: string;
  handleFormChange: (content: string) => void;
}

function YoutubeLinkInput({linkUrl, handleFormChange}: YoutubeLinkProps) {
  return (
    <div className="youtubeLinkInput min-w-96">
      <h2 className="text-xl font-bold">Video do Local (Youtube)</h2>
      <input 
        type="text" 
        value={linkUrl}
        onChange={(e) => handleFormChange(e.target.value)}
        placeholder="Ex: https://www.youtube.com/watch?v=aUG--f5qZjo"
        className="w-full text-sm shadow-lg rounded-full mt-2 px-2 font-semibold py-2 border"
      />
  </div>
  );  
}

export default YoutubeLinkInput;