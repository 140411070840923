import React, { useState } from 'react'
import LayoutContainer from '../components/LayoutContainer';
import { useFetchDemoData } from '../hooks/useFetchDemoData';
import LayoutSection from '../components/LayoutSection';
import SectionHeader from '../components/SectionHeader';
import SponsorsContainer from '../components/SponsorsContainer';
import LayoutsHeaderGate from '../components/Header/LayoutsHeaderGate';
import Loading from "./Loading";
import ScheduleController from '../components/ScheduleController';
import CurriculumModal from '../components/CurriculumModal';
import { Participant } from '../types/EventData';

function Schedule() {
  const {data, loading} = useFetchDemoData();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [participant, setParticipant] = useState<Participant | null>(null);

  if (loading) {
    return (<Loading />);
  }

  const scheduleData = data?.schedule;

  const handleCurriculumClick = (participant: Participant) => {
    setParticipant(participant);
    setIsModalOpen(true);
  } 

  return (
    <div className="Schedule layout">
      <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
        <LayoutsHeaderGate />
        <LayoutSection>
          <SectionHeader>PROGRAMAÇÃO</SectionHeader>
          <ScheduleController 
            days={scheduleData?.days!}
            selectedIndex={selectedIndex}
            color1={data?.color1!}
            onDaySelect={setSelectedIndex}
            onCurriculumClick={handleCurriculumClick}
          />
        </LayoutSection>
        <LayoutSection>
          <SponsorsContainer 
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
      {participant && (
        <CurriculumModal
          participant={participant}
          isModalOpen={isModalOpen}
          setIsModalOpen={() => setIsModalOpen(false)}
        />
      )}
    </div>
  )
}

export default Schedule;