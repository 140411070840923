import React, { useState } from "react";
import PlaceNameInput from "../FormInputs/PlaceNameInput";
import PlaceInputGroup from "../FormInputs/PlaceInputGroup";
import PlaceTelephoneInput from "../FormInputs/PlaceTelephoneInput";
import PicturesUploader from "../FormInputs/PicturesUploader";
import EventIntroductionInput from "../FormInputs/DescriptionInput";
import YoutubeLinkInput from "../FormInputs/YoutubeLinkInput";
import { EventData } from "../../../types/EventData";
import DescriptionInput from "../FormInputs/DescriptionInput";

type DetailsProps = {
  eventData: EventData;
  handleFormChange: (data: {[key: string]: any}) => void;
  submitForm: () => void;
}

function Details({eventData, handleFormChange}: DetailsProps) {
  return (
    <div className="details bg-white">
      <div className="px-16 py-8 flex gap-24 flex-col items-center 2xl:items-start 2xl:flex-row justify-center">
        <div className="flex-0 flex flex-col gap-6">
          <PlaceNameInput 
            placeName={eventData.place.name}
            handleFormChange={(placeName) => handleFormChange({place: {name: placeName}})}
          />
          <PlaceInputGroup
            placeData={eventData.place}
            handleFormChange={handleFormChange}
          />
          <PlaceTelephoneInput
            tel={eventData.place.telephone}
            handleFormChange={(tel) => handleFormChange({place: {telephone: tel}})}
          />
        </div>
        <div className="flex-0 flex flex-col gap-4 items-center">
          <PicturesUploader 
            title="Fotos do Local"
            pictures={eventData.place.imgs!}
            setPictures={(newPics) => handleFormChange({
              place: {
                imgs: newPics
              }
            })}
          />
          <YoutubeLinkInput 
            linkUrl={eventData.place.youtubeurl!}
            handleFormChange={(content) => handleFormChange({
              place: {
                youtubeurl: content
              }
            })}
          />
        </div>
        <div className="flex-0 flex flex-col">
          {/* <EventIntroductionInput
            title="Descrição sobre o Local"
            placeholder="Escreva aqui a descrição sobre o local..."
          /> */}
          <DescriptionInput 
            title="Descrição sobre o Local"
            handleFormChange={(content) => handleFormChange({place: {
              description: content
            }})}
            content={eventData.place.description}
            placeholder="Escreva uma descrição sobre o local onde irá acontecer o evento"
          />
        </div>
      </div>
    </div>
  );
}

export default Details;