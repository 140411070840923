import { motion, AnimatePresence } from "framer-motion";
import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import EventManagerProgress from "../components/EventManagerProgress";
import Info from "../components/EventManagerProgress/Steps/Info";
import Details from "../components/EventManagerProgress/Steps/Details";
import ParticipantsManager from "../components/EventManagerProgress/Steps/ParticipantsManager";
import Sponsors from "../components/EventManagerProgress/Steps/Sponsors";
import Settings from "../components/EventManagerProgress/Steps/Settings";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import BlockDiv from "../utils/ui/BlockDiv";
import { EventData, Layout } from "../types/EventData";
import { parseEventDataToCreateEventData } from "../utils/helpers/parseEventDataToCreateEventData";
import { fetchCreateEvent, fetchEditEvent, fetchEventEditData } from "../utils/api";
import Subscriptions from "../components/EventManagerProgress/Steps/Subscriptions";
import { FaArrowLeftLong } from "react-icons/fa6";
import { ToastContainer } from 'react-toastify';
import FeedbackModal from "../components/EventManagerProgress/FeedbackModal";
import { recursiveKeyUpdate } from "../utils/helpers/recursiveKeyUpdate";
import Schedule from "../components/EventManagerProgress/Steps/Schedule";
import Expositors from "../components/EventManagerProgress/Steps/Expositors";
import Categories from "../components/EventManagerProgress/Steps/Categories";
import Functions from "../components/EventManagerProgress/Steps/Functions";
import Loading from "./Loading";

const stateComponents = [
  Info,
  Details,
  Categories,
  ParticipantsManager,
  Functions,
  Schedule,
  Sponsors,
  Subscriptions,
  Expositors,
  Settings
];

const variants = {
  enter: (direction: number) => {
    return {
      x: direction === 0 ? 500 : -500,
      opacity: 0
    };
  },
  center: {
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      x: direction === 0 ? -500 : 500,
      opacity: 0
    };
  }
};

enum EventCreationStatus {
  none = -1,
  loading = 0,
  success = 1,
  error = 2
}

function EventManager() {
  const { layoutName, eventId } = useParams();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [direction, setDirection] = useState<number>(0);
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eventCreationStatus, setEventCreationStatus] = useState<EventCreationStatus>(EventCreationStatus.none);
  const [eventCreationText, setEventCreationText] = useState<string>('asdasdasd');

  const navigate = useNavigate();

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [eventData, setEventData] = useState<EventData>({
    layout: (layoutName as Layout),
    logourl: '',
    banner: {
      backgroundimgs: [],
      title: ''
    },
    introduction: [],
    speakers: [],
    participants: [],
    functions: [],
    place: {
      name: '',
      district: '',
      state: '',
      cep: '',
      number: '',
      complement: '',
      reference: '',
      telephone: '',
      street: '',
      description: '',
      city: '',
      youtubeurl: '',
      imgs: []
    },
    sponsors: [],
    categories: [],
    expositor: {
      manuallink: '',
      expositors: [],
      name: '',
      telephones: [],
      email: ''
    },
    schedule: {
      days: []
    },
    startdate: new Date(),
    paymentdate: new Date(),
    subscriptioncategories: [],
    color1: [0, 0, 0],
    color2: [0, 0, 0],
    route: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      if (eventId) {
        setIsLoading(true);
        const data = await fetchEventEditData(parseInt(eventId));
        setIsLoading(false);
        setIsEditing(true);
        setEventData(data)
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleStepChange = (step: number) => {
    setDirection(step > currentStep ? 0 : 1);
    setCurrentStep(step);
  };

  const handleFormChange = (data: {[key: string]: any}) => {
    setEventData(prevState => {
      const newState = {...prevState};
      recursiveKeyUpdate(newState, data);
      return newState;
    });
  }
  
  const handleFormSubmit = async () => {
    try {
      onOpenModal();
      setEventCreationStatus(EventCreationStatus.loading);

      const route = eventData.route?.replace('/', '');
      const fetchData = JSON.stringify(parseEventDataToCreateEventData(eventData));
  
      console.log("FETCH DATA");
      console.log(fetchData)

      if (isEditing) {
        await fetchEditEvent(fetchData, eventId!);
        setEventCreationText(`Acesse seu evento editado em <a href="/${route}">/${route}</a>`);
      } else {
        await fetchCreateEvent(fetchData);
        setEventCreationText(`Acesse seu novo evento em <a href="/${route}">/${route}</a>`);
      }
      setEventCreationStatus(EventCreationStatus.success);
    } catch(errorMsg) {
      // errorNotify(code as string);
      console.log(errorMsg)
      setEventCreationText(errorMsg as string)
      setEventCreationStatus(EventCreationStatus.error);
    }
  }

  const renderStep = (): ReactNode => {
    const StateComponent = stateComponents[currentStep];
    if (!StateComponent) {
      return <div>Invalid step</div>;
    }
    return (
      <motion.div
        key={currentStep}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        custom={direction}
        transition={{
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.1 }
        }}
      >
        <div className="fixed flex items-center justify-between px-4 w-screen h-screen pointer-events-none">
          {currentStep > 0 ? (
            <RiArrowLeftLine 
            className="pointer-events-auto cursor-pointer transform transition transition-duration-300 hover:scale-110"
            onClick={() => handleStepChange(currentStep - 1)}
            style={{width: '50px', height: '50px'}}
          />) : (<BlockDiv />)}
          {currentStep < stateComponents.length - 1 ? ( <RiArrowRightLine 
            className="pointer-events-auto cursor-pointer transform transition transition-duration-300 hover:scale-110"
            onClick={() => handleStepChange(currentStep + 1)}
            style={{width: '50px', height: '50px'}}
            />) : (<BlockDiv />)}
        </div>
        <StateComponent 
          eventData={eventData}
          handleFormChange={handleFormChange}
          submitForm={handleFormSubmit}
          isEditing={isEditing}
        />
      </motion.div>
    );
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="EventManager bg-white 2xl:overflow-hidden relative"
      style={{
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="absolute p-8">
        <a href="/events"><FaArrowLeftLong style={{width: '35px', height: '35px'}} /></a>
      </div>
      <nav className="flex justify-center items-center pt-6 pb-2 mb-2">
        <h1 className="font-bold text-4xl">Configure o Layout</h1>
      </nav>
      <div className="relative">
        <EventManagerProgress
          currentStep={currentStep}
          stepChange={handleStepChange}
        />
        <AnimatePresence mode="wait" custom={direction}>
          {renderStep()}
        </AnimatePresence>
      </div>
      
      <Modal open={isLoading} closeIcon={false} onClose={() => navigate('/events')} center closeOnEsc={false} closeOnOverlayClick={false}>
        <div className="w-96 h-96 max-w-96 max-h-96 flex flex-col items-center justify-center">
          <Loading />
        </div>
      </Modal>

      <Modal open={open} onClose={onCloseModal} center>
        <div className="w-96 h-96 max-w-96 max-h-96 flex flex-col items-center justify-center">
          <h2 
            className={`text-center mt-12 text-4xl font-bold ${eventCreationStatus == EventCreationStatus.error ? 'text-red-500' : (eventCreationStatus == EventCreationStatus.loading ? 'text-blue-500' : 'text-green-500')}`}
          >
            {isEditing ? 'Editando evento' : 'Criando evento'}
          </h2>
          <FeedbackModal 
            eventCreationStatus={eventCreationStatus}
            message={eventCreationText}
          />
        </div>
      </Modal>
      <ToastContainer />
    </motion.div>
  );
}

export default EventManager;
