import React from "react";

interface StepCircleProps {
  index: number;
  title: string;
  fill?: boolean;
  color2: number[];
}

function StepCircle({index, title, fill, color2}: StepCircleProps) {

  const circleStyle = {
    backgroundColor: fill ? 'transparent' : `rgb(${color2})`,
    borderColor: fill ? `rgb(${color2})` : 'transparent',
  };

  const textColor = fill ? `rgb(${color2})` : 'white';

  return (
    <div className="stepCircle">
      <div className="flex items-center gap-4 flex-col xl:flex-row">
        <div className="w-10 h-10 rounded-full flex justify-center items-center border border-2" style={circleStyle}>
          <p className="text-xl font-semibold" style={{color: textColor}}>{index}</p>
        </div>
        <p className="text-lg font-semibold" style={{color: `rgb(${color2})`}}>
          {title.toUpperCase()}
        </p>
      </div>
    </div>
  );
}

export default StepCircle;