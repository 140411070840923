import React from "react";

interface SectionHeaderProps extends React.HTMLAttributes<HTMLLIElement> {
  children: React.ReactNode;
  thin?: boolean;
}

function SectionHeader({children, thin} : SectionHeaderProps) {
  return (
    <h2 className={`${thin ? 'font-semibold' : 'font-extrabold'} text-2xl uppercase tracking-wide ml-5 my-5`}>{children}</h2>
  );
}

export default SectionHeader;