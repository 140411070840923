import React, { useEffect, useRef } from "react";
import {getCoordinates, initMap, LocationName } from "../../../services/MapService";
import { Place } from "../../../types/EventData";

import 'leaflet/dist/leaflet.css'

export type LocationMapProps = {
  location: Place;
  width?: number;
  height?: number;
  [key: string] : any;
}

function LocationMap({location, className, width, height}: LocationMapProps) {
  const mapRef = useRef<L.Map | undefined>(undefined);

  useEffect(() => {
    const loadMap = async () => {
      const coordinates = await getCoordinates(location);
      
      if (coordinates && !mapRef.current) {
        mapRef.current = initMap(coordinates, location.name as LocationName)
      }
    }

    loadMap();
  }, [location]); 
  
  return (
    <div className="locationMap">
      <div className={className}>
        <div id="map" className="rounded" style={{
          height: height && `${height}px` || '500px',
          width: width && `${width}px` || '800px',
        }}></div>
      </div>
    </div>
  );
}

export default LocationMap;