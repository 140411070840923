import React, { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from "react-cookie";
import { fetchLogin } from "../utils/api";

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();    

  const [cookies] = useCookies(['authJwt']);
  const [loginSuccess, setLoginSuccess] = useState(false);

  const errorNotify = () => toast.error("Algum erro aconteceu, verifique as informações e tente novamente.", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  useEffect(() => {
    if (loginSuccess && cookies['authJwt']) {
      navigate('/events');
    }
  }, [loginSuccess, cookies, navigate]);

  const formSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const res = await fetchLogin(username, password);
      console.log(res);
      setLoginSuccess(true);
    } catch (err) {
      console.log('Error');
      console.log(err)
      errorNotify(); 
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="Login bg-white"
      style={{
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <form 
        onSubmit={formSubmitHandler}
        className="relative z-10 h-full w-full flex justify-center items-center"
      >
        <div className="bg-white min-w-96 min-h-80 rounded-lg border-2 shadow-xl flex flex-col justify-center items-center gap-12 py-24 px-16">
          <div className="text-center">
            <img 
              className='m-auto'
              src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/credentechlogo.png" 
              alt="Credentech" 
            />
          </div>
          <div className="flex flex-col gap-4">
            <input 
              type="text"
              name="username"
              id="usernameInput"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="USERNAME"
              className="border rounded-full px-4 py-2 shadow-sm"
              required
            />
            <input 
              type="password"
              name="password"
              id="passwordInput"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="PASSWORD"
              className="border rounded-full px-4 py-2 shadow-sm"
              required
            />
            <input
              type="submit"
              id="submitInput"
              value="login"
              className="uppercase text-white bg-gradient-to-r from-orange-600 to-orange-800 px-4 py-3 rounded-full font-semibold transition-all ease-in-out hover:animate-gradient hover:scale-105 duration-300 outline-none mt-4 shadow-lg cursor-pointer "
            />
          </div>
        </div>
      </form>
      <ToastContainer />
    </motion.div>
  );
}

export default Login;