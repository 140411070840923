import React from "react";

interface ExpositorSectionProps extends React.HTMLAttributes<HTMLLIElement> {
  children?: React.ReactNode;
  title: string;
  containerized?: boolean;
}

function ExpositorSection({title, children, containerized}: ExpositorSectionProps) {
  return (
    <div className={`expositorSection ${containerized ? 'bg-slate-50 rounded-lg' : ''}`}>
      <div className='py-5'>
        <p className="tracking-wider text-lg font-medium text-center">{title}</p>
        <div className="flex justify-center">
          {children}
        </div>
      </div>
    </div>
  );
}

export default ExpositorSection;