import React from "react";
import { IconType } from "react-icons";
import { Tooltip } from "react-tooltip";

interface TooltipIconProps {
  id: string;
  content: string;
  Icon: IconType;
  height?: number;
  width?: number;
  href?: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

export default function TooltipIcon({href, height=30, width=30, id, content, Icon, className, disabled, onClick}: TooltipIconProps) {
  return (
    <a
      href={href}
      className={`inline-block me-2 transition-transform duration-200 ease-in-out hover:scale-110 outline-none cursor-pointer ${className} ${disabled ? 'text-stone-700 opacity-50 cursor-not-allowed' : ''}`}
      onClick={onClick}
      data-tooltip-id={`evt-tooltip-${id}`}
      data-tooltip-content={content}
      data-tooltip-place="bottom"
    >
      <Icon style={{height: `${height}px`, width: `${width}px`}} />
      <Tooltip id={`evt-tooltip-${id}`} />
    </a>
  );
}