import { useEffect, useState } from "react";
import NavItem from "./NavItem/NavItem";
import Header from "../../Header";
import { useParams } from "react-router-dom";

interface NavbarProps {
  column?: boolean;
  className?: string;
  logo?: string;
  noLogo?: boolean;
  color1: number[];
  layout: "layout1" | "layout2" | "layout3" | "layout4"
}

function Navbar({column, className, logo, layout, noLogo, color1}: NavbarProps) {
  const { eventSlug } = useParams();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <nav className="navbar pt-2">
      {layout === "layout2" && !noLogo ? <Header small logo={logo!}  /> : <></>}
      <div className={`h-full ${className}`}>
        <ul className={`list-none flex gap-2 w-full  ${column ? 'flex-col' : ''}`}>
          <li className={column ? "px-28" : ""}></li>
          <NavItem color1={color1} redirect={`/${eventSlug}`} isMobile={isMobile}>Home</NavItem>
          <NavItem color1={color1} column={column} hasDropdown isMobile={isMobile}>
            Sobre
            <NavItem color1={color1} redirect={`/${eventSlug}/comissions`} className="mt-1" isMobile={isMobile}>Comissões</NavItem>
            <NavItem color1={color1} redirect={`/${eventSlug}/guests`} className="mt-1" isMobile={isMobile}>Convidados</NavItem>
            <NavItem color1={color1} redirect={`/${eventSlug}/expositors`} className="mt-1" isMobile={isMobile}>Expositores</NavItem>
          </NavItem>
          <NavItem color1={color1} redirect={`/${eventSlug}/subscriptions`} isMobile={isMobile}>Inscrição</NavItem>
          <NavItem color1={color1} redirect={`/${eventSlug}/schedule`} isMobile={isMobile}>Programação</NavItem>
          <NavItem color1={color1} redirect={`/${eventSlug}/support`} isMobile={isMobile}>Fale Conosco</NavItem>
        </ul>
      </div>
      {layout === "layout4" && !noLogo ? <Header center logo={logo!}  /> : <></>}
      {layout === "layout1" && !noLogo ? <Header logo={logo!}  /> : <></>}
    </nav>
  );
}

export default Navbar;