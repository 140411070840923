import React, { useState } from "react";
import Colorful from '@uiw/react-color-colorful';
import { hsvaToHex, hsvaToRgba } from '@uiw/color-convert';
import rgbToHsv from "../../../../utils/helpers/rgbToHsv";

type ColorSelectorProps = {
  title: string;
  rgbColor: number[];
  handleFormChange: (rgb: number[]) => void;
}

function ColorSelector({handleFormChange, title, rgbColor}: ColorSelectorProps) {
  const hsv = rgbToHsv(rgbColor[0] ?? 0, rgbColor[1] ?? 0, rgbColor[2] ?? 0);
  const [hsva, setHsva] = useState({ h: hsv[0], s: hsv[1], v: hsv[2], a: 1 });
  
  return (
    <div className="ColorSelector">
      <h2 
        className="text-xl font-semibold"
      >
        {title}
      </h2>
      <Colorful 
        color={hsva}
        disableAlpha={true}
        className="w-32"
        onChange={(color) => {
          const { hsva } = color;

          setHsva(hsva);
          // handleFormChange(hsvToRGB(hsva.h, hsva.s, hsva.v));
          const rgba = hsvaToRgba({
            h: hsva.h,
            s: hsva.s,
            v: hsva.v,
            a: 1
          });
          handleFormChange([rgba.r, rgba.g, rgba.b])
        }}
      />
      <div 
        className="rounded-full m-auto mt-2"
        style={{
          width: '30px',
          height: '30px',
          background: hsvaToHex(hsva)
        }} 
      />
    </div>
  );
}

export default ColorSelector;