import React from "react";

type InfoRowProps = {
  property: string;
  value: string;
}

function InfoRow({property, value}: InfoRowProps) {
  return (
    <div className="infoRow">
      <p className="ms-4 mt-2 flex">
        <span className="font-semibold">{property}</span>
        <span className="ms-2">{value}</span>
      </p>
    </div>
  );
}

export default InfoRow;