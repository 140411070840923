import React from 'react'
import LayoutContainer from '../components/LayoutContainer';
import { useFetchDemoData } from '../hooks/useFetchDemoData';
import LayoutSection from '../components/LayoutSection';
import SectionHeader from '../components/SectionHeader';
import ExpositorSection from '../components/ExpositorSection';
import SponsorsContainer from '../components/SponsorsContainer';
import formatTelephone from '../utils/helpers/formatTelephone';
import LayoutsHeaderGate from '../components/Header/LayoutsHeaderGate';
import Loading from "./Loading";

function Expositors() {
  const {data, loading} = useFetchDemoData();
  
  if (loading) {
    return (<Loading />);
  }

  const expositorData = data?.expositor!;

  const renderExpositors = (containerized?: boolean) => {
    return (
      <div>
        <SectionHeader>EXPOSITOR</SectionHeader>
        <ExpositorSection containerized={containerized} title="Manual de Exposição">
          <p className='text-slate-600 text-center mt-2 underline'>
            <a target='_blank' href={expositorData.manuallink}>[clique para efetuar o download]</a>
          </p>
        </ExpositorSection>
        <div className='border-b-neutral-300 border-2 border-transparent my-3 mx-16' />
        <ExpositorSection containerized={containerized} title="Expositores Confirmados">
          <ul className='flex flex-col items-center my-8'>
            {expositorData.expositors.map((expositor) => (
              <li>{expositor}</li>
            ))}
          </ul>
        </ExpositorSection>
        <div className='border-b-neutral-300 border-2 border-transparent my-3 mx-16' />
        <ExpositorSection containerized={containerized} title="Atendimento ao Expositor">
          <div className='py-4 text-center max-w-96'>
            <p>Informações sobre expositores para este evento, entre em contato com</p>
            <p className='font-bold my-2'>{expositorData.name}</p>
            <p className='text-center'>
              <b>FONE: </b>
              {expositorData.telephones.map((tel) => (
                <span className='me-4'>{formatTelephone(tel)}</span>
              ))}
            </p>
            <p>
              <b>E-MAIL: </b>
              <span className='underline'>{expositorData.email}</span>
            </p>
          </div>
        </ExpositorSection>
        <div className='my-4' />
      </div>
    );
  }

  return (
    <div className="Expositors layout">
      <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
        <LayoutsHeaderGate />
        {/* <LayoutSection> */}
          {data?.layout == "layout1" ? (
            <LayoutSection>
              {renderExpositors()}
            </LayoutSection>
          ) : (
            renderExpositors(true)
          )}
        {/* </LayoutSection> */}
        <LayoutSection>
          <SponsorsContainer 
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  )
}

export default Expositors;