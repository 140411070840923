import React, { useState } from "react";
import { motion } from 'framer-motion';
import Wave from "react-wavify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import PageWaves from "../components/PageWaves";
import CredentechNavbar from "../components/CredentechNavbar";

import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"
import Modal from "react-responsive-modal";

function About() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="About"
    >
      <div className="flex flex-col items-start min-h-screen">
        <CredentechNavbar />
        <div className="flex-1 w-full flex flex-col lg:flex-row items-center px-2 lg:px-12 gap-8">
          <div className="flex flex-col justify-center w-full mt-12 lg:m-0 lg:w-1/2 text-center lg:text-left">
            <h1
              className="font-black text-5xl min-[2000px]:text-7xl"
              style={{
              color: '#FF6B00'
              }}
            >
              Sobre nós
            </h1>
            <p className="tracking-wide my-8 text-lg">
            Na Credentech, somos especialistas em soluções inovadoras de credenciamento e gestão de eventos, desenvolvendo ferramentas que simplificam a organização e o controle de participantes. Com um sistema robusto e eficiente, oferecemos ferramentas que incluem controle de acesso, emissão de crachás, check-in automatizado, geração de relatórios e monitoramento em tempo real, tudo com tecnologia de ponta e integração digital. 
            Seja em congressos, palestras ou eventos corporativos, nosso objetivo é otimizar cada etapa do credenciamento, permitindo que produtores se concentrem no sucesso do evento. Acreditamos em tecnologia acessível e personalizada, garantindo segurança, praticidade e eficiência para eventos de qualquer porte.
            </p>
            <button
              className="uppercase text-white bg-gradient-to-r from-orange-600 to-orange-800 px-12 py-3 rounded-full font-semibold transition-all ease-in-out hover:animate-gradient mt- max-w-64 m-auto lg:m-0"
              style={{
                backgroundSize: "200% 200%",
                backgroundPosition: "0% 50%",
                animation: "gradient-slide 3s ease infinite"
              }}
              onClick={() => setModalOpen(true)}
              >
              Fale Conosco!
            </button>
          </div>
          <div 
            className="w-full lg:w-1/2"
            style={{
              userSelect: 'none',
              '--swiper-navigation-color': `#FF6600`,
              '--swiper-pagination-color': `#FF6600`,
              '--swiper-pagination-active-color': `#FF6600`
            } as React.CSSProperties & { [key: string]: string | number}}
          >
            <Swiper
              modules={[Navigation, Pagination]}
              pagination={{clickable: true}}
              navigation={true}
            >
              <SwiperSlide>
                <img 
                  src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/banner.png"
                  className="px-24 py-8 h-[300px] md:h-[600px] object-cover object-center m-auto"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img 
                  src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/banner.png"
                  className="px-24 py-8 h-[300px] md:h-[600px] object-cover object-center m-auto"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img 
                  src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/banner.png"
                  className="px-24 py-8 h-[300px] md:h-[600px] object-cover object-center m-auto"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      {/* <Modal
        onClose={() => setModalOpen(false)}
        visible={modalOpen}
        width={600}
        height={500}
      >
        <div className="px-6 py-6 flex flex-col items-center gap-4">
          <h2 className="text-2xl font-black text-[#FF6600]">Mande uma mensagem!</h2>
          <div className="w-full px-24">
            <label
              htmlFor="email"
              className="text-lg ms-2"
            >
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Digite o seu email."
              className="w-full border border-2 rounded-lg px-4 py-1" 
            />
          </div>
          <div className="w-full px-24">
            <label
              htmlFor="content"
              className="text-lg ms-2"
            >
              Sua menssagem:
            </label>
            <textarea 
              name="content" 
              id="content"
              placeholder="Digite o conteúdo da mensagem."
              className="w-full border border-2 rounded-lg p-4 resize-none"
            ></textarea>
          </div>
          <button
            className="uppercase text-white bg-gradient-to-r from-orange-600 to-orange-800 px-12 py-3 rounded-full font-semibold transition-all ease-in-out hover:animate-gradient mt- max-w-64 m-auto lg:m-0"
            style={{
              backgroundSize: "200% 200%",
              backgroundPosition: "0% 50%",
              animation: "gradient-slide 3s ease infinite"
            }}
          >
            Enviar
          </button>
        </div>
      </Modal> */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        center
      >
        <div className="px-6 py-6 flex flex-col items-center gap-4 px-2 xl:px-24">
          <h2 className="text-2xl font-black text-[#FF6600] text-center">Mande uma mensagem!</h2>
          <div className="w-full">
            <label
              htmlFor="email"
              className="text-lg ms-2"
            >
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Digite o seu email."
              className="w-full border border-2 rounded-lg px-4 py-1" 
            />
          </div>
          <div className="w-ful">
            <label
              htmlFor="content"
              className="text-lg ms-2"
            >
              Sua mensagem:
            </label>
            <textarea 
              name="content" 
              id="content"
              placeholder="Digite o conteúdo da mensagem."
              className="w-full border border-2 rounded-lg p-4 resize-none h-64"
            ></textarea>
          </div>
          <button
            className="uppercase text-white bg-gradient-to-r from-orange-600 to-orange-800 px-12 py-3 rounded-full font-semibold transition-all ease-in-out hover:animate-gradient mt- max-w-64 m-auto lg:m-0"
            style={{
              backgroundSize: "200% 200%",
              backgroundPosition: "0% 50%",
              animation: "gradient-slide 3s ease infinite"
            }}
          >
            Enviar
          </button>
        </div>
      </Modal>
    </motion.div>
  );
}
  
export default About;