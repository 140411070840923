import React from "react";
import { EventData } from "../../../types/EventData";
import Success from "../../../utils/ui/Success";

interface SettingsProps {
  eventData: EventData;
  handleFormChange: (data: {[key: string]: any}) => void;
  submitForm: () => void;
  isEditing?: boolean;
}

function Settings({eventData, handleFormChange, submitForm, isEditing}: SettingsProps) {
  const layoutOptions = ["layout1", "layout2", "layout3", "layout4"];

  const formatLayoutName = (layout: string) => layout.charAt(0).toUpperCase() + layout.slice(1).replace(/\d+/, (match) => ` ${match}`);

  return (
    <div className="settings">
      <div className="px-36 py-16 flex justify-center">
        <div className="flex flex-col gap-4">
          <div>
            <h2 className="text-2xl font-semibold">
              Rota do evento
            </h2>
            <input 
              type="text" 
              value={(eventData.route != "" ? `/${eventData.route}` : '')}
              onChange={(e) => handleFormChange({
                route: e.target.value!.replace("/", "")
              })}
              className="w-full text-sm shadow-lg rounded-full mt-2 px-4 font-semibold py-2 border max-w-96 ms-2"
              placeholder="Ex: /neurobahia"
            />
          </div>
          <div>
            <h2 className="text-2xl font-semibold">
              Alterar layout
            </h2>
            <select
              className="w-full text-sm shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border max-w-80 bg-white"
              onChange={(e) => handleFormChange({
                layout: e.target.value
              })}
            >
              {layoutOptions.map((layoutName) => (
                <option selected={eventData.layout == layoutName} value={layoutName}>{formatLayoutName(layoutName)}</option>
              ))}
            </select>
          </div>
          <button 
            className="uppercase text-white bg-gradient-to-r from-orange-600 to-orange-800 px-12 py-3 rounded-full font-semibold transition-all ease-in-out hover:animate-gradient self-end"
            onClick={submitForm}
            style={{
              backgroundSize: "200% 200%",
              backgroundPosition: "0% 50%",
              animation: "gradient-slide 3s ease infinite"
            }}
          >
            {isEditing ? 'Editar Evento' : 'Criar Evento'}
          </button> 
        </div>
      </div>
    </div>
  );
}

export default Settings;