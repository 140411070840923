import React from "react";

interface TableCellProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  children?: React.ReactNode;
}

function TableCell({className, children}: TableCellProps) {
  return (
    <td className={`${className} font-semibold px-5 py-2 text-center border-b border-gray-200`}>
      {children}
    </td>
  );
}

export default TableCell;