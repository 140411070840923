import React, { useState } from 'react'
import LayoutContainer from '../components/LayoutContainer';
import { useFetchDemoData } from '../hooks/useFetchDemoData';
import LayoutSection from '../components/LayoutSection';
import SectionHeader from '../components/SectionHeader';
import SponsorsContainer from '../components/SponsorsContainer';
import LayoutsHeaderGate from '../components/Header/LayoutsHeaderGate';
import Loading from "./Loading";
import { rgbHoverColor } from '../utils/helpers/rgbHoverColor';

function Support() {
  const {data, loading} = useFetchDemoData();
  const [isSubmitHovering, setSubmitHovering] = useState<boolean>(false);

  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="Support layout">
      <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
        <LayoutsHeaderGate />
        <LayoutSection>
          <SectionHeader>SUPORTE</SectionHeader>
          {/* TODO: Please refactor this form */}
          <form action="" className='flex flex-col items-center gap-12 justify-center text-center'>
            <div className='flex flex-col'>
              <label htmlFor="name" className='text-sm font-medium text-gray-700'>Nome</label>
              <input type="text" name="name" id="name" placeholder="Digite o seu nome" className='max-w-80 border-b border-gray-300 text-gray-900 text-sm focus:outline-none focus:border-blue-500 block w-full p-2 transition duration-300' />
            </div>
            <div className='flex flex-col'>
              <label htmlFor="email" className='text-sm font-medium text-gray-700'>E-Mail</label>
              <input type="email" name="email" id="email" placeholder="Digite o seu email" className='min-w-96 border-b border-gray-300 text-gray-900 text-sm focus:outline-none focus:border-blue-500 block w-full p-2 transition duration-300' />
            </div>
            <div className='flex flex-col'>
              <label htmlFor="message" className='text-sm font-medium text-gray-700'>Mensagem</label>
              <textarea
                name="message" 
                id="message" 
                placeholder="Digite a sua mensagem" 
                className='min-w-96 border-b border-gray-300 text-gray-900 text-sm focus:outline-none focus:border-blue-500 block w-full p-2 transition duration-300'
                rows={4} // Define a altura inicial do textarea
              />
            </div>
            <button 
              type="submit" 
              // className='px-6 py-2 mt-4 bg-blue-500 text-white font-semibold text-sm rounded-lg shadow hover:bg-blue-600 transition duration-300'
              className='px-6 py-2 mt-4 text-white font-semibold text-sm rounded-lg shadow transition duration-300'
              style={{
                backgroundColor: isSubmitHovering ? `rgb(${rgbHoverColor(data?.color1!)})` : `rgb(${data?.color1!})`
              }}
              onMouseEnter={() => setSubmitHovering(true)}
              onMouseLeave={() => setSubmitHovering(false)}
            >
              Enviar Mensagem
             </button>
          </form>
        </LayoutSection>
        <LayoutSection>
          <SponsorsContainer 
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  )
}

export default Support;