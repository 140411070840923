import React from "react";
import isTodayOrEarlier from "../../utils/helpers/isDateTodayOrEalier";
import convertDateToString from "../../utils/helpers/convertDateToString";
import { SubscriptionCategory } from "../../types/EventData";

type SubscriptionCategoriesTablesProps = {
  paymentdate: Date;
  color1: number[];
  subscriptionCategories: SubscriptionCategory[];
}

function SubscriptionCategoriesTables({paymentdate, color1, subscriptionCategories}: SubscriptionCategoriesTablesProps) {
  return (
    <table className='table-auto w-full block mt-5'>
      <thead className='w-full block'>
        <tr className='w-full flex gap-2'>
          <th 
            className='font-semibold text-white py-2 px-4 w-2/3'
            style={{
              backgroundColor: `rgb(${color1})`
            }}
          >
            Categoria
          </th>
          {/* <th className={`${!isTodayOrEarlier(paymentdate!) ? 'line-through' : ''} bg-blue-500 font-semibold text-white py-2 px-4 w-1/4`}>Até dia {convertDateToString(paymentdate!)}</th> */}
          <th 
            className={`${!isTodayOrEarlier(paymentdate!) ? 'line-through' : ''} font-semibold text-white py-2 px-4 w-1/4`}
            style={{
              backgroundColor: `rgb(${color1})`
            }}
          >
            Até dia {convertDateToString(paymentdate!)}
          </th>
          {/* <th className='bg-blue-500 font-semibold text-white py-2 px-4 w-1/4'>Após dia {convertDateToString(paymentdate!)}</th> */}
          <th 
            className='font-semibold text-white py-2 px-4 w-1/4'
            style={{
              backgroundColor: `rgb(${color1})`
            }}
          >
            Após dia {convertDateToString(paymentdate!)}
          </th>
        </tr>
      </thead>
      <tbody className='w-full block'>
        {subscriptionCategories.map((subscriptionCategory, index) => {
          let backgroundClass = index % 2 == 0 ? 'bg-slate-200' : 'bg-slate-300';

          if (subscriptionCategory.hidden) return <></>

          return (
            <tr className='w-full flex gap-2 text-center'>
              <td className={`font-normal font-medium py-2 px-4 w-2/3 ${backgroundClass}`}>{subscriptionCategory.title}</td>
              <td className={`${!isTodayOrEarlier(paymentdate!) ? 'line-through' : ''} font-normal font-medium py-2 px-4 w-1/4 ${backgroundClass}`}>R$ {subscriptionCategory.prevalue}</td>
              <td className={`font-normal font-medium py-2 px-4 w-1/4 ${backgroundClass}`}>R$ {subscriptionCategory.posvalue}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ); 
}

export default SubscriptionCategoriesTables;