import { Draggable } from "@hello-pangea/dnd";
import { FunctionData } from "../../../../types/EventData";
import truncateString from "../../../../utils/helpers/truncateString";
import { FaTrash } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";

interface FunctionListItemProps {
  functionData: FunctionData;
  index: number;
  selected?: boolean;
  handleDelete: (functionId: string) => void;
  onClick: (functionId: string) => void;
}

export default function FunctionListItem({ 
  functionData, 
  index,
  selected,
  handleDelete,
  onClick
}: FunctionListItemProps) {
  return (
    <Draggable draggableId={functionData.id} index={index}>
      {(provided) => (
        <li
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={`${selected ? 'bg-neutral-900 text-white' : 'bg-stone-200 text-stone-900'} w-full mt-2 rounded-full shadow-md transition-colors duration-300 ease-in-out flex gap-4 items-center justify-between px-8 py-1`}
          onClick={() => onClick(functionData.id)}
        >
          <p className="uppercase text-sm font-semibold tracking-wider max-w-48 overflow-hidden min-h-8 flex items-center">{truncateString(functionData.name, 15)}</p>
          <button
            data-tooltip-id={`function-${index + 1}-tooltop`}
            data-tooltip-content="Deletar"
            data-tooltip-place="bottom"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(functionData.id);
            }}
          >
            <FaTrash />
          </button>
          <Tooltip id={`function-${index + 1}-tooltop`} />
        </li>
      )}
    </Draggable>
  );
}