import React, { useEffect, useState } from "react";
import { EventData } from "../../../types/EventData";
import TextInput from "../FormInputs/TextInput";
import PdfUploader from "../../PdfUploader";
import ExpositorList from "../FormInputs/ExpositorList";
import formatTelephone from "../../../utils/helpers/formatTelephone";

interface ExpositorsProps {
  eventData: EventData;
  handleFormChange: (data: {[key: string]: any}) => void;
  submitForm: () => void;
}

export default function Expositors({
  eventData,
  handleFormChange,
  submitForm
}: ExpositorsProps) {
  return (
    <div className="expositors bg-white">
      <div className="px-16 py-8 flex justify-center">
        <div className="flex flex-col gap-8">
          <div className="flex gap-12">
            <div className="min-w-96">
              <TextInput 
                title="Email" 
                placeholder="Email de atendimento ao expositor" 
                value={eventData.expositor.email}
                onChange={(value: string) => {
                  handleFormChange({
                    expositor: {
                      email: value
                    }
                  })
                }}
              />
            </div>
            <div className="min-w-96">
              <TextInput 
                title="Telefone 1" 
                placeholder="Telefone de atendimento ao expositor" 
                value={eventData.expositor.telephones[0] || ''}
                onChange={(value) => {
                  handleFormChange({
                    expositor: {
                      telephones: [value.replace(/\D/g, '').slice(0, 11), eventData.expositor.telephones[1]]
                    }
                  })
                }}
              />
            </div>
            <div className="min-w-96"> 
              <TextInput 
                title="Telefone 2" 
                placeholder="Telefone de atendimento ao expositor" 
                value={eventData.expositor.telephones[1] || ''}
                onChange={(value) => {
                  handleFormChange({
                    expositor: {
                      telephones: [eventData.expositor.telephones[0], value.replace(/\D/g, '').slice(0, 11)]
                    }
                  })
                }}
              />
            </div>
          </div>
          <div className="flex self-start gap-12">
            <div className="flex flex-col">
              <h2 className="text-xl 2xl:text-3xl font-bold">Manual de Exposição</h2>
              <PdfUploader
                pdfUrl={eventData.expositor.manuallink || ''}
                setPdfUrl={(pdfUrl) => handleFormChange({
                  expositor: {
                    manuallink: pdfUrl
                  }
                })}
              />
            </div>
            <div className="flex flex-col grow ms-12">
              <h2 className="text-xl 2xl:text-3xl font-bold">Lista de Expositores</h2>
              <ExpositorList
                eventData={eventData}
                handleFormChange={handleFormChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}