import React from "react";

interface SponsorSection extends React.HTMLAttributes<HTMLLIElement> {
  title: string;
  children?: React.ReactNode;
  className? : string;
}

function SponsorSection({title, children}: SponsorSection) {
  return (
    <div className="border border-x-transparent border-t-transparent border-b-neutral-300 border-2 py-8 my-6 text-center">
      <p className="text-gray-700">{title}</p>
      {children}
    </div>
  );
}

export default SponsorSection;