import React from "react";
import Divider from "../../utils/ui/Divider";
import DayButton from "../DayButton";
import DayContainer from "../DayContainer";
import { Participant, ScheduleDay } from "../../types/EventData";

interface ScheduleControllerProps {
  days: ScheduleDay[];
  selectedIndex?: number;
  color1: number[];
  onDaySelect: (index?: number) => void;
  onCurriculumClick: (participant: Participant) => void;
}

export default function ScheduleController({
  days,
  color1,
  selectedIndex,
  onDaySelect,
  onCurriculumClick
}: ScheduleControllerProps) {
  return (
    <div>
      <div className='w-full flex justify-center gap-4 select-none'>
        {days.map((day: ScheduleDay, index) => {
          const currentIndex = index;
          return (
            <DayButton
              selected={selectedIndex == (currentIndex)}
              color1={color1}
              day={day.date}
              index={currentIndex + 1}
              onClick={() => {
                if (selectedIndex == currentIndex) {
                  return onDaySelect(undefined);
                }
                return onDaySelect(currentIndex);
              }}
            />
          ); 
        })}
      </div>
      <Divider />
          
      {selectedIndex == undefined ?
        (
          <div>
            <p className='text-center text-xl'>Selecione um dia para visualizar a programção</p>
          </div>
        ) :
        (
          <>
            {days.map((day, index) => {
              if (selectedIndex != index) {
                return (<></>);
              }
              return (
                <DayContainer 
                  color1={color1}
                  day={day}
                  onCurriculumClick={onCurriculumClick}
                />
              );
            })}
          </>
        )
      }
    </div>
  );
}