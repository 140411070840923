import React, { useEffect } from 'react'

import LayoutContainer from '../components/LayoutContainer';
import LayoutSection from '../components/LayoutSection';
import SectionHeader from '../components/SectionHeader';
import { useFetchDemoData } from '../hooks/useFetchDemoData';
import SponsorsContainer from '../components/SponsorsContainer';
import CategorySection from '../components/CategorySection';
import LayoutsHeaderGate from '../components/Header/LayoutsHeaderGate';
import Header from '../components/Header';
import filterParticipantsByCategory from '../utils/helpers/filterParticipantsByCategory';
import Loading from "./Loading";
import Modal from '../utils/ui/Modal';

function Comissions() {
  const {data, loading} = useFetchDemoData();

  const renderCommissions = (containerLayout?: boolean) => {
    return (
      <div>
        <SectionHeader>COMISSÕES</SectionHeader>
        {data?.categories.map((value) => {
          const participants = filterParticipantsByCategory(Object.values(data!.participants), value)
          if (participants.length == 0 || value.guestcategory) {
            return (<></>);
          }
          return (
            <div className='bg-slate-50 rounded-lg px-4 py-4 mb-8'>
              <CategorySection
                containerLayout={containerLayout}
                category={value}
                participants={participants}
              />
            </div>
          );
        })}
      </div>
    );
  }

  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="Comissions layout px-6 sm:px-0">
      <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
        <LayoutsHeaderGate />
          {data?.layout == "layout1" ? (
            <LayoutSection>
              {renderCommissions()}
            </LayoutSection>
          ) : (
            renderCommissions(true)
          )}
        <LayoutSection>
          <SponsorsContainer 
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  );
}

export default Comissions;