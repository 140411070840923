import React, { useEffect, useState } from "react";
import { Sponsor } from "../../../../types/EventData";
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import SponsorListItem from "../SponsorListItem";
import CreateListItemButton from "../CreateListItemButton";
import reorderArray from "../../../../utils/helpers/reorderArray";
import InsertListButton from "../../../../utils/ui/InsertListButton";

interface SponsorsFormProps {
  sponsors: Sponsor[];
  onSelect: (sponsorId: string) => void;
  onUpdate: (sponsorUpdate: Sponsor[]) => void;
}

function SponsorsForm({sponsors, onSelect, onUpdate}: SponsorsFormProps) {
  const [selectedId, setSelectedId] = useState<string>('');

  useEffect(() => {
    setSelectedId('');
  }, [sponsors]);

  useEffect(() => {
    onSelect(selectedId);
  }, [selectedId])

  const onDragEnd = (result: DropResult<string>) => {
    if (!result.destination) {
      return;
    }

    const swipedItemsArray = reorderArray<Sponsor>(sponsors, result.source.index, result.destination.index);

    onUpdate(swipedItemsArray);
  }

  const handleSponsorDelete = (sponsorId: string) => {
    const categoryUpdate = sponsors.filter(sponsor => sponsor.id !== sponsorId);
    setSelectedId('');
    onUpdate(categoryUpdate);
  }

  const handleSponsorClick = (sponsorId: string) => {
    if (selectedId == sponsorId) {
      return setSelectedId('');
    }
    setSelectedId(sponsorId);
  }

  const handleSponsorCreate = () => {
    const categoryUpdate = [...sponsors];
    categoryUpdate.push({
      id: `sponsor-${sponsors.length + 1}`,
      name: '<Novo Patrocinador>',
      pic: 'https://image-cdn-ak.spotifycdn.com/image/ab67706c0000da8463bcdace67f79859e30a17fa'
    });

    onUpdate(categoryUpdate);
  }

  return (
    <div className="w-[400px]">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sponsors" type="list">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="border-r-transparent xl:border-r-slate-200 border-l-transparent border-t-transparent xl:border-b-transparent pe-8 border-4 overflow-y-scroll h-[200px]"
            >
              {sponsors.map((sponsor, index) => (
                <SponsorListItem
                  key={sponsor.id}
                  sponsor={sponsor}
                  index={index}
                  selected={selectedId == sponsor.id}
                  handleDelete={handleSponsorDelete}
                  onClick={handleSponsorClick}
                />
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-center items-center mt-6">
        {/* <CreateListItemButton onClick={handleSponsorCreate} /> */}
        <InsertListButton onClick={handleSponsorCreate} />
      </div>
    </div>
  );
}

export default SponsorsForm;