import React from "react";
import { SponsorCategory } from "../../../../types/EventData";
import { Draggable } from "@hello-pangea/dnd";
import truncateString from "../../../../utils/helpers/truncateString";
import { FaTrash } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";

interface CategoryListItemProps {
  sponsorCategory: SponsorCategory;
  index: number;
  selected?: boolean;
  handleDelete: (sponsorCategoryName: string) => void;
  onClick: (sponsorCategoryName: string) => void;
}

function SponsorCategoryListItem({sponsorCategory, selected, index, handleDelete, onClick}: CategoryListItemProps) {
  return (
    <Draggable draggableId={sponsorCategory.id} index={index}>
      {(provided) => (
        <li
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={`${selected ? 'bg-neutral-900 text-white' : 'bg-stone-200 text-stone-900'} w-full mt-2 rounded-full shadow-md transition-colors duration-300 ease-in-out flex items-center justify-between px-8 py-2 gap-4`}
          onClick={() => onClick(sponsorCategory.id)}
        >
          <div className="flex items-center gap-2">
            <p className="uppercase text-sm font-semibold tracking-wider">
              {truncateString(sponsorCategory.name, 30)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              data-tooltip-id={`sponsorcategory-${index + 1}-tooltip`}
              data-tooltip-content="Deletar"
              data-tooltip-place="bottom"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(sponsorCategory.id);
              }}
            >
              <FaTrash />
            </button>
            <Tooltip id={`sponsorcategory-${index + 1}-tooltip`} />
          </div>
        </li>
      )}
    </Draggable>
  );
}

export default SponsorCategoryListItem;