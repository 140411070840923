import React from "react";

type PaymentDateInputProps = {
  date?: string;
  handleFormChange: (date: string) => void;
}

export default function PaymentDateInput({date, handleFormChange}: PaymentDateInputProps) {
  return (
    <div className="paymentDateInput max-w-96 flex flex-col items-center m-auto">
      <h2 
        className="text-2xl font-semibold m-auto"
      >
        Data de virada de lote
      </h2>
      <input 
        className="border rounded-full text-gray-500 px-4 py-1 mt-2 shadow-lg font-semibold"
        type="date"
        onChange={(e) => handleFormChange(e.target.value)}
        value={date}
      />
    </div>
  );
}