import React from "react";
import { useLottie } from "lottie-react";
import animation from './animation.json';

const options = {
  animationData: animation,
  loop: false
};

const style = {
  height: 200
};

export default function Error() {
  const { View } = useLottie(options, style);

  return (
    <div className="error">
      {View}
    </div>
  );
}