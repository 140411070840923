import React from "react";

import formatDate from "../../../utils/helpers/formatDate";

import Dot from "../../../utils/ui/Dot";
import { Banner, Place } from "../../../types/EventData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

type EventBannerProps = {
  banner: Banner;
  eventDate: Date;
  place: Place;
  color1: number[];
  color2: number[];
  [key: string] : any;
}

function EventBanner({color1, color2, banner, place, eventDate, ...props} : EventBannerProps) {
  // const {title, backgroundImgs, date} = banner;
  const {title, backgroundimgs} = banner;
  const titleWords = title.split(' ');

  return (
    <div 
      className="eventBanner relative bg-gray-500 w-full h-96 overflow-hidden rounded-t-lg"
      style={{
        '--swiper-navigation-color': `rgb(${color2[0]}, ${color2[1]}, ${color2[2]})`,
        '--swiper-pagination-color': `rgb(${color2[0]}, ${color2[1]}, ${color2[2]})`,
        '--swiper-pagination-active-color': `rgb(${color2[0]}, ${color2[1]}, ${color2[2]})`
      } as React.CSSProperties & { [key: string]: string | number}}
    >
      <div className="absolute inset-0 z-0">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          pagination={{ clickable: true }}
          navigation={true}
          loop
          autoplay={{ 
            delay: 2500,
            disableOnInteraction: false,
          }}
          speed={1000}
          className="w-full h-full"
        >
          {backgroundimgs.map((img) => (
            <SwiperSlide>
              <img src={img.src} alt={img.alt} className="w-full h-full object-cover" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="swiper-navigation-higher-z-index" style={{pointerEvents: 'none'}}/>
      <div className="absolute inset-0 z-10 bg-gradient-radial from-transparent via-black to-transparent opacity-50" style={{pointerEvents: 'none'}}></div>
      <div 
        className="absolute inset-0 z-20 flex flex-col items-center justify-center" 
        style={{
          pointerEvents: 'none',
          color: `rgb(${color2[0]}, ${color2[1]}, ${color2[2]})`,
          textShadow: `
            -1px -1px 0 rgba(${color1[0]}, ${color1[1]}, ${color1[2]}, 0.6),
            1px -1px 0 rgba(${color1[0]}, ${color1[1]}, ${color1[2]}, 0.6),
            -1px 1px 0 rgba(${color1[0]}, ${color1[1]}, ${color1[2]}, 0.6),
            1px 1px 0 rgba(${color1[0]}, ${color1[1]}, ${color1[2]}, 0.6)
          `
        }}
      >
        <h1 className="font-black text-4xl 2xl:text-7xl max-w-xl text-center tracking-wider leading-tight uppercase">
          {/* <span>{titleWords[0]}</span> <br />
          {titleWords[1]} */}
          {titleWords.map((word) => (
            <span>{word} <br /></span>
          ))}
        </h1>
        <div className="flex 2xl:flex-row sm:flex-col sm:mt-4 justify-between items-center gap-1 2xl:gap-4">
          <p className="font-bold lg:max-w-28 xl:max-w-32 text-center">{formatDate(eventDate)}</p>
          <Dot color={`rgb(${color2})`} />
          <p className="text-lg xl:px-0 2xl:px-2">{place.name}</p>
          <Dot color={`rgb(${color2})`} />
          <p className="text-lg xl:px-0 2xl:px-2">{place.city} - {place.state}</p>
        </div>
      </div>
    </div>
  );
}

export default EventBanner;