import React, { useEffect } from "react";
import LayoutContainer from "../components/LayoutContainer";
import LayoutsHeaderGate from "../components/Header/LayoutsHeaderGate";
import LayoutSection from "../components/LayoutSection";
import SponsorsContainer from "../components/SponsorsContainer";
import { useFetchDemoData } from "../hooks/useFetchDemoData";
import SectionHeader from "../components/SectionHeader";
import MultistepCheckout from "../components/MultistepCheckout";
import Loading from "./Loading";

function SubscriptionPayment() {
  const {data, loading} = useFetchDemoData();
  
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="subscriptionPayment">
      <LayoutContainer color1={data?.color1!} color2={data?.color2!}>
        <LayoutsHeaderGate />
        <LayoutSection transparent noBorder>
          <SectionHeader thin>Pagamento</SectionHeader>
          <MultistepCheckout 
            subscriptionCategories={data?.subscriptioncategories!}
            color1={data?.color1}
            color2={data?.color2}
            eventid={data?.eventid!}
          />
        </LayoutSection>
        <LayoutSection>
          <SponsorsContainer
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  );
}

export default SubscriptionPayment;