import React from "react";

interface TableHeadProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
}

export default function TableHead({children}: TableHeadProps) {
  return (
    <th className="px-5 py-3 font-normal text-neutral-500">
      {children}
    </th>
  );
}