import React from "react";
import { motion } from "framer-motion";
import { FaTrash } from "react-icons/fa";

interface TrashButtonProps {
  onClick?: () => void;
}

export default function TrashButton({onClick}: TrashButtonProps) {
  return (
    <motion.div
      className="absolute -top-2 -right-2 bg-red-600 text-white p-2 rounded-full"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      whileHover={{ scale: 1.1, boxShadow: "0px 0px 10px rgba(0,0,0,0.5)" }}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick();
      }}
    >
      <FaTrash className="w-4 h-4" />
    </motion.div>
  );
}