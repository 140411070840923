import React from "react";

type PlaceNameInputProps = {
  placeName: string;
  handleFormChange: (placeName: string) => void;
}

function PlaceNameInput({placeName, handleFormChange}: PlaceNameInputProps) {
  return (
    <div className="placeNameInput min-w-96">
      <h2 className="text-xl font-bold">Nome do Local</h2>
      <input 
        value={placeName}
        onChange={(e) => handleFormChange(e.target.value)}
        type="text"
        placeholder="INSIRA O NOME DO LOCAL"
        className="w-full text-sm shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border"
      />
    </div>
  );
}

export default PlaceNameInput;