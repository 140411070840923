import React from "react";

interface StartDateInfo {
  date: Date;
}

export default function StartDateInfo({date}: StartDateInfo) {
  return (
    <div className="flex justify-center items-center gap-2 mt-4">
      <h2 className="text-xl 2xl:text-2xl font-bold">Data de início: </h2>
      <p className="text-lg">
        {date.toLocaleDateString('pt-BR')}
        <span className="text-sm text-gray-400">&nbsp;(Definido em informações gerais)</span>
      </p>
    </div>
  );
}