import React from "react";
import Modal from "../../../utils/ui/Modal";
import { EventSubscriber } from "../../../types/EventData";
import convertDateToString from "../../../utils/helpers/convertDateToString";
import formatTime from "../../../utils/helpers/formatTime";
import formatTelephone from "../../../utils/helpers/formatTelephone";
import formatBRL from "../../../utils/helpers/formatBRL";
import formatCpf from "../../../utils/helpers/formatCpf";
import SectionInfo from "./SectionInfo";

interface SubscriberModalProps {
  modalSubscriber: EventSubscriber;
  visible?: boolean;
  onClose: () => void;
}

export default function SubscriberModal({modalSubscriber, visible, onClose}: SubscriberModalProps) {

  const ModalDivisor = () => (<div className="bg-stone-200 w-full h-0.5 mt-4" />);

  return (
    <Modal 
      visible={visible} 
      onClose={onClose}
      className="relative py-6 px-8 flex flex-col gap-12"
      width={600}
      height={650}
    >
      <div className="flex flex-col justify-center items-center">
        <h2 className="font-black text-3xl">{modalSubscriber.name} {modalSubscriber.surname}</h2>
        <p className="text-sm text-stone-400">
          <span className="font-bold">Inscrito em:</span> {convertDateToString(modalSubscriber.dateofsubscription)} {formatTime(modalSubscriber.dateofsubscription)}
        </p>
      </div>
      <div>
        <h2 className="text-xl text-center">Contato</h2>
        <ModalDivisor />
        <div className="flex justify-between">
          <SectionInfo 
            title="Celular" 
            content={formatTelephone(modalSubscriber.area_code.toString() + modalSubscriber.number.toString())}
          />
          <SectionInfo 
            title="Email" 
            content={modalSubscriber.email} 
          />
        </div>
      </div>
      <div>
        <h2 className="text-xl text-center">Inscrição</h2>
        <ModalDivisor />
        <div className="flex justify-between">
          <SectionInfo 
            title="Categoria" 
            content={modalSubscriber.subscriptioncategory.title} 
          />
          <SectionInfo 
            title="Valor" 
            content={`R$ ${formatBRL(modalSubscriber.valuepaid)}`} 
          />
        </div>
      </div>
      <div>
        <h2 className="text-xl text-center">Informações Pessoais</h2>
        <ModalDivisor />
        <div className="flex justify-between">
          <SectionInfo 
            title="Nome" 
            content={modalSubscriber.name} 
          />
          <SectionInfo 
            title="Sobrenome" 
            content={modalSubscriber.surname} 
          />
        </div>
        <div className="flex justify-between">
          <SectionInfo 
            title="Data de Nascimento" 
            content={convertDateToString(modalSubscriber.birthdate)} 
          />
          <SectionInfo 
            title="CPF" 
            content={modalSubscriber.cpf} 
          />
        </div>
      </div>
      <div>
        <h2 className="text-xl text-center">Endereço</h2>
        <ModalDivisor />
        <div className="flex justify-between">
          <SectionInfo 
            title="Endereço" 
            content={modalSubscriber.address} 
            grow
          />
          <SectionInfo 
            title="Numero" 
            content={modalSubscriber.street_number} 
            grow
          />
        </div>
        <div className="flex justify-between">
          <SectionInfo 
            title="Cidade" 
            content={modalSubscriber.city} 
            grow
          />
          <SectionInfo 
            title="Estado"
            content={modalSubscriber.state}
            grow
          />
        </div>
        <div className="flex justify-between">
          <SectionInfo 
            title="CEP"
            content={modalSubscriber.cep} 
          />
          <SectionInfo 
            title="Complemento"
            content={modalSubscriber.complement} 
          />
        </div>
      </div>
    </Modal>
  );
}