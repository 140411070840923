function isTodayOrEarlier(paymentDate: Date) {
  if (!paymentDate) {
    console.log("A data de pagamento é inválida ou não definida.");
    return false;
  }

  const today = new Date();
  const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const paymentDateFormatted = new Date(paymentDate.getFullYear(), paymentDate.getMonth(), paymentDate.getDate());

  return todayDate.getTime() <= paymentDateFormatted.getTime();
}

export default isTodayOrEarlier