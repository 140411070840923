import React from "react";
import { Participant, ScheduleModule } from "../../../types/EventData";
import PersonItemContainer from "../PersonItemContainer";
import ProgrammingContainer from "../ProgrammingContainer";

interface ModuleContainerProps {
  module: ScheduleModule;
  startDate: Date;
  endDate: Date;
  onCurriculumClick: (participant: Participant) => void;
}

export default function ModuleContainer({
  module,
  startDate,
  endDate,
  onCurriculumClick,
}: ModuleContainerProps) {
  const formatDate = (date: Date) => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const isCompose = module.moduleprogrammings.length > 1 || (module.moduleprogrammings[0] && !module.moduleprogrammings[0].isinterval)

  return (
    <div className={`flex px-2 ${!isCompose && 'bg-stone-200 py-2'}`}>
      <p className="font-bold me-8">
        {`${formatDate(startDate)} - ${formatDate(endDate)}`}
      </p>
      <div className="flex flex-col">
        <p className="font-bold">{isCompose ? `MÓDULO - ${module.title}` : module.title}</p>
        <div className="flex flex-col gap-4">
          {module.assignments.map((assignment) => (
            <PersonItemContainer
              key={assignment.id}
              person={assignment.participant}
              functionData={assignment.function as any}
              onCurriculumClick={onCurriculumClick}
            />
          ))}
        </div>
        <div>
          {module.moduleprogrammings.map((programming, index) => {
            const accumulatedMinutes = module.moduleprogrammings.slice(0, index).reduce((accumulated, reducer) => accumulated + reducer.minutes, 0);

            const startProgrammingDate = new Date(startDate.getTime() + accumulatedMinutes * 60000);
            const endProgrammingDate = new Date(startProgrammingDate.getTime() + programming.minutes * 60000);
            return (
              <ProgrammingContainer
                compose={isCompose}
                programming={programming}
                startDate={startProgrammingDate}
                endDate={endProgrammingDate}
                onCurriculumClick={onCurriculumClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}