import React, { useState } from "react";

type EventTitleInputProps = {
  title: string;
  handleFormChange: (title: string) => void;
}

function EventTitleInput({title, handleFormChange}: EventTitleInputProps) {
  return (
    <div className="eventTitleInput min-w-96">
      <h2 className="text-xl font-semibold">
        Título do Evento
      </h2>
      <input 
        type="text"
        placeholder="INSIRA O TÍTULO DO EVENTO"
        className="w-full text-sm shadow-lg rounded-full mt-2 px-4 font-semibold py-2 border max-w-96 ms-2"
        onChange={(e) => handleFormChange(e.target.value)}
        value={title}
      />
    </div>
  );
}

export default EventTitleInput;