import React from 'react';

type LocationDetailsData = {
  name : string,
  description : string
}

function LocationDetails({name, description} : LocationDetailsData) {
  return (
    <div className="locationDetails">
      <div className="text-center">
        <h3 className="font-semibold text-2xl tracking-wider">{name}</h3>
        <p className="my-2 md:text-sm lg:text-lg text-slate-600 text-wrap">{description}</p>
      </div>
    </div>
  );
}

export default LocationDetails;