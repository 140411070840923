import React, { useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"

import '../../../styles/speakerCarousel.css';
import { Participant } from "../../../types/EventData";
import ProfileBadge from "../../ProfileBadge";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

type SpeakerCarouselData = {
  authors : Participant[];
  color1: number[];
}

function SpeakerCarousel({ authors, color1 } : SpeakerCarouselData) {
  const [swiperControl, setSwiperControl] = useState<SwiperClass>();

  return (
    <div 
      className="m-auto w-full px-24"
      // style={{maxWidth: '1200px'}}
    >
      <Swiper
        onSwiper={(swiper: SwiperClass) => setSwiperControl(swiper)}
        className="speaker-slider"
        direction="horizontal"
        slidesPerView={3}
        spaceBetween={5}
        navigation={true}
        centeredSlides={true}
        initialSlide={Math.floor(authors.length / 2)}
        modules={[Navigation]}
      >
        {authors.map(author => (
          <SwiperSlide className="m-auto">
            <ProfileBadge profile={author} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default SpeakerCarousel;