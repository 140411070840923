import React from "react";

interface LayoutProps extends React.HTMLAttributes<HTMLLIElement> {
  children: React.ReactNode;
  className?: string;
  noBorder?: boolean;
  transparent?: boolean;
}

function LayoutSection({transparent, children, className, noBorder} : LayoutProps) {
  return (
    <div className={`${transparent ? "bg-transparent" : "bg-slate-50"} mb-8 ${(!noBorder ? "px-2 py-8 rounded-lg" : "")} ${className}`}>
      {children}
    </div>
  );
}

export default LayoutSection;