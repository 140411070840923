import { FunctionData, Participant, ScheduleModule } from "../../../../../types/EventData";
import ListItem from "../../../../../utils/ui/ListItem";

interface ModuleAssignmentFormProps {
  scheduleModule: ScheduleModule;
  functions: FunctionData[];
  eventParticipants: Participant[];
  selectedParticipant?: Participant;
  onSelectParticipant: (participant: Participant) => void;
  onAssignmentChange: (participantId: string, functionId: string | undefined) => void;
}

export default function ModuleAssignmentForm({
  scheduleModule,
  eventParticipants,
  functions,
  selectedParticipant,
  onSelectParticipant,
  onAssignmentChange
}: ModuleAssignmentFormProps) {
  return (
    <div className="flex flex-col items-center">
      <h2 className="text-3xl font-bold mb-12">Funções associadas</h2>
      {eventParticipants.length == 0 ? (
        <p className="text-center text-gray-300 font-semibold">
          Você ainda não têm nenhuma pessoa adicionada ao evento. Crie no gerenciamento de pessoas!
        </p>
      ) : (
        <>
          <ul
            className="min-w-80 h-[200px] overflow-y-scroll"
          >
            {eventParticipants.map((participant) => {
              const assignment = scheduleModule.assignments.find(assignment => assignment.participant.id === participant.id);
              const participantFunction = assignment ? functions.find(func => func.id === assignment.function.id) : undefined;
              return (
                <ListItem
                  className="cursor-pointer flex items-center gap-4 px-4 py-1"
                  selected={selectedParticipant?.id === participant.id}
                  onClick={() => onSelectParticipant && onSelectParticipant(participant)}
                >
                  <img
                    className="w-8 h-8 rounded-full"
                    src={participant.profilepic}
                  />
                  <p>{participant.name} {participantFunction && <span className="text-sm text-gray-500">({participantFunction.name})</span>}</p>
                </ListItem>
              );
            })}
          </ul>
          {selectedParticipant && (
            <div className="flex flex-col gap-4">
              <p className="text-lg font-semibold">Função para {selectedParticipant.name}</p>
              <select 
                className="border rounded px-2 py-1"
                onChange={(e) => {
                  const selectedFunctionId = e.target.value;
                  console.log(selectedFunctionId)
                  onAssignmentChange(selectedParticipant.id, selectedFunctionId || undefined);
                }}
                value={scheduleModule.assignments.find(assignment => 
                  assignment.participant.id === selectedParticipant.id
                )?.function.id || ''}
              >
                <option value="">{"<Sem função>"}</option>
                {functions.map((func) => (
                  <option 
                    key={func.id} 
                    value={func.id}
                  >
                    {func.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </>
      )}
    </div>
  );
}