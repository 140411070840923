import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { EventData } from "../../../../types/EventData";

interface ExpositorListProps {
  eventData: EventData;
  handleFormChange: (data: {[key: string]: any}) => void;
}

export default function ExpositorList({ eventData, handleFormChange }: ExpositorListProps) {
  const [expositors, setExpositors] = useState<string[]>(eventData.expositor.expositors);
  const [newExpositor, setNewExpositor] = useState("");

  useEffect(() => {
    handleFormChange({
      expositor: {
        ...eventData.expositor,
        expositors: expositors
      }
    });
  }, [expositors]);

  const adicionarExpositor = () => {
    if (newExpositor.trim() !== "") {
      setExpositors([...expositors, newExpositor.trim()]);
      setNewExpositor("");
    }
  };

  const removerExpositor = (index: number) => {
    const novosExpositores = expositors.filter((_, i) => i !== index);
    setExpositors(novosExpositores);
  };

  return (
    <div className="flex flex-col grow">
      <div className="mb-4">
        <input
          type="text"
          value={newExpositor}
          onChange={(e) => setNewExpositor(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && adicionarExpositor()}
          placeholder="Nome do expositor"
          className="w-full text-sm shadow-lg rounded-full px-8 font-semibold py-2 border"
        />
      </div>
      {expositors.length === 0 ? (
        <p className="text-gray-500 italic">Nenhum expositor adicionado ainda.</p>
      ) : (
        <ul className="space-y-2 max-h-[180px] overflow-y-scroll">
          {expositors.map((expositor, index) => (
            <li 
              key={`expositor${index}`}
              className="flex justify-between items-center bg-gray-100 rounded-full px-4 py-2"
            >
              <span>{expositor}</span>
              <button
                onClick={() => removerExpositor(index)}
                className="text-gray-800 hover:text-black transition-colors duration-200"
              >
                <FaTrash />
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}