import React, { useState } from "react";

type FormInputProps = {
  name: string;
  id?: string;
  type: string;
  placeholder: string;
  title?: string;
  value?: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  invalid?: boolean;
}

function FormInput({value, invalid, required, onChange, title, name, id, type, placeholder, onBlur}: FormInputProps) {
  return (
    <div className="w-1/2 relative">
      <label className="font-semibold text-lg">{title}</label>
      <input
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`bg-slate-50 border border-2 ${invalid ? 'border-red-300' : 'border-slate-200'} mt-3 px-2 py-1 w-full transition-all`}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        required={required}
      />
      {invalid && (
        <span className="absolute right-3 bottom-3 text-xs text-red-500">
          Dado inválido
        </span>
      )}
    </div>
  );
}

export default FormInput;