import React, { ReactElement, useEffect } from "react";
import { BrowserRouter, Routes, Route, useParams, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useCookies } from 'react-cookie';
import Home from "./pages/Home";
import Layout from "./pages/layouts/Layout";
import Edit from "./pages/Edit";
import About from "./pages/About";
import Login from "./pages/Login";
import EventsDashboard from "./pages/EventsDashboard";
import EventManager from "./pages/EventManager";
import LayoutSelection from "./pages/LayoutSelection";
import SubscriptionDashboard from "./pages/SubscriptionDashboard";

type ProtectedRouteProps = {
  authorizedElement: ReactElement,
  unauthorizedRedirect: string;
}

type OnlyUnprotectedRouteProps = {
  unprotectedElement: ReactElement,
  redirect: string;
}

const cookieName = 'authJwt';

const ProtectedRoute = ({authorizedElement, unauthorizedRedirect}: ProtectedRouteProps) => {
  const [cookies] = useCookies([cookieName]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookies[cookieName]) {
      navigate(unauthorizedRedirect);
    }
  }, []);

  return authorizedElement;
}

const OnlyUnprotectedRoute = ({redirect, unprotectedElement}: OnlyUnprotectedRouteProps) => {
  const [cookies] = useCookies([cookieName]);
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies[cookieName]) {
      navigate(redirect);
    }
  }, []);

  return unprotectedElement;
}

function Router() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} /> 
        <Route path="/about" element={<About />} /> 
        <Route path="/login" element={ 
          <OnlyUnprotectedRoute
            unprotectedElement={<Login />}
            redirect="/events"
          />
        } />
        {/* <Route path="/events" element={<EventsDashboard />} />
        <Route path="/events/create" element={<LayoutSelection />} />
        <Route path="/events/create/:layoutName" element={<EventManager />} />
        <Route path="/events/edit/:eventId" element={<EventManager />} />
        <Route path="/events/subscribers/:eventId" element={<SubscriptionDashboard />} /> */}
        <Route path="/events" element={ 
          <ProtectedRoute 
            authorizedElement={<EventsDashboard />}
            unauthorizedRedirect="/login"
          />
        } />
        <Route path="/events/create" element={
          <ProtectedRoute
            authorizedElement={<LayoutSelection />}
            unauthorizedRedirect="/login"
          />
        } />
        <Route path="/events/create/:layoutName" element={
          <ProtectedRoute
            authorizedElement={<EventManager />}
            unauthorizedRedirect="/login"
          />
        } />
        <Route path="/events/edit/:eventId" element={
          <ProtectedRoute
            authorizedElement={<EventManager />}
            unauthorizedRedirect="/login"
          />
        } />
        <Route path="/events/subscribers/:eventId" element={
          <ProtectedRoute
            authorizedElement={<SubscriptionDashboard />}
            unauthorizedRedirect="/login"
          />
        } />
        
        <Route path="/:eventSlug/*" element={<Layout />} />
      </Routes> 
    </AnimatePresence>
  );
}

export default Router;